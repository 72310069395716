import React, { useEffect, useState } from 'react';
import OrderProductTable from "./orderProductTable";
import {
    ModalShipment, ModalLogOnOrder, ModalSendSMS,
    ModalPrivateLog, ModalAvailability, ModalFiscalReceipt,
    ModalTtn, ModalAdditionalServices, ModalSpecialOffer
} from "./Modal/index";
import TopPanel from "../TopPanel/TopPanel";
import Loader from "../../../components/ui/Loader";
import ModalInvoice from "./Modal/ModalInvoice";
import ModalReturnProduct from "./Modal/ModalReturnProduct";
import { useSelector } from "react-redux";
import { orderEditSelector } from "../../../store/orderEdit/selectors";

const ProductsList = ({
                          formProps,
                          data,
                          setData,
                          bonusData,
                          checkEdit,
                          editManager,
                          setTtnNumber,
                          statusDisableAll,
                          setRequiredFields,
                          orderStatus,
                          productsData,
                          setProductsData,
                          setOrderStatus,
                          setBonusData,
                          totalPriceData,
                          setTotalPriceData,
                          setOldOrderStatus
}) => {
    const orderEdit = useSelector(orderEditSelector);

    const [openButton, setOpenButton] = useState(false);
    const [activeShipment, setActiveShipment] = useState(false);
    const [activeLogOnOrder, setActiveLogOnOrder] = useState(false);
    const [activeFiscalReceipt, setActiveFiscalReceipt] = useState(false);
    const [activeInvoice, setActiveInvoice] = useState(false);
    const [activePrivateLog, setActivePrivateLog] = useState(false);
    const [activeReturnProduct, setActiveReturnProduct] = useState(false);
    const [activeSendSMS, setActiveSendSMS] = useState(false);
    const [activeTtn, setActiveTtn] = useState(false);
    const [activeSpecialOffer, setActiveSpecialOffer] = useState(false);
    const [copyShow, setCopyShow] = useState(false);

    const [activeAvailability, setActiveAvailability] = useState(false);
    const [cartAdditionalServices, setCartAdditionalServices] = useState(false);
    const [servicesChecked, setServicesChecked] = useState([]);
    const [paramsServices, setParamsServices] = useState({});
    const [productIndex, setProductIndex] = useState(null);
    const [productId, setProductId] = useState(null);
    const [priceForNP, setPriceForNP] = useState(500);

    useEffect(() => {
        if(orderEdit?.type_payment === '1' ){
            setPriceForNP(formProps?.data?.fields?.total)
        } else if (!orderEdit.type_payment && formProps?.data?.fields?.type_payment === 1) {
            setPriceForNP(formProps?.data?.fields?.total)
        } else {
            setPriceForNP(500)
        }
    }, [formProps?.data?.fields?.total, orderEdit])

    const form = formProps.formH;

    /* eslint-disable */
    return (
        <>
            <TopPanel
                formH={form}
                formProps={formProps}
                openButton={openButton}
                setOpenButton={setOpenButton}
                setActiveShipment={setActiveShipment}
                setActiveLogOnOrder={setActiveLogOnOrder}
                setActiveFiscalReceipt={setActiveFiscalReceipt}
                setActiveInvoice={setActiveInvoice}
                setActivePrivateLog={setActivePrivateLog}
                setActiveReturnProduct={setActiveReturnProduct}
                setActiveSendSMS={setActiveSendSMS}
                setActiveTtn={setActiveTtn}
                copyShow={copyShow}
                setCopyShow={setCopyShow}
                checkEdit={checkEdit}
                editManager={editManager}
                setTtnNumber={setTtnNumber}
                productsData={productsData}
                setProductsData={setProductsData}
                setRequiredFields={setRequiredFields}
                totalPriceData={totalPriceData}
                setTotalPriceData={setTotalPriceData}
                data={data}
                setData={setData}
                orderStatus={orderStatus}
                setOrderStatus={setOrderStatus}
                setOldOrderStatus={setOldOrderStatus}
                editBtn
                breadcrumbs={{
                    items: [
                        {
                            title: 'Головна',
                            url: '/app',
                        },
                        {
                            title: 'Заказы',
                            url: `/app/orders${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                        },
                        {
                            title: formProps?.data?.fields?.id ? `Samsung: Заказ #${formProps?.data?.fields?.id}` : 'Новый заказ',
                        },
                    ]
                }}
            />
            <OrderProductTable
                form={form}
                formProps={formProps}
                setActiveAvailability={setActiveAvailability}
                setActiveSpecialOffer={setActiveSpecialOffer}
                setCartAdditionalServices={setCartAdditionalServices}
                setServicesChecked={setServicesChecked}
                setParamsServices={setParamsServices}
                setProductIndex={setProductIndex}
                setProductId={setProductId}
                bonusData={bonusData}
                checkEdit={checkEdit}
                statusDisableAll={statusDisableAll}
                productsData={productsData}
                setProductsData={setProductsData}
                totalPriceData={totalPriceData}
                setTotalPriceData={setTotalPriceData}
                setBonusData={setBonusData}
            />
            {activeShipment && (
                <ModalShipment
                    active={activeShipment}
                    setActive={setActiveShipment}
                    form={form}
                    title="Распоряжение на отгрузку"
                />
            )}
            {activeLogOnOrder && (
                <ModalLogOnOrder
                    active={activeLogOnOrder}
                    setActive={setActiveLogOnOrder}
                    title="Лог по заказу"
                    id={formProps.orderID}
                />
            )}
            {activeFiscalReceipt && (
                <ModalFiscalReceipt
                    active={activeFiscalReceipt}
                    setActive={setActiveFiscalReceipt}
                    id={formProps.orderID}
                    title="Лог фискального чека по заказу"
                />
            )}
            {activeInvoice && (
                <ModalInvoice
                    active={activeInvoice}
                    setActive={setActiveInvoice}
                    id={formProps.orderID}
                    productsData={productsData}
                    formProps={formProps}
                    setRequiredFields={setRequiredFields}
                    orderStatus={orderStatus}
                    title="Інвойси"
                />
            )}
            {activePrivateLog && (
                <ModalPrivateLog
                    active={activePrivateLog}
                    setActive={setActivePrivateLog}
                    orderId={formProps.orderID}
                    title="Лог привата по заказу"
                />
            )}
            {activeReturnProduct && (
                <ModalReturnProduct
                    active={activeReturnProduct}
                    setActive={setActiveReturnProduct}
                    formProps={formProps}
                    productsData={productsData}
                    setProductsData={setProductsData}
                    title="Повернення"
                />
            )}
            {activeSendSMS && (
                <ModalSendSMS
                    active={activeSendSMS}
                    setActive={setActiveSendSMS}
                    formProps={formProps}
                    title="Отправка СМС"
                />
            )}
            {activeTtn && (
                <ModalTtn
                    active={activeTtn}
                    setActive={setActiveTtn}
                    formProps={formProps}
                    setTtnNumber={setTtnNumber}
                    orderID={formProps?.orderID}
                    priceForNP={priceForNP}
                    title="ТТН"
                />
            )}
            {activeAvailability && (
                <ModalAvailability
                    active={activeAvailability}
                    setActive={setActiveAvailability}
                    productId={productId}
                    title="Наличие"
                />
            )}
            {cartAdditionalServices && (
                <ModalAdditionalServices
                    active={cartAdditionalServices}
                    setActive={setCartAdditionalServices}
                    formProps={formProps}
                    servicesChecked={servicesChecked}
                    paramsServices={paramsServices}
                    productIndex={productIndex}
                    productsData={productsData}
                    setProductsData={setProductsData}
                    setTotalPriceData={setTotalPriceData}
                    statusDisableAll={statusDisableAll}
                    title="Дополнительные сервисы"
                />
            )}

            {activeSpecialOffer && (
                <ModalSpecialOffer
                    active={activeSpecialOffer}
                    setActive={setActiveSpecialOffer}
                    orderID={formProps?.orderID}
                    productIndex={productIndex}
                    productsData={productsData}
                    productId={productId}
                    formProps={formProps}
                    setProductsData={setProductsData}
                    title="Выберите товары"
                />
            )}

            {formProps.load && <Loader />}
        </>
    );
};

export default ProductsList;
