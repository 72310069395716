import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {uid} from 'react-uid';
import {useDispatch} from "react-redux";
import cn from 'classnames';

import s from './FiltersRow.module.scss';
import s_Select from "../../../ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../../../../containers/OrderEditView/fakeData/orderTable.module.scss";
import DateRangeCustom from "../../../ui/DateRangeCustom/DateRangeCustom";
import InputWrapper from "../../../ui/InputWrapper";
import s_input from "../../../ui/InputWrapper/InputWrapper.module.scss";

const TableListViewFiltersRow = ({
                                   urlParams = {}, fields = [], onSubmit, loading = false, setFilters, discountHandler, onTextClickHandler
                                 }) => {
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
    setValue
  } = formH;
  const dispatch = useDispatch();

  const [nullCardNumber, setNullCardNumber] = useState(false);
  const [nullCardNumberDisable, setNullCardNumberDisable] = useState(false);

  function onSubmitHandle(data) {
    if (setFilters) {
      dispatch(setFilters(data))
    }
    if (onSubmit) {
      if (typeof data['null_card_number'] !== 'undefined') {

        if (!!data['card_number']?.length) {
          data['null_card_number'] = '';
        }

        if (data['null_card_number'] === 'true') {
          data['null_card_number'] = 1;
        } else {
          data['null_card_number'] = '';
        }

      }

      if (!!data?.created_from?.length) {
        data.created_from = `${data?.created_from} 00:00:00`;
      }

      if (!!data?.created_to?.length) {
        data.created_to = `${data?.created_to} 23:59:59`;
      }

      if(!!data?.date_from_markers?.length) {
        data.date_from = `${data?.date_from_markers} 00:00:00`;
      }

      if(!!data?.date_to_markers?.length) {
        data.date_to = `${data?.date_to_markers} 23:59:59`;
      }

      if (!!data?.updated_from?.length) {
        data.updated_from = `${data?.updated_from} 00:00:00`;
      }

      if (!!data?.updated_to?.length) {
        data.updated_to = `${data?.updated_to} 23:59:59`;
      }

      onSubmit(data);
    }
  }

  const sendCheckbox = (e) => {
    if (e.target.checked) {
      onSubmit({...urlParams, [e.target.name]: 1});
    } else {
      delete urlParams[e.target.name];
      onSubmit(urlParams);
    }
  }

  // on press enter
  function onEnterPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();

      handleSubmit(onSubmitHandle)();
    }
  }

  // on change fields (mostly not text inputs)
  function changeHandler() {
    handleSubmit(onSubmitHandle)();
  }

  useEffect(() => {
    if (urlParams?.null_card_number) {
      setNullCardNumber(!!urlParams?.null_card_number);
    }
  }, [urlParams]);

  return (
    <tr className={cn(s.wrap,
      {[s.wrapLoading]: loading})}
    >
      {Array.isArray(fields) && fields.map((f) => {
        let el;
        let secondEl;
        const valKey = f.optProps ? f.optProps[0] : 'id';
        const titleKey = f.optProps ? f.optProps[1] : 'title';
        const secondSmbl = f.optProps ? f.optProps[2] : 'id';
        if (f?.secondLvl) {
          switch (f?.secondLvl?.type) {
            case ('date'):
              if (['created_from', 'created_to', 'updated_from', 'updated_to'].includes(f?.secondLvl?.name)) {
                secondEl = (
                  <div className={s.wrap_date_input}>
                    <InputWrapper id={`filter-table-${f?.secondLvl?.name}`}>
                      <input
                        type="date"
                        name={f?.secondLvl?.name}
                        id={f?.secondLvl?.name}
                        className={s_input.input}
                        onKeyDown={onEnterPress}
                        ref={register}
                        defaultValue={urlParams && urlParams[f?.secondLvl?.name]?.split(' ')?.[0]}
                        {...f?.secondLvl?.attr}
                      />
                    </InputWrapper>
                  </div>
                );
              } else {
                secondEl = (
                  <div className={s.wrap_date_input}>
                    <InputWrapper id={`filter-table-${f?.secondLvl?.name}`}>
                      <input
                        type="date"
                        name={f?.secondLvl?.name}
                        id={f?.secondLvl?.name}
                        className={s_input.input}
                        onKeyDown={onEnterPress}
                        ref={register}
                        defaultValue={urlParams && urlParams[f?.secondLvl?.name]}
                        {...f?.secondLvl?.attr}
                      />
                    </InputWrapper>
                  </div>
                );
              }

              break;

            case ('text'):
              secondEl = (
                <label
                  htmlFor={f?.secondLvl?.name}>
                  {f?.secondLvl?.label}
                  <input
                    type='text'
                    name={f?.secondLvl?.name}
                    className={s.input_text}
                    onKeyDown={onEnterPress}
                    ref={register}
                    defaultValue={urlParams && urlParams[f?.secondLvl?.name]}
                    {...f?.secondLvl?.attr}
                  />
                </label>
              );

              break;

            case ('number'):
              el = (
                <label
                  htmlFor={f?.secondLvl?.name}>
                  {f?.secondLvl?.label}
                  <input
                    type='number'
                    name={f?.secondLvl?.name}
                    className={s.input_text}
                    onKeyDown={onEnterPress}
                    ref={register}
                    defaultValue={urlParams && urlParams[f?.secondLvl?.name]}
                    {...f?.secondLvl?.attr}
                  />
                </label>
              );

              break;

            case ('checkbox'):
              secondEl = (
                <div className={s_Select.checkbox__wrapper}>
                  <input
                    className={s_Select.checkbox}
                    type='checkbox'
                    name={f?.secondLvl?.name}
                    id={f?.secondLvl?.id}
                    ref={register}
                    {...f?.secondLvl?.attr}
                  />
                  <label htmlFor={f?.secondLvl?.id} className={s_Select.label}/>
                </div>
              );

              break;

            case ('select'):
              secondEl = (
                <select
                  name={f?.secondLvl?.name}
                  className={s.input_select}
                  ref={register}
                  defaultValue={urlParams && urlParams[f?.secondLvl?.name]}
                  onChange={changeHandler}
                  {...f?.secondLvl?.attr}
                >
                  <option value="">---</option>
                  {f?.secondLvl?.options && f?.secondLvl?.options.map((e) => (
                    <option
                      value={e[valKey]}
                      key={uid(e)}
                    >
                      {e[titleKey]}
                    </option>
                  ))}
                </select>
              );

              break;

            default:
              secondEl = null;
          }
        }
        switch (f.type) {
          case ('custom'):
            el = (
              <div>
                <div>
                  <label
                    htmlFor={f.name}>
                    {f.label}
                    <input
                      type='text'
                      name={f.name}
                      className="form-input"
                      onKeyDown={onEnterPress}
                      onChange={(e) => {
                        setNullCardNumber(false);
                        if (!!e?.target?.value?.length) {
                          setNullCardNumberDisable(true);
                        } else {
                          setNullCardNumberDisable(false);
                        }
                      }}
                      ref={register}
                      defaultValue={urlParams && urlParams[f.name]}
                      {...f.attr}
                    />
                  </label>
                </div>

                <div className={s.flex}>
                  <input
                    type='hidden'
                    name='null_card_number'
                    ref={register}
                    value={nullCardNumber}
                  />
                  <div className={s_Select.checkbox__wrapper}>
                    <input
                      className={s_Select.checkbox}
                      type='checkbox'
                      name='null_card_number_second'
                      id='check_id'
                      checked={nullCardNumber}
                      disabled={nullCardNumberDisable}
                      onClick={(e) => {
                        setNullCardNumber(e.target.checked);
                        setValue('null_card_number', e.target.checked);
                        changeHandler();
                      }}
                      {...f.attr}
                    />
                    <label
                      htmlFor='check_id'
                      className={cn(s_Select.label, {
                        [s_Select.label_disable]: nullCardNumberDisable
                      })}
                    />
                  </div>
                  {nullCardNumber ? 'Без карты' : 'С картой'}
                </div>
              </div>
            );

            break;

          case ('date_range'):
            el = <DateRangeCustom
              formH={formH}
              startDateName={f.startName}
              endDateName={f.endName}
              handlerOnChange={changeHandler}
              urlParams={urlParams}
              style={{height: f?.height && f?.height, display: f?.display && f?.display}}
            />;

            break;

          case ('date'):
              if (['date_from_markers', 'date_to_markers', 'date_to', 'date_from', 'starts_at', 'finish_at', 'created_from', 'created_to', 'updated_from', 'updated_to'].includes(f?.name)) {
              el = (
                <div className={s.wrap_date_input}>
                  <InputWrapper id={`filter-table-${f?.name}`}>
                    <input
                      type="date"
                      name={f?.name}
                      id={f?.name}
                      className={s_input.input}
                      onKeyDown={onEnterPress}
                      ref={register}
                      defaultValue={urlParams && urlParams?.[f?.name]?.split(' ')?.[0]}
                      {...f.attr}
                    />
                  </InputWrapper>
                </div>
              );
            } else {
              el = (
                <div className={s.wrap_date_input}>
                  <InputWrapper id={`filter-table-${f?.name}`}>
                    <input
                      type="date"
                      name={f?.name}
                      id={f?.name}
                      className={s_input.input}
                      onKeyDown={onEnterPress}
                      ref={register}
                      defaultValue={urlParams && urlParams[f.name]}
                      {...f.attr}
                    />
                  </InputWrapper>
                </div>
              );
            }

            break;

          case ('text'):
            el = (
              <label htmlFor={f.name} {...f.attrLabel}>
                {f.label}
                <input
                  type='text'
                  placeholder={f.placeholder}
                  name={f.name}
                  className={s.input_text}
                  onKeyDown={onEnterPress}
                  ref={register}
                  defaultValue={urlParams && urlParams[f.name]}
                  {...f.attr}
                />
              </label>
            );

            break;

          case ('number'):
            el = (
              <label
                htmlFor={f.name}>
                {f.label}
                <input
                  type='number'
                  name={f.name}
                  className={s.input_text}
                  onKeyDown={onEnterPress}
                  ref={register}
                  defaultValue={urlParams && urlParams[f.name]}
                  {...f.attr}
                />
              </label>
            );

            break;

          case ('checkbox'):
            el = (
              <div className={s.input_checkbox}>
                <div className={s_Select.checkbox__wrapper}>
                  <input
                    className={s_Select.checkbox}
                    type='checkbox'
                    name={f.name}
                    id={f.id}
                    ref={register}
                    defaultChecked={urlParams && urlParams[f.name]?.toString() === '1'}
                    onClick={sendCheckbox}
                    {...f.attr}
                  />
                  <label htmlFor={f.id} className={s_Select.label}/>
                </div>
                <div style={{width: "auto", height: "auto"}}>{f.label}</div>
              </div>
            );

            break;

          case ('select'):
            el = (
                <select
                    name={f.name}
                    className={s.input_select}
                    ref={register}
                    defaultValue={urlParams && urlParams[f.name]}
                    onChange={changeHandler}
                    {...f.attr}
                >
                    <option value="">---</option>
                    {f.options && f.options.map((e) => (
                        <option
                            value={e[valKey]}
                            key={uid(e)}
                        >
                            {e[titleKey]}
                        </option>
                    ))}
                </select>
            );

              break;

          case ('select_try_and_buy'):
            const copyCities = f.options
            const uniqueCities = copyCities?.filter((shop, index, self) =>
              index === self.findIndex((s) => s.settlement?.id === shop.settlement?.id)
            );

            const copyShops = f.options
            const uniqueShops = copyShops?.filter((shop, index) => {
              if(urlParams?.settlement_id && !urlParams?.shop_id) {
                return shop?.settlement?.id === +urlParams.settlement_id
              } else if(urlParams?.settlement_id && urlParams?.shop_id) {
                return shop?.settlement?.id === +urlParams.settlement_id
              } else {
                return shop
              }
            });
            // console.log(uniqueShops)

            let arrayForSearch = f.name !== 'shop_id' ? f.options : uniqueShops
            el = (
              <select
                name={f.name}
                className={s.input_select}
                ref={register}
                defaultValue={urlParams && urlParams[f.name]}
                onChange={changeHandler}
                {...f.attr}
              >
                <option value="">---</option>

                {f.name !== 'settlement_id' ? f.options && arrayForSearch.map((e) => (
                  <option
                    value={e[valKey]}
                    key={uid(e)}
                  >
                    {e[titleKey]}
                  </option>
                )) : uniqueCities && uniqueCities.map((e) => (
                  <option
                    value={e?.settlement?.id}
                    key={uid(e?.settlement?.id)}
                  >
                    {e?.settlement?.title}
                  </option>
                ))}
              </select>
          );

            break;

          case ('custom_select_for_promocode'):
            el = (
              <select
                name={f.name}
                className={s.input_select}
                ref={register}
                defaultValue={urlParams && urlParams[f.name]}
                onChange={changeHandler}
                {...f.attr}
              >
                <option value="">---</option>
                {f.options && f.options.map((e) => (
                  <option
                    value={e[valKey]}
                    key={uid(e)}
                  >
                    { `[ ${e[secondSmbl]} ] ` + e[titleKey]}
                  </option>
                ))}
              </select>
            );

            break;

            case ('calcOrder'):
                el = (
                    <button className={s.calcOrderWrapper} type="button" onClick={discountHandler} {...f.attr}>
                        Розрахувати з %
                    </button>
                )
            break;

            case ('textDivWithFunc'):
                el = (
                    <div {...f.attr} onClick={onTextClickHandler} >
                        {f.text?.split(' ').map((e, i) => (
                            <React.Fragment key={i}>
                                {e}
                                <br />
                            </React.Fragment>
                        ))}
                    </div>
                )
                break;

            default:
                el = null;
        }

          return (
              <th key={uid(f)} className={f.type === 'checkbox' ? s.vertical_align : s.baseline} style={{ width: f?.width ? f?.width : 'auto', top: 40, minWidth: f?.minWidth ? f?.minWidth : f?.minWidth ? f?.minWidth : f?.type === 'date_range' ? '260px' : 'auto',
                  maxWidth: f?.maxWidth ? f?.maxWidth : 'auto', ...f?.wrapperStyle
          }}>
            <div {...f?.attrWrapper}>
              {el}
              {secondEl && (
                <>
                  <div style={{ fontSize: 10, textAlign: 'center' }}>{f?.attrWrapper?.style?.display !== 'block' && '-'}</div>
                  {secondEl}
                </>
              )}
            </div>
          </th>
        );
      })}
    </tr>
  );
};

export default TableListViewFiltersRow;
