import React from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import checkPermission from "../../lib/checkPermission";
import useList from "../../lib/crud/useList";
import getSplicedData from '../../lib/crud/getSplicedData';
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { getData, remove, getFiltersData } from "./api";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import getObjInArrFind from "../../lib/helpers/getObjInArrFind";
import StatusCircle from "../../components/ui/StatusCircle";
import CopyComponent from "../../components/ui/CopyComponent";

const MediaList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        setRest,
        filtersData,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });

    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Media list`}/>
            {!load && (
                <TopPagination
                    pagination={(
                        <>
                            {+rest?.meta?.total && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        loc={location}
                                        navigate={navigate}
                                    />
                                    <TableListViewPerPage
                                        urlParams={urlParams}
                                        onSelect={(val, params) => pushUrlParams(params)}
                                        total={+rest?.meta?.total}
                                    />
                                </>
                            )}
                        </>
                    )}
                />
            )}
            <TableListViewTable>
                <TableHead
                    arrayChildren={[
                        {title: 'ID', attr: {style: {width: '30px'}}},
                        {title: 'Зображення', attr: {style: {width: '60px'}}},
                        {title: 'URL', attr: {style: {width: '60px'}}},
                        'ALT UA', 'ALT RU',
                        {title: 'Статус активності', attr: {style: {width: '120px'}}},
                        {title: 'Тип зображення', attr: {style: {width: '120px'}}},
                        {title: 'Домен', attr: {style: {width: '120px'}}}
                    ]}
                    filterChildren={<TableListViewFiltersRow
                        urlParams={urlParams}
                        fields={[
                            {},
                            {},
                            {},
                            {type: 'text', name: 'alt_ua', attr: {style: {width: "100%"}, placeholder: 'Пошук'}, attrLabel: { style: { width: '100%' } }},
                            {type: 'text', name: 'alt_ru', attr: {style: {width: "100%"}, placeholder: 'Пошук'}, attrLabel: { style: { width: '100%' } }},
                            {
                                type: 'select', name: 'is_active',
                                options: [
                                    {
                                        id: '0',
                                        title: 'Не активна'
                                    },
                                    {
                                        id: '1',
                                        title: 'Активна'
                                    }
                                ]
                            },
                            {
                                type: 'select',
                                name: 'collection_name',
                                options: filtersData && filtersData?.types,
                                optProps: ['value', 'title']
                            },
                            {type: 'select', name: 'domain_id', options: filtersData?.domains},
                            {}
                        ]}
                        onSubmit={pushUrlParams}
                    />}
                />
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={8}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`media-item-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Медіа видалено',
                                    }}
                                    editLink={`/app/media/update/${e.id}`}
                                    permissionRemove='admin.media.destroy'
                                    permissionEdit='admin.media.edit'
                                >
                                    <td>
                                        {checkPermission('admin.media.edit')
                                            ? <Link to={`/app/media/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                        {e?.conversions?.original
                                            ? <img src={e?.conversions?.original} alt=""/>
                                            : '---'}
                                    </td>
                                    <td title={e?.conversions?.original || '---'}>
                                        {e?.conversions?.original ? <CopyComponent copyText={e?.conversions?.original} /> : '---'}
                                    </td>
                                    <td>
                                        <span>
                                            {e?.custom_properties?.alt_ua || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.custom_properties?.alt_ru || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                        </span>
                                    </td>
                                    <td>
                                        <span>
                                            {e?.collection_name || '---'}
                                        </span>
                                    </td>
                                    <td>
                                        {e?.domain_id
                                            ? !!filtersData?.domains?.length
                                            && getObjInArrFind(filtersData?.domains, 'id', e?.domain_id)?.title
                                            : '---'}
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={8}>Немає медіа</td>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
        </div>
    )
}
export default MediaList