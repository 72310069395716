import React from "react";
import {Link} from "react-router-dom";
import {PubSub as ps} from "pubsub-js";
import {EDIT} from "../../../const/pubsub";
import Breadcrumbs from "./Breadcrumbs";
import Button from "../../ui/Button";
import s from "./TopPanelNew.module.scss";
import icon_arrow_back from "../../../assets/icons/icon_arrow-back.svg";
import icon_save from "../../../assets/icons/icon_save.svg";
import IconApply from "../../icon/IconApply";
import checkPermission from "../../../lib/checkPermission";
import BtnCreate from "../TopPanelNew/BtnCreate";
import TopInfo from "./TopInfo";

const TopPanelChild = ({
                         title,
                         breadcrumbs,
                         back,
                         backNavigate,
                         backLinkA = false,
                         id,
                         btnEdit,
                         children,
                         childrenBtnSave,
                         topInfo = null,
                         btnCreate = {href: null, title: null, permissionName: null},
                         location
                       }) => {
  function saveHandler() {
    ps.publish(EDIT.save);
  }

  function applyHandler() {
    localStorage.setItem('apply', 'on');
    ps.publish(EDIT.save);
  }

  return (
    <div className={s.topPanel} style={{margin: '0 0 32px 0'}}>
      <div className={s.breadcrumbs__wrapper} style={{alignItems: 'center'}}>
        <div>
          {breadcrumbs && (
            <Breadcrumbs {...breadcrumbs} />
          )}
          <div className={s.title}>
            <div>{title}</div>
          </div>
        </div>
        {topInfo && (
          <TopInfo data={topInfo}/>
        )}
        {btnEdit && !topInfo && (
          <div className={s.buttons__wrapper}>
            <div className={s.buttons__group}>
              {back && !back.includes('/app/page-settings/main') && (
                <div style={{marginRight: '16px'}}>
                  <Link
                    to={back}>Відмінити</Link>
                </div>
              )}
              {id ? (
                <>
                  <Button border id="edit-btn" onClick={applyHandler}>
                    <IconApply/>
                    Застосувати
                  </Button>
                  <Button purple id="save-btn" onClick={saveHandler}>
                    <img src={icon_save} alt="Save icon"/>
                    Зберегти
                  </Button>
                </>
              ) : btnCreate?.href
                ? checkPermission(btnCreate?.permissionName) && (
                <div className={s.button__block}>
                  <BtnCreate {...btnCreate} />
                </div>
              ) : back?.includes('page-settings/main') ? (
                  <>      <Link to={'/app/page-settings'}>
                    <Button border id="edit-btn">
                      Скинути фильтри
                    </Button>
                  </Link>
                    <Link to={'/app/page-setting/create/'}>
                      <Button purple id="save-btn">
                        <img src={icon_save} alt="Save icon"/>
                        Створити
                      </Button>
                    </Link>
                  </>
                ) : childrenBtnSave
                  ? childrenBtnSave
                  : (
                    <>
                      {back && !back.includes('page-setting/create') &&
                        <Button border id="edit-btn" onClick={applyHandler}>
                          <IconApply/>
                          Застосувати
                        </Button>}
                      <Button purple id="save-btn" onClick={saveHandler}>
                        <img src={icon_save} alt="Save icon"/>
                        Створити
                      </Button>
                    </>
                  )}
            </div>
          </div>
        )}
      </div>
      {/* TODO refactoring */}
      {back && (
        <div className={s.footer}>
          {backNavigate
              ? (
                  <div className={s.nav_back_wrapper}>
                      <Link to={back} onClick={() => backNavigate(-1)}>
                          <img src={icon_arrow_back} alt="Arrow back icon"/>
                          Назад
                      </Link>
                  </div>
              )
              : back && backLinkA && back?.includes('/page-settings/update')
                  ? (
                      <div className={s.nav_back_wrapper}>
                          <a href={back}>
                              <img src={icon_arrow_back} alt="Arrow back icon"/>
                              Назад
                          </a>
                      </div>
                  ) :
                  !location?.includes('/marker/') && back?.includes('/page-settings/update') && !back?.includes('/app/page-settings/main') ? (
                      <div className={s.nav_back_wrapper}>
                          <Link to={back}>
                              <img src={icon_arrow_back} alt="Arrow back icon"/>
                              Назад
                          </Link>
                      </div>
                  ) : <div className={s.nav_back_wrapper}>
                      <Link to={back}>
                          <img src={icon_arrow_back} alt="Arrow back icon"/>
                          Назад
                      </Link>
                  </div>}
          {btnEdit && topInfo && (
            <div className={s.buttons__wrapper}>
              <div className={s.buttons__group}>
                {back && (
                  <div style={{marginRight: '16px'}}>
                    <Link to={back}>Скасувати</Link>
                  </div>
                )}
                {id ? (
                  <>
                    <Button border id="edit-btn" onClick={applyHandler}>
                      <IconApply/>
                      Застосувати
                    </Button>
                    <Button purple id="save-btn" onClick={saveHandler}>
                      <img src={icon_save} alt="Save icon"/>
                      Зберегти
                    </Button>
                  </>
                ) : btnCreate && checkPermission(btnCreate.permissionName) && (
                  <Button purple id="save-btn" onClick={saveHandler}>
                    <img src={icon_save} alt="Save icon"/>
                    Зберегти
                  </Button>
                )}
              </div>
            </div>
          )}
          {children && children}
        </div>
      )}
    </div>
  );
};

export default TopPanelChild;