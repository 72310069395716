import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";

const BtnAction = ({ title, href, funcRedux }) => {
    const dispatch = useDispatch();

    return (
        <Link to={href} className="btn btn-primary" onClick={() => {
            funcRedux && dispatch(funcRedux())
        }}>
            {title}
        </Link>
    );
};

export default BtnAction;
