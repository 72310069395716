import s_Modal from "../../TryAndBuyList/Modal/modal.module.scss";
import s from "../../../components/ui/Modal/Modal.module.scss";
import CheckboxSwitch from "../../../components/ui/CheckboxSwitch";
import React, {useEffect, useState} from "react";
import {editCreditType, getCreditTypeEdit} from "../../../lib/api/creditTypes";
import errorMessage from "../../../lib/errorMessage";
import Loader from "../../../components/ui/Loader";
import {useForm} from "react-hook-form";
import s_input from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputWrapper from "../../../components/ui/InputWrapper";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import Button from "../../../components/ui/Button";

const ModalEdit = ({setActive, active, activeId}) => {
    const [data, setData] = useState(null);
    const [load, setLoad] = useState(false);

    const formH = useForm();
    const {
        watch,
    } = formH;

    const isInCategoryWatch = watch('is_active');

    const handlerOnChange = (e) => {
        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    useEffect(() => {
        setData(prev => {
            return {
                ...prev,
                is_active: isInCategoryWatch ? 1 : 0,
            }
        })
    }, [isInCategoryWatch]);

    const createItem = async() => {
        setLoad(true);
        editCreditType(activeId, data).then((res) => {
            window.location.reload()
        })
            .catch(async(err) => await errorMessage(err, ''))
            .finally(() => setLoad(false));
    }

    useEffect(() => {
        setLoad(true);
        getCreditTypeEdit(activeId).then((res) => {
            if (res?.data){
                setData(res.data);
            }
        })
            .catch(async(err) => await errorMessage(err, ''))
            .finally(() => setLoad(false));
    }, [activeId])

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
                style={{minWidth: "663px"}}
            >
                {data ?
                    <div>

                        <div className={s.modal__header}>
                            <div className={s.modal__title}>Існуючий тип кредиту</div>

                            <CheckboxSwitch
                                name="is_active"
                                id="bank-is-active"
                                label="Активність"
                                defaultChecked={data.is_active}
                                formH={formH}
                            />
                        </div>

                        <InputWrapper
                            label={'Назва типу кредита'}
                            id={`title-modal-label-` + activeId}
                        >
                            <input
                                className={s_input.input}
                                type={'text'}
                                placeholder={'Назва типу кредита'}
                                maxLength={255}
                                name={'title'}
                                id={`title-modal-input-` + activeId}
                                value={data?.title}
                                onChange={handlerOnChange}
                            />
                        </InputWrapper>

                        <Group attrs={{style: {margin: '0 10px 0 0'}}}>
                            <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                                <InputWrapper
                                    label={'Кількість платежів'}
                                    id={`number-modal-label-` + activeId}
                                >
                                    <input
                                        className={s_input.input}
                                        type={'number'}
                                        placeholder={'Кількість платежів'}
                                        maxLength={255}
                                        name={'payment_count'}
                                        id={`number-modal-input-` + activeId}
                                        value={data?.payment_count}
                                        onChange={handlerOnChange}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        <div className={s.modal__footer}>
                            <div className={s.modal__group}>
                                <div>
                                    <Button purple onClick={createItem} disabled={load}>Змінити</Button>
                                </div>
                                <div>
                                    <Button border onClick={() => {
                                        setActive(false);
                                        setData(null)
                                    }}>Закрити</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : load && <Loader/>}
            </div>
        </div>
    )
};

export default ModalEdit;