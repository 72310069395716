import React, { useEffect, useState } from "react";
import Item from "../Item";
import Form from "../../../components/ui/FormEditView/Form";
import Group from "../../../components/ui/FormEditView/Group";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import cn from "classnames";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import { VALIDATION } from "../../../const/text";
import InputWrapper from "../../../components/ui/InputWrapper";
import s from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import icon_info from "../../../assets/icons/icon_info.svg";
import Loader from "../../../components/ui/Loader";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../../store/orderEdit/constants";
import InputMask from "react-input-mask";

const OrderUserInfo = ({ formProps, statusDisableAll, requiredFields, fieldsReqEmail, orderStatus, oldOrderStatus }) => {
    const orderEdit = useSelector(state => state.orderEdit);
    const dispatch = useDispatch();

    const onChangeObj = (key, value, name, id) => dispatch({type: Constants.ON_CHANGE_OBJ, payload: {key, value, name, id}});

    const form = formProps.formH;
    const dataFields = formProps.data && formProps.data.fields;
    const [phoneInfo, setPhoneInfo] = useState(false);

    const handlePhone = (e) => {
        onChangeObj('phone', e.target.value, 'order_user_info', dataFields?.order_user_info?.id);
    };

    const emailWatch = form.watch('order_user_info.email');
    const patronymicWatch = form.watch('order_user_info.patronymic');
    const companyNameWatch = form.watch('order_user_info.company_name');
    const deliveryTypeWatch = form.watch('delivery_type');
    const statusPaymentWatch = form.watch('status_payment');

    useEffect(() => {
        if ((fieldsReqEmail.delivery_type === '6') && ((typeof fieldsReqEmail.warehouse_id === 'string') && !!fieldsReqEmail.warehouse_id?.length)
            && (fieldsReqEmail.status_payment === '1')) {
            form.clearErrors('order_user_info.email');
        }

        if (!((fieldsReqEmail.delivery_type === '6') && ((typeof fieldsReqEmail.warehouse_id === 'string') && !!fieldsReqEmail.warehouse_id?.length)
            && (fieldsReqEmail.status_payment === '1'))) {
            form.clearErrors('order_user_info.email');
            if (!!emailWatch?.length) {
                form.clearErrors('order_user_info.email');
            }
        }
    }, [fieldsReqEmail, emailWatch]);

    useEffect(() => {
        if (!!orderEdit?.promoCode && (oldOrderStatus === 3 && orderStatus !== 1 && orderStatus !== 17) && oldOrderStatus !== orderStatus && patronymicWatch.trim().length < 3) {
            form.setError('order_user_info.patronymic', {type: 'email', message: "Поле должно содержать не менее 3 символов"});
        } else if (((fieldsReqEmail?.delivery_type !== '6' && fieldsReqEmail?.delivery_type !== '7') && (oldOrderStatus === 3 && orderStatus !== 1  && orderStatus !== 17) && oldOrderStatus !== orderStatus && !patronymicWatch) && patronymicWatch.trim().length < 3) {
            form.setError('order_user_info.patronymic', {type: 'email', message: "Поле должно содержать не менее 3 символов"});
        } else if (!!orderEdit?.promoCode && orderStatus === 4 && patronymicWatch.trim().length < 3) {
            form.setError('order_user_info.patronymic', {type: 'email', message: "Поле должно содержать не менее 3 символов"});
        } else if ((fieldsReqEmail?.delivery_type !== '6' && fieldsReqEmail?.delivery_type !== '7') && orderStatus === 4 && patronymicWatch.trim().length < 3) {
            form.setError('order_user_info.patronymic', {type: 'email', message: "Поле должно содержать не менее 3 символов"});
        } else {
            form.clearErrors('order_user_info.patronymic');
        }
    }, [requiredFields, emailWatch, patronymicWatch, orderEdit, orderEdit?.promoCode, orderStatus, oldOrderStatus]);

    useEffect(() => {
        if (deliveryTypeWatch === '6' && statusPaymentWatch === '1') {
            form.clearErrors('order_user_info.email');
            return
        }

        if (requiredFields) {
            form.setError('order_user_info.email', { type: 'email', message: "Поле не должно быть пустым" });
            if (!!emailWatch?.length) {
                form.clearErrors('order_user_info.email');
            }
        } else {
            form.clearErrors('order_user_info.email');
        }
    }, [requiredFields, deliveryTypeWatch, emailWatch, statusPaymentWatch]);

    return(
        <Item title="Данные заказчика" statusDisableAll={statusDisableAll}>
            <form>
                <button type="submit" className="d-hide" />
                <Form Tag="div">

                    {/* order_user_info.surname */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Фамилия*"
                                id="oui-surname"
                                errorComponent={form.errors?.order_user_info?.surname &&
                                    <p className="form-input-hint">{form.errors.order_user_info.surname.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.order_user_info?.surname,
                                    })}
                                    type="text"
                                    placeholder="Фамилия*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required
                                    })}
                                    name="order_user_info.surname"
                                    id="oui-surname"
                                    defaultValue={dataFields?.order_user_info?.surname}
                                    onChange={(e) => onChangeObj('surname', e.target.value, 'order_user_info', dataFields?.order_user_info?.id)}
                                    readOnly={statusDisableAll}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* order_user_info.name */}
                    <Group attrs={{className: cn('mt-0 mb-0', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Имя*"
                                id="oui-name"
                                errorComponent={form.errors?.order_user_info?.name &&
                                    <p className="form-input-hint">{form.errors.order_user_info.name.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.order_user_info?.name,
                                    })}
                                    type="text"
                                    placeholder="Имя*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required
                                    })}
                                    name="order_user_info.name"
                                    id="oui-name"
                                    defaultValue={dataFields?.order_user_info?.name}
                                    readOnly={statusDisableAll}
                                    onChange={(e) => onChangeObj('name', e.target.value, 'order_user_info', dataFields?.order_user_info?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* order_user_info.patronymic */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Отчество"
                                id="oui-patronymic"
                                errorComponent={form.errors?.order_user_info?.patronymic &&
                                    <p className="form-input-hint">{form.errors.order_user_info?.patronymic.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.order_user_info?.patronymic,
                                    })}
                                    type="text"
                                    placeholder="Отчество"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required
                                    })}
                                    name="order_user_info.patronymic"
                                    id="oui-patronymic"
                                    defaultValue={dataFields?.order_user_info?.patronymic}
                                    readOnly={statusDisableAll}
                                    onChange={(e) => onChangeObj('patronymic', e.target.value, 'order_user_info', dataFields?.order_user_info?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* order_user_info.email */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label='E-mail*'
                                id="oui-email"
                                errorComponent={form.errors?.order_user_info?.email &&
                                    <p className="form-input-hint">{form.errors.order_user_info.email.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.order_user_info?.email,
                                    })}
                                    type="email"
                                    placeholder="E-mail"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="order_user_info.email"
                                    id="oui-email"
                                    defaultValue={dataFields?.order_user_info?.email}
                                    readOnly={statusDisableAll}
                                    onChange={(e) => onChangeObj('email', e.target.value, 'order_user_info', dataFields?.order_user_info?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* order_user_info.phone */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Телефон*"
                                id="oui-phone"
                                errorComponent={form.errors?.order_user_info?.phone &&
                                    <p className="form-input-hint">{form.errors.order_user_info.phone.message}</p>}
                            >
                                <InputMask mask="+389999999999" defaultValue={dataFields?.order_user_info?.phone} readOnly={statusDisableAll} onChange={handlePhone}>
                                    {(inputProps) => <input
                                        className={cn(s.input, {
                                            [s.input_error]: form.errors?.order_user_info?.phone,
                                        })}
                                        type="phone"
                                        placeholder="Телефон*"
                                        maxLength={INPUT_MAX_LENGTH}
                                        name="order_user_info.phone"
                                        id="oui-phone"
                                        ref={form.register({
                                            required: VALIDATION.req.required
                                        })}
                                        defaultValue={dataFields?.order_user_info?.phone}
                                        readOnly={statusDisableAll}
                                        onChange={handlePhone}
                                        {...inputProps}
                                    />}
                                </InputMask>
                                {!statusDisableAll && (
                                    <div className={s.phone_info} onClick={() => setPhoneInfo(!phoneInfo)}>
                                        <img src={icon_info} alt="Icon info" />
                                    </div>
                                )}
                            </InputWrapper>
                            {phoneInfo && <div className={s.phone_popup}><b>Например:</b> 0(44)441-02-23
                                или 0444410223</div>}
                        </ColFull>
                    </Group>

                    {/* order_user_info.callback_disabled */}
                    <Group attrs={{ className: 'mt-1 mb-1' }}>
                        <ColFull>
                            <div className={s_Select.checkbox__wrapper}>
                                <input
                                    className={s_Select.checkbox}
                                    type="checkbox"
                                    id="oui-phone_dont_call_back"
                                    name="order_user_info.callback_disabled"
                                    ref={form.register}
                                    defaultChecked={dataFields?.order_user_info?.callback_disabled}
                                    readOnly={statusDisableAll}
                                    onChange={(e) => onChangeObj('callback_disabled', e.target.checked, 'order_user_info', dataFields?.order_user_info?.id)}
                                />
                                <label htmlFor="oui-phone_dont_call_back" className={`${s_Select.label} ${s_Select.label_left}`}>Не перезванивать</label>
                            </div>
                        </ColFull>
                    </Group>

                    {/* order_user_info.card_number */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper label="Номер карточки покупателя" id="oui-card_number">
                                <input
                                    className={s.input}
                                    type="number"
                                    min={0}
                                    placeholder="Номер карточки покупателя"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="order_user_info.card_number"
                                    id="oui-card_number"
                                    defaultValue={dataFields?.order_user_info?.card_number}
                                    readOnly={statusDisableAll}
                                    onChange={(e) => onChangeObj('card_number', e.target.value, 'order_user_info', dataFields?.order_user_info?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* order_user_info.company_name */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper label="Компания" id="oui-company_name">
                                <input
                                    className={s.input}
                                    type="text"
                                    placeholder="Компания"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register}
                                    name="order_user_info.company_name"
                                    id="oui-company_name"
                                    defaultValue={dataFields?.order_user_info?.company_name}
                                    readOnly={statusDisableAll}
                                    onChange={(e) => onChangeObj('company_name', e.target.value, 'order_user_info', dataFields?.order_user_info?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* order_user_info.company_b2b */}
                    {companyNameWatch?.length > 0 && (
                        <Group attrs={{className: cn('mt-1 mb-1', {
                                [s.z_index_5]: statusDisableAll,
                            })}}>
                            <ColFull>
                                <InputWrapper label="Виключно для B2B" id="oui-company-b2b">
                                    <input
                                        className={s.input}
                                        type="text"
                                        placeholder="Виключно для B2B"
                                        maxLength={INPUT_MAX_LENGTH}
                                        ref={form.register}
                                        name="order_user_info.company-b2b"
                                        id="oui-company-b2b"
                                        defaultValue={dataFields?.order_user_info?.company_b2b}
                                        readOnly={statusDisableAll}
                                        onChange={(e) => onChangeObj('company_b2b', e.target.value, 'order_user_info', dataFields?.order_user_info?.id)}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>
                    )}

                </Form>

                {formProps.load && <Loader />}
            </form>
        </Item>
    )
}

export default OrderUserInfo
