import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import Item from "../Item";
import Form from "../../../components/ui/FormEditView/Form";
import Group from "../../../components/ui/FormEditView/Group";
import { INPUT_MAX_LENGTH } from "../../../const/view";
import Loader from "../../../components/ui/Loader";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import s_Select from "./orderStyle.module.scss";
import cn from "classnames";
import Button from "../../../components/ui/Button";
import InputWrapper from "../../../components/ui/InputWrapper";
import s from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import fetchAx from "../../../lib/fetchAx";
import { DOMAIN_API } from "../../../const/api";
import { ERROR_RESP, VALIDATION } from "../../../const/text";
import { useDispatch } from "react-redux";
import Constants from "../../../store/orderEdit/constants";

const OrderCreditInfo = ({ formProps, statusDisableAll }) => {
    const dispatch = useDispatch();

    const onChangeObj = (key, value, name, id) => dispatch({type: Constants.ON_CHANGE_OBJ, payload: {key, value, name, id}});

    const [creditType, setCreditType] = useState([]);
    const [creditOffer, setCreditOffer] = useState([]);
    const [creditBank, setCreditBank] = useState([]);
    const [creditBankConfirm, setCreditBankConfirm] = useState(null);
    const [creditBankApproved, setCreditBankApproved] = useState([]);
    const [disabledFields, setDisabledFields] = useState({
        credit_type_id: false,
        bank_id: false,
        credit_offer_id: false,
        surname: false,
        name: false,
        patronymic: false,
        tin: false,
        btnSend: false,
    });
    const [approvedBankId, setApprovedBankId] = useState(null);
    const dataFields = formProps.data && formProps.data.fields;
    const form = formProps.formH;

    const creditTypeId = form.watch('credit_user.credit_type_id');
    const creditBankWatch = form.watch('credit_user.bank_id');
    const creditOfferIdWatch = form.watch('credit_user.credit_offer_id');

    const getCreditType = (id) => {
        return fetchAx({
            url: `${DOMAIN_API}/api/admin/order/${id}/credits`,
            method: 'GET',
        });
    };

    const handleConfirmSubmit = async (id) => {
        try {
            await fetchAx({
                url: `${DOMAIN_API}/api/admin/order/${id}/credit-confirm`,
                method: 'POST',
                data: {
                    bank_id: approvedBankId
                }
            }).then((res) => {
                toast(res.description, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                // location.reload();
            })
        } catch (err) {
            if((err.response.status === 422) || (err.response.status === 400)){
                toast.error(
                    <>
                        <h3>Ошибки валидации:</h3>
                        {err.response.data.message &&  <p>- {err.response.data.message}</p>}
                    </>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            } else (
                toast.error(ERROR_RESP, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            )
        }
    };

    const handleSubmit = async (id) => {
        try {
            await fetchAx({
                url: `${DOMAIN_API}/api/admin/order/${id}/credit-request`,
                method: 'POST',
            }).then((res) => {
                toast(res.description, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                location.reload();
            })
        } catch (err) {
            if((err.response.status === 422) || (err.response.status === 400)){
                toast.error(
                    <>
                        <h3>Ошибки валидации:</h3>
                        {err.response.data.message &&  <p>- {err.response.data.message}</p>}
                    </>, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
            } else (
                toast.error(ERROR_RESP, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            )
        }
    };

    useEffect( async () => {
        await getCreditType(dataFields?.id)
            .then(res => {
                return setCreditType(res.data)
            })
            .catch(err => console.error(err));
    }, []);

    useEffect(() => {
        if(+creditTypeId) {
            const findCreditType = creditType?.find((item) => {
                return item.id === +creditTypeId
            })?.banks;
            const result = findCreditType?.map(item => ({bank_id: item.id, bank_name: item.title, credit_offers: item.credit_offers}));
            setCreditBank(result);
        } else {
            setCreditBank([]);
            setCreditOffer([]);
        }
    }, [creditTypeId]);

    useEffect(() => {
        if(+creditBankWatch) {
            const data = creditBank.find(item => item.bank_id === +creditBankWatch)?.credit_offers;

            if(data) {
                setCreditOffer(data);
            }
        } else {
            setCreditOffer([]);
        }

        if(!!dataFields?.credit_user?.approved_bank_ids?.length) {
            const findNameBankApproved = dataFields?.credit_user?.approved_bank_ids?.map(item => {
                return creditBank.filter(bank => bank.bank_id === item);
            }).flat();
            setCreditBankApproved(findNameBankApproved);
        }
    }, [creditBankWatch, creditTypeId]);
    useEffect(() => {
        if(dataFields?.credit_user?.credit_type_id && !!creditType?.length) {
            const findCreditType = creditType?.find((item) => item.id === dataFields?.credit_user?.credit_type_id)?.banks;
            if(findCreditType) {

                const result = findCreditType?.map(item => {
                    return ({bank_id: item.id, bank_name: item.title, credit_offers: item.credit_offers})
                });
                setCreditBank(result);
            }
        }

        if(dataFields?.credit_user?.bank_id && !!creditType?.length) {
            const data = creditType?.find(item => item.id === dataFields?.credit_user?.credit_type_id)?.banks;
            if(data) {
                const result = data?.find(item => {
                    return item.id === dataFields?.credit_user?.bank_id
                })?.credit_offers;
                setCreditOffer(result);
            }
        }
    }, [dataFields, creditType]);

    useEffect(() => {
        if(dataFields?.credit_user?.confirm_bank_id && !!creditBank?.length) {
            const result = creditBank?.find(item => item.bank_id === dataFields?.credit_user?.confirm_bank_id)
            setCreditBankConfirm(result?.bank_name);
        }
    }, [dataFields, creditBank]);

    useEffect(() => {
        if(dataFields?.credit_user?.status_process?.status === 'CREDIT_WAIT_CONFIRMATION') {
            setDisabledFields({
                credit_type_id: true,
                bank_id: true,
                credit_offer_id: true,
                surname: true,
                name: true,
                patronymic: true,
                tin: true,
                btnSend: true,
            });
        }

        if(dataFields?.credit_user?.status_process?.status === 'CREDIT_WAIT_BANK_RESPONSE') {
            setDisabledFields({
                credit_type_id: true,
                bank_id: true,
                credit_offer_id: true,
                surname: true,
                name: true,
                patronymic: true,
                tin: true,
                btnSend: true,
            });
        }
        if(dataFields?.credit_user?.status_process?.status === 'CREDIT_ISSUED') {
            setDisabledFields({
                credit_type_id: true,
                bank_id: true,
                credit_offer_id: true,
                surname: true,
                name: true,
                patronymic: true,
                tin: true,
                btnSend: true,
            });
        }
        if(dataFields?.credit_user?.status_process?.status === 'CREDIT_REJECTED') {
            setDisabledFields({
                credit_type_id: true,
                bank_id: true,
                credit_offer_id: true,
                surname: true,
                name: true,
                patronymic: true,
                tin: true,
                btnSend: true,
            });
        }
        if(dataFields?.credit_user?.status_process?.status === 'CREDIT_DRAFT') {
            setDisabledFields({
                credit_type_id: false,
                bank_id: false,
                credit_offer_id: false,
                surname: true,
                name: true,
                patronymic: true,
                tin: true,
                btnSend: false,
            });
        }

        if(!dataFields?.credit_user) {
            setDisabledFields({
                credit_type_id: false,
                bank_id: false,
                credit_offer_id: false,
                surname: false,
                name: false,
                patronymic: false,
                tin: false,
                btnSend: true,
            });
        }
    }, [dataFields]);

    return (
        <Item title="Информация о кредите" statusDisableAll={statusDisableAll}>
            <form>
                <button type="submit" className="d-hide" />
                <Form Tag="div">

                    {/* credit_user.credit_type_id */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    {formProps.data
                                        ? (
                                            <select
                                                name="credit_user.credit_type_id"
                                                className={s_Select.select}
                                                id="tf-credit_type_id"
                                                ref={form.register}
                                                disabled={disabledFields.credit_type_id}
                                                onChange={(e) => onChangeObj('credit_type_id', e.target.value, 'credit_user', dataFields?.credit_user?.id)}
                                            >
                                                <option value={null}>выберите к-во платежей</option>
                                                {creditType?.length !== 0 && creditType?.map((credit) => (
                                                    <option
                                                        key={credit.id}
                                                        value={credit.id}
                                                        selected={credit.id === dataFields?.credit_user?.credit_type_id}
                                                    >
                                                        {credit.title}
                                                    </option>
                                                ))}
                                            </select>
                                        )
                                        : (
                                            <div className="p-relative">
                                                <div className="loading" />
                                            </div>
                                        )}
                                    <label className={s_Select.label} htmlFor="tf-credit_type_id">Тип кредита / к-во платежей*</label>
                                </div>
                            </div>
                            {disabledFields.credit_type_id && (
                                <input type="hidden" name="credit_user.credit_type_id" defaultValue={dataFields?.credit_user?.credit_type_id} ref={form.register} />
                            )}
                        </ColFull>
                    </Group>

                    {/* credit_user.bank_id */}
                    {!!creditBank?.length && (
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <div className={s_Select.select__inner}>
                                    <div className={s_Select.select__wrapper}>
                                        {formProps.data
                                            ? (
                                                <select
                                                    name="credit_user.bank_id"
                                                    className={s_Select.select}
                                                    id="tf-bank_id"
                                                    ref={form.register}
                                                    disabled={disabledFields.bank_id}
                                                    onChange={(e) => onChangeObj('bank_id', e.target.value, 'credit_user', dataFields?.credit_user?.id)}
                                                >
                                                    <option value="">Банк не выбран</option>
                                                    {creditBank.map(bank => (
                                                        <option
                                                            key={bank.bank_id}
                                                            value={bank.bank_id}
                                                            selected={bank.bank_id === dataFields?.credit_user?.bank_id}
                                                        >
                                                            {bank.bank_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            )
                                            : (
                                                <div className="p-relative">
                                                    <div className="loading" />
                                                </div>
                                            )}
                                        <label className={s_Select.label} htmlFor="tf-bank">Банк*</label>
                                    </div>
                                </div>
                                {disabledFields.bank_id && (
                                    <input type="hidden" name="credit_user.bank_id" defaultValue={creditBankWatch || dataFields?.credit_user?.bank_id} ref={form.register} />
                                )}
                            </ColFull>
                        </Group>
                    )}

                    {/* credit_user.credit_offer_id */}
                    {!!creditOffer?.length && (
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <div className={s_Select.select__inner}>
                                    <div className={s_Select.select__wrapper}>
                                        {formProps.data
                                            ? (
                                                <select
                                                    name="credit_user.credit_offer_id"
                                                    className={s_Select.select}
                                                    id="tf-credit_offer_id"
                                                    ref={form.register}
                                                    disabled={disabledFields.credit_offer_id}
                                                    onChange={(e) => onChangeObj('credit_offer_id', e.target.value, 'credit_user', dataFields?.credit_user?.id)}
                                                >
                                                    {creditOffer?.length !== 0 && creditOffer.map((offer) => (
                                                        <option
                                                            key={offer.id}
                                                            value={offer.id}
                                                            selected={offer.id === dataFields?.credit_user?.credit_offer_id}
                                                        >
                                                            {offer.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            )
                                            : (
                                                <div className="p-relative">
                                                    <div className="loading" />
                                                </div>
                                            )}
                                        <label className={s_Select.label} htmlFor="tf-credit_offer_id">Название кредита</label>
                                    </div>
                                </div>
                                {disabledFields.credit_offer_id && (
                                    <input type="hidden" name="credit_user.credit_offer_id" defaultValue={creditOfferIdWatch || dataFields?.credit_user?.credit_offer_id} ref={form.register} />
                                )}
                            </ColFull>
                        </Group>
                    )}

                    {/* credit_user.surname */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Фамилия*"
                                id="tf-surname"
                                errorComponent={form.errors?.credit_user?.surname &&
                                <p className="form-input-hint">{form.errors?.credit_user?.surname.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.credit_user?.surname,
                                    })}
                                    type="text"
                                    placeholder="Фамилия*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="credit_user.surname"
                                    id="tf-surname"
                                    defaultValue={dataFields?.credit_user?.surname || dataFields?.order_user_info?.surname}
                                    readOnly={disabledFields.surname || statusDisableAll}
                                    onChange={(e) => onChangeObj('surname', e.target.value, 'credit_user', dataFields?.credit_user?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* credit_user.name */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Имя*"
                                id="tf-name"
                                errorComponent={form.errors?.credit_user?.name &&
                                <p className="form-input-hint">{form.errors?.credit_user?.name.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.credit_user?.name,
                                    })}
                                    type="text"
                                    placeholder="Имя*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="credit_user.name"
                                    id="tf-name"
                                    defaultValue={dataFields?.credit_user?.name || dataFields?.order_user_info?.name}
                                    readOnly={disabledFields.name || statusDisableAll}
                                    onChange={(e) => onChangeObj('name', e.target.value, 'credit_user', dataFields?.credit_user?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* credit_user.patronymic */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Отчество*"
                                id="tf-client_patronymic"
                                errorComponent={form.errors?.credit_user?.patronymic &&
                                <p className="form-input-hint">{form.errors?.credit_user?.patronymic.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.credit_user?.patronymic,
                                    })}
                                    type="text"
                                    placeholder="Отчество*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="credit_user.patronymic"
                                    id="tf-patronymic"
                                    defaultValue={dataFields?.credit_user?.patronymic || dataFields?.order_user_info?.patronymic}
                                    readOnly={disabledFields.patronymic || statusDisableAll}
                                    onChange={(e) => onChangeObj('patronymic', e.target.value, 'credit_user', dataFields?.credit_user?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* credit_user.tin */}
                    <Group attrs={{className: cn('mt-1 mb-1', {
                            [s.z_index_5]: statusDisableAll,
                        })}}>
                        <ColFull>
                            <InputWrapper
                                label="Идентификационный код*"
                                id="tf-tin"
                                errorComponent={form.errors?.credit_user?.tin &&
                                <p className="form-input-hint">{form.errors?.credit_user?.tin.message}</p>}
                            >
                                <input
                                    className={cn(s.input, {
                                        [s.input_error]: form.errors?.credit_user?.tin,
                                    })}
                                    type="number"
                                    placeholder="Идентификационный код*"
                                    maxLength={INPUT_MAX_LENGTH}
                                    ref={form.register({
                                        required: VALIDATION.req.required,
                                    })}
                                    name="credit_user.tin"
                                    id="tf-tin"
                                    defaultValue={dataFields?.credit_user?.tin}
                                    readOnly={disabledFields.tin || statusDisableAll}
                                    onChange={(e) => onChangeObj('tin', e.target.value, 'credit_user', dataFields?.credit_user?.id)}
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* credit_user.status_process */}
                    {dataFields?.credit_user?.status_process && (
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <div className={s_Select.text__wrapper}>
                                    <div>Статус заявки</div>
                                    <div className={s_Select.text}>{dataFields?.credit_user?.status_process?.name ? dataFields?.credit_user?.status_process?.name : dataFields?.credit_user?.status_process?.status}</div>
                                </div>
                            </ColFull>
                        </Group>
                    )}

                    {/* dataFields?.credit_user.bank_id */}
                    {dataFields?.credit_user?.status_process?.status !== 'CREDIT_WAIT_BANK_RESPONSE' &&
                     dataFields?.credit_user?.status_process?.status !== 'CREDIT_REJECTED' && (
                        !!creditBankApproved.length && !dataFields?.credit_user?.confirm_bank_id ? (
                            <>
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull>
                                        <div className={s_Select.select__inner}>
                                            <div className={s_Select.select__wrapper}>
                                                <select
                                                    name="approved_bank_ids"
                                                    className={s_Select.select}
                                                    id="tf-approved_bank_ids"
                                                    onChange={e => setApprovedBankId(e.target.value)}
                                                >
                                                    <option value="">Банк не выбран</option>
                                                    {creditBankApproved.map(bank => (
                                                        <option
                                                            key={bank.bank_id}
                                                            value={bank.bank_id}
                                                            selected={bank.bank_id === dataFields?.credit_user?.confirm_bank_id}
                                                        >
                                                            {bank.bank_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label className={s_Select.label} htmlFor="tf-approved_bank_ids">Подтвержденные заявки*</label>
                                            </div>
                                        </div>
                                    </ColFull>
                                </Group>

                                {/* button submit */}
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull>
                                        <Button purple full onClick={() => handleConfirmSubmit(dataFields?.id)}>Заявка на подтверждение кредита</Button>
                                    </ColFull>
                                </Group>
                            </>

                        ) : (
                            creditBankConfirm && (
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull>
                                        <div className={s_Select.text__wrapper}>
                                            <div>Кредит выдан в банке</div>
                                            <div className={s_Select.text}>{creditBankConfirm}</div>
                                        </div>
                                    </ColFull>
                                </Group>
                            )
                        )
                    )}

                    {/* button submit */}
                    {!dataFields?.credit_user?.approved_bank_ids?.length && (
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <Button purple full disabled={disabledFields.btnSend} onClick={() => handleSubmit(dataFields?.id)}>Отправить заявку</Button>
                            </ColFull>
                        </Group>
                    )}
                </Form>

                {formProps.load && <Loader />}
            </form>
        </Item>
    )
}

export default OrderCreditInfo