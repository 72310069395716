import React, { useEffect, useState } from 'react';
import { getWarehouse } from "../../../lib/api/order";
import Form from '../../../components/ui/FormEditView/Form';
import Loader from '../../../components/ui/Loader';
import Group from '../../../components/ui/FormEditView/Group';
import { order_delivery_type, order_type_payment } from '../../../lib/const/order';
import Item from "../Item";
import s_Select from "../../OrderEditView/fakeData/orderStyle.module.scss";
import ColFull from "../../../components/ui/FormEditView/ColFull";
import s from "../../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputDate from "../../../components/ui/InputDate";
import InputWrapper from "../../../components/ui/InputWrapper";
import s_FormSelect from "../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import { uid } from "react-uid";

const OrderDelivery = ({ formProps }) => {
    const dataFields = formProps.data && formProps.data.fields;
    const form = formProps.formH;
    const delivery_type = form.watch('delivery_type');
    const [warehouseData, setWarehouseData] = useState([]);

    useEffect(() => {
        getWarehouse({
            domain_id: dataFields?.domain_id
        }).then((res) => setWarehouseData(res.data.data));
    }, []);

    /* eslint-disable */
    return (
        <Item title="Информация о доставке">
            <form>
                <button type="submit" className="d-hide"/>
                <Form Tag="div">

                    {/* city */}
                    {((delivery_type === '6') || (delivery_type === '7') || (delivery_type === '8') || (delivery_type === '')) && (
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <div className={s_Select.select__inner}>
                                    <div className={s_Select.select__wrapper}>
                                        <select
                                            name="city"
                                            className={s_Select.select}
                                            id="to_city"
                                            ref={form.register}
                                            disabled
                                        >
                                            <option value="">выберите город</option>
                                            <option value={1} selected>Киев</option>
                                            <option value={2}>Львов</option>
                                        </select>
                                        <label className={s_Select.label} htmlFor="to_delivery_type">Город</label>
                                    </div>
                                </div>
                            </ColFull>
                        </Group>
                    )}

                    {/* delivery_type */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    <select
                                        name="delivery_type"
                                        className={s_Select.select}
                                        id="to_delivery_type"
                                        ref={form.register}
                                        disabled
                                    >
                                        <option value="">выберите тип доставки</option>
                                        {order_delivery_type.map((type) => (
                                            <option
                                                key={type.id}
                                                value={type.id}
                                                selected={type.id === dataFields?.delivery_type}
                                            >
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={s_Select.label} htmlFor="to_delivery_type">Служба доставки</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* delivery_type 8 */}
                    {delivery_type === '8' && (
                        <>
                            {/* Address */}
                            <Group attrs={{className: 'mt-1 mb-0'}}>
                                <ColFull>
                                    <InputWrapper label="Адрес доставки" id="to_address">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Адрес доставки"
                                            ref={form.register}
                                            name="address"
                                            id="to_address"
                                            readOnly
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* Time of delivery */}
                            <Group attrs={{className: 'mt-1 mb-0'}}>
                                <ColFull>
                                    <div className={s_Select.select__inner}>
                                        <div className={s_Select.select__wrapper}>
                                            <select
                                                name="delivery_time"
                                                className={s_Select.select}
                                                id="to_delivery_time"
                                                ref={form.register}
                                                disabled
                                            >
                                                <option value="">выберите время</option>
                                                <option value={1} selected>09:00 - 15:00</option>
                                                <option value={2}>15:01 - 18:00</option>
                                                <option value={3}>18:01 - 21:00</option>
                                            </select>
                                            <label className={s_Select.label} htmlFor="to_delivery_time">Время доставки</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>

                            {/* Floor */}
                            <Group attrs={{className: 'mt-1 mb-0'}}>
                                <ColFull>
                                    <div className={s_Select.select__inner}>
                                        <div className={s_Select.select__wrapper}>
                                            <select
                                                name="floor"
                                                className={s_Select.select}
                                                id="to_floor"
                                                ref={form.register}
                                                disabled
                                            >
                                                <option value="">выберите этаж</option>
                                                <option value={1} selected>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                                <option value={4}>4</option>
                                                <option value={5}>5</option>
                                                <option value={6}>6</option>
                                                <option value={7}>7</option>
                                                <option value={8}>8</option>
                                            </select>
                                            <label className={s_Select.label} htmlFor="to_floor">Этаж</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>

                            {/* Has elevator */}
                            <Group attrs={{className: 'mt-2 mb-2'}}>
                                <ColFull>
                                    <div className={s_FormSelect.checkbox__wrapper}>
                                        <input
                                            className={s_FormSelect.checkbox}
                                            type="checkbox"
                                            id="to_has_elevator"
                                            name="has_elevator"
                                            ref={form.register}
                                            readOnly
                                        />
                                        <label htmlFor="to_has_elevator" className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}>Лифт</label>
                                    </div>
                                </ColFull>
                            </Group>
                        </>
                    )}

                    {/* delivery_type 6, 7 */}
                    {((delivery_type === '6') || (delivery_type === '7')) && (
                        <>
                            {/* delivery_pickup.warehouse_id */}
                            <Group attrs={{className: 'mt-1 mb-0'}}>
                                <ColFull>
                                    <div className={s_Select.select__inner}>
                                        <div className={s_Select.select__wrapper}>
                                            {formProps.data
                                                ? (
                                                    <select
                                                        name="delivery_pickup.warehouse_id"
                                                        className={s_Select.select}
                                                        id="to_delivery_pickup.warehouse_id"
                                                        ref={form.register}
                                                        disabled
                                                    >
                                                        <option value="">выберите точку выдачи</option>
                                                        {warehouseData?.map((type) => (
                                                            <option
                                                                key={type.id}
                                                                value={type.id}
                                                                selected={type.id === dataFields?.delivery_pickup?.warehouse_id}
                                                            >
                                                                {type.title_ru || type.title_ua}
                                                            </option>
                                                        ))}
                                                    </select>
                                                )
                                                : (
                                                    <div className="p-relative">
                                                        <div className="loading" />
                                                    </div>
                                                )}
                                            <label className={s_Select.label} htmlFor="or-to_point_issue">Точка выдачи</label>
                                        </div>
                                    </div>
                                </ColFull>
                            </Group>

                            {/* Notify the customer about the delivery to the store */}
                            <Group attrs={{className: 'mt-2 mb-2'}}>
                                <ColFull>
                                    <div className={s_FormSelect.checkbox__wrapper}>
                                        <input
                                            className={s_FormSelect.checkbox}
                                            type="checkbox"
                                            id="to_notify_client"
                                            name="notify_client"
                                            ref={form.register}
                                            readOnly
                                        />
                                        <label htmlFor="to_notify_client" className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}>Сообщить клиенту про доставку в магазин</label>
                                    </div>
                                </ColFull>
                            </Group>
                        </>
                    )}

                    {/* type_payment */}
                    <Group attrs={{className: 'mt-1 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    <select
                                        name="type_payment"
                                        className={s_Select.select}
                                        id="to_type_payment"
                                        ref={form.register}
                                        disabled
                                    >
                                        <option value="">выберите способ</option>
                                        {order_type_payment.map((type) => (
                                            <option
                                                key={uid(type)}
                                                value={type.id}
                                                selected={type.id === dataFields?.type_payment}
                                            >
                                                {type.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label className={s_Select.label} htmlFor="to_type_payment">Вид оплаты</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* Estimated delivery date */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s.wrapper}>
                                <div className={s.input__wrapper}>
                                    <InputDate
                                        withZero={false}
                                        id="to_delivery_date"
                                        name="delivery_date"
                                        className={s.input}
                                        type="date"
                                        defVal=""
                                        placeholder="Выберите дату"
                                        formH={form}
                                        required={false}
                                    />
                                    <label className={s.label} htmlFor="to_delivery_date">Дата планируемой доставки</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* delivery_price */}
                    <Group attrs={{className: 'mt-1 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="Стоимость доставки" id="to_delivery_price">
                                <input
                                    className={s.input}
                                    type="number"
                                    placeholder="Стоимость доставки"
                                    ref={form.register}
                                    name="delivery_price"
                                    id="to_delivery_price"
                                    value={dataFields?.delivery_price}
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* ttn */}
                    <Group attrs={{className: 'mt-1 mb-0'}}>
                        <ColFull>
                            <InputWrapper label="ТТН" id="to_ttn">
                                <input
                                    className={s.input}
                                    type="number"
                                    placeholder="ТТН"
                                    ref={form.register}
                                    name="ttn"
                                    id="to_ttn"
                                    readOnly
                                />
                            </InputWrapper>
                        </ColFull>
                    </Group>

                    {/* delivery_status */}
                    <Group attrs={{className: 'mt-1 mb-1'}}>
                        <ColFull>
                            <div className={s_Select.select__inner}>
                                <div className={s_Select.select__wrapper}>
                                    <select
                                        name="delivery_status"
                                        className={s_Select.select}
                                        id="to_delivery_status"
                                        ref={form.register}
                                        disabled
                                    >
                                        <option value="">выберите статус</option>
                                        <option value="ready">Готов к отправке</option>
                                        <option value="wait">Ожидается</option>
                                        <option value="not_ready">Не готов</option>
                                    </select>
                                    <label className={s_Select.label} htmlFor="to_delivery_status">Статус
                                        доставки</label>
                                </div>
                            </div>
                        </ColFull>
                    </Group>

                    {/* comment_additional */}
                    <Group attrs={{className: 'mt-0 mb-0'}}>
                        <ColFull>
                            <div className={s_Select.textarea__wrapper}>
                                <textarea
                                    name="comment_additional"
                                    className={s_Select.textarea}
                                    id="to_comment_additional"
                                    placeholder="Комментарий пользователя"
                                    rows="4"
                                    ref={form.register}
                                    value={dataFields?.comment_additional}
                                    readOnly
                                />
                                <label className={s_Select.label__textarea} htmlFor="to_comment_additional">Комментарий
                                    пользователя</label>
                            </div>
                        </ColFull>
                    </Group>

                </Form>

                {formProps.load && <Loader/>}

            </form>
        </Item>
    );
};

export default OrderDelivery;
