export default (editor, opts = {}) => {
    const bm = editor.BlockManager;

    const style = `
        <style>
            div.col {
                min-height: 50px;
            }
            
            div.row-cols-custom-3 {
                display: flex;
                align-items: flex-start !important;
                justify-content: center;
            }
        </style>
    `;

    bm.add(opts.name, {
        label: `
            <div class="gjs-block-label">
                ${opts.label}
            </div>
        `,
        category: opts.category,
        content: `
            <div class="row row-cols-1 row-cols-sm-3 row-cols-custom-3">
                <div class="col mb-4 mb-sm-0"></div>
                <div class="col mb-4 mb-sm-0"></div>
                <div class="col"></div>
            </div>
            ${style}
        `,
    })
};