import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove
} from './api';
import MainContainer from './MainContainer';
import ItemsContainer from "./ItemsContainer";
import UserProfile from "./fakeData/userProfile";
import OrderUserInfo from "./fakeData/orderUserInfo";
import ProductsList from "./fakeData/productsList";
import OrderCreditInfo from "./fakeData/orderCreditInfo";
import OrderParamsInfo from "./fakeData/orderParamsInfo";
import OrderDelivery from "./fakeData/orderDelivery";
import OrderPreviousOrders from "./fakeData/orderPreviousOrders";
import OrderCommentHistory from "./fakeData/orderCommentHistory";
import { ModalCancelVariant, ModalChangeWarehouse } from "./fakeData/Modal";
import {checkVisitOrderEdit, getCallsHistory} from "../../lib/api/order";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import Constants from "../../store/orderEdit/constants";
import { scrollIntoViewHelper } from "../../lib/helpers/scrollIntoViewHelper";
import { getLoyaltyBalance } from "../../lib/api/loyalty";
import errorMessage from "../../lib/errorMessage";
import { refreshToken } from "../../lib/crud/refreshToken";
import { toast } from "react-toastify";
import OrderCallHistory from "./fakeData/orderCallsHistory";
import { getAudit } from "../../lib/api/audit";
import {orderEditSelector} from "../../store/orderEdit/selectors";

const orderPopupInfoId = [
  2053220,
  2053218,
  2053090,
  2053064,
  2052979,
  2052962,
  2052958,
  2052957,
  2052882,
  2052875,
  2052785,
  2052776,
  2052710,
  2052669,
  2052653,
  2052548,
  2052437,
  2052321,
  2052238,
  2052226,
  2052196,
  2052118,
  2051883,
  2051682,
  2051577,
  2051536,
  2051362,
  2050862,
  2050793,
  2050766,
  2050663,
  2050606,
  2050446,
  2050428,
  2050360,
  2049873,
  2049836,
  2049734,
  2049634,
  2048899,
  2048235,
  2047876,
  2047650,
  2047594,
  2052010,
  2052094,
  2052092,
  2051918,
  2052132,
  2052297,
  2052337,
  2052207,
  2052120,
  2052449,
  2052494,
  2052495,
  2052667,
  2052521,
  2052587,
  2052650,
  2052681,
  2052520,
  2052544,
  2052523,
  2052852,
  2052963,
  2052773,
  2052861,
  2052897,
  2052767,
  2053078,
  2053210,
  2053098,
  2053015,
  2052975,
  2052988,
  2053141,
  2053100,
  2053214,
  2053115,
  2047138,
  2047638,
  2048100,
  2048138,
  2047652,
  2048992,
  2049083,
  2049067,
  2049286,
  2049461,
  2049766,
  2050303,
  2050245,
  2050645,
  2050926,
  2050721,
  2051113,
  2051191,
  2051220,
  2051691,
  2051503,
  2051711,
  2051637,
  2051843,
  2051826,
  2051894
]

const OrderEditView = () => {
  const dispatch = useDispatch();
  const orderEdit = useSelector(orderEditSelector);
  const navigate = useNavigate();

  const clearReducer = () => dispatch({type: Constants.CLEAR_FORM, payload: {}});

  const { id: orderID } = useParams();
  const [bonusData, setBonusData] = useState(null);
  const [callsHistory, setCallsHistory] = useState([]);
  const [activeCancelVariant, setActiveCancelVariant] = useState(false);
  const [cancelVariants, setCancelVariants] = useState([]);
  const formH = useForm();
  const {
    handleSubmit,
    reset,
    watch,
  } = formH;

  const { data, setData, load, setLoad, submitHandler } = useEdit({
    targetID: orderID,
    // updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/order/not-found',
      remove: `/app/order${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/order${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Заказ создан',
      edit: 'Заказ обновлен',
      remove: 'Заказ удален',
    },
  });

  const formProps = {
    orderID,
    data,
    setData,
    handleSubmit,
    submitHandler,
    formH,
    load,
    setLoad,
  };

  const [orderStatus, setOrderStatus] = useState(null);
  const [oldOrderStatus, setOldOrderStatus] = useState(null);
  const [loadBonus, setLoadBonus] = useState(false);

  useEffect(() => {
    if(data?.fields?.user?.phone || data?.fields?.user_info?.phone) {
      setLoadBonus(true);
      const getHandleLoyaltyBalance = async () => {
        await getLoyaltyBalance(data?.fields?.user?.phone || data?.fields?.user_info?.phone)
          .then((res) => setBonusData(res.data))
          .catch(async (err) => await errorMessage(err, `/loyalty/user/balance/${data?.fields?.user?.phone || data?.fields?.user_info?.phone}`))
          .finally(() => setLoadBonus(false));
      }

      getHandleLoyaltyBalance()
    }

    if(data?.cancelVariants) {
      setCancelVariants(data?.cancelVariants)
    }
  }, [data]);

  useEffect(() => {
    if (!data?.fields?.binotel_calls?.length && data?.fields?.user_info?.phone) {
      const callsHistoryFunc = async () => {
        await getCallsHistory(data?.fields?.user_info?.phone)
          .then((res) => setCallsHistory(res.data))
          .catch(async (err) => await errorMessage(err, `/api/admin/binotel/${data?.fields?.user_info?.phone}`))
      }

      callsHistoryFunc()
    } else {
      setCallsHistory(data?.fields?.binotel_calls)
    }
  }, [data?.fields?.user_info?.phone]);

  const typePaymentWatch = watch('type_payment');

  const [checkEdit, setCheckEdit] = useState(false);
  const [editManager, setEditManager] = useState(null);
  const [ttnNumber, setTtnNumber] = useState(null);
  const [statusDisableAll, setStatusDisableAll] = useState(false);
  const [adminCanEdit, setAdminCanEdit] = useState(false);
  const [openModalChangeWarehouse, setOpenModalChangeWarehouse] = useState(false);
  const [fieldsReqEmail, setFieldsReqEmail] = useState({
    delivery_type: null,
    warehouse_id: null,
    status_payment: null,
  });

  const [requiredFields, setRequiredFields] = useState(true);
  const [productsData, setProductsData] = useState([]);

  const [totalPriceData, setTotalPriceData] = useState({
    total: 0,
    refunded: 0,
    price_final: 0,
  });

  const handleGetAuditOrder = async () => {
    await getAudit({order_id: data?.fields?.id})
        .then((res) => {
          if (res?.data?.data?.[0]?.new_values?.['core::core.audit.status'] === 'Смена магазина ЧЕКБОКС') {
            setOpenModalChangeWarehouse(true)
          } else {
            setOpenModalChangeWarehouse(false)
          }
        })
        .catch(async (err) => {
          if(err?.response?.status === 401) {
            await refreshToken(async () => {
              await getAudit({order_id: data?.fields?.id})
                  .then((res) => {
                    if (res?.data?.data?.[0]?.new_values?.['core::core.audit.status'] === 'Смена магазина ЧЕКБОКС') {
                      setOpenModalChangeWarehouse(true)
                    } else {
                      setOpenModalChangeWarehouse(false)
                    }
                  })
            })
          } else {
            await errorMessage(err, 'api/admin/audit GET')
          }
        })
  }

  useEffect(() => {

    switch (data?.fields?.status) {
      case 3:
      case 5:
      case 6:
      case 17:
      case 22:
      case 23:
      case 33: {
        setRequiredFields(false);
        break;
      }
    }

    switch (data?.fields?.status) {
      case 10:
      case 15:
      case 18:
      case 19:
      case 20:
      case 21:
      case 33: {
        setStatusDisableAll(true);
        break;
      }

      default: {
        setStatusDisableAll(false);
        break;
      }
    }

    const roleAdmin = JSON.parse(localStorage.getItem('roles'));

    if(!!roleAdmin?.length) {
      const check = roleAdmin?.filter(role => role === 'admin');
      if(!!check?.length) {
        setAdminCanEdit(true)
      } else {
        setAdminCanEdit(false)
      }
    }

    if(data?.editInfo) {
      setCheckEdit(data?.editInfo?.canEdit);
      setEditManager(data?.editInfo);
      delete data.editInfo;
    }
  }, [data]);

  useEffect(() => {
    let intervalId = setInterval(async () => {
      await checkVisitOrderEdit(orderID).then(res => {
        setCheckEdit(res?.data?.canEdit);
        setEditManager(res?.data);
      }).catch(async (err) => {
        if(err?.response?.status === 401) {
          setLoad(true)
          await refreshToken(async () => {
            setLoad(false)
            await checkVisitOrderEdit(orderID).then(res => {
              setCheckEdit(res?.data?.canEdit);
              setEditManager(res?.data);
            });
          })
        } else {
          await errorMessage(err, '')
        }
      }).finally(() => setLoad(false));
    }, 5000)

    return(() => {
      clearInterval(intervalId)
    })
  }, [checkEdit])

  useEffect(() => {
    clearReducer();

    if (orderPopupInfoId?.includes(+orderID)) {
      toast.error('"Внимание" По этому заказу выписана налоговая накладная. При внесении любого изменения в заказ, необходимо написать об этом письмо Куприянову Олегу, Шкляревской Алле и кассиру ПР7', {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
      })
    }
  }, [])

  useEffect(() => {
    if(!!Object.keys(formH?.errors)?.length) {
      scrollIntoViewHelper(formH?.errors);
    }
  }, [formH?.errors, orderEdit]);

  /* if order status = 33 get Audit and check change status */
  useEffect(() => {
    if (data?.fields?.status === 33) {
      handleGetAuditOrder()
    }
  }, [data])

  /* eslint-disable */
  return (
    <MainContainer submitHandler={submitHandler} handleSubmit={handleSubmit}>
      <Helmet title={orderID} />
      {data ? <ProductsList
        formProps={formProps}
        data={data}
        setData={setData}
        bonusData={bonusData}
        checkEdit={checkEdit}
        editManager={editManager}
        setTtnNumber={setTtnNumber}
        statusDisableAll={statusDisableAll}
        setRequiredFields={setRequiredFields}
        orderStatus={orderStatus}
        productsData={productsData}
        setProductsData={setProductsData}
        setOrderStatus={setOrderStatus}
        setBonusData={setBonusData}
        totalPriceData={totalPriceData}
        setTotalPriceData={setTotalPriceData}
        setOldOrderStatus={setOldOrderStatus}
      /> : <Loader />}
      <ItemsContainer>

        <div >
          {data ? <UserProfile data={data.fields} loadBonus={loadBonus} bonusData={bonusData} /> : <Loader />}
          {data ? <OrderCommentHistory formProps={formProps} /> : <Loader />}
          {data ? <OrderPreviousOrders formProps={formProps} /> : <Loader />}
          {data ? <OrderCallHistory formProps={formProps} callsHistory={callsHistory}/> : <Loader />}
        </div>
        <div>
          {data ? <OrderUserInfo
            formProps={formProps}
            statusDisableAll={statusDisableAll}
            requiredFields={requiredFields}
            fieldsReqEmail={fieldsReqEmail}
            orderStatus={orderStatus}
            oldOrderStatus={oldOrderStatus}
          /> : <Loader />}
        </div>
        <div>
          {data ? <OrderDelivery
            formProps={formProps}
            ttnNumber={ttnNumber}
            setTtnNumber={setTtnNumber}
            statusDisableAll={statusDisableAll}
            requiredFields={requiredFields}
            adminCanEdit={adminCanEdit}
            setFieldsReqEmail={setFieldsReqEmail}
            fieldsReqEmail={fieldsReqEmail}
            orderStatus={orderStatus}
            oldOrderStatus={oldOrderStatus}
            data={data}
          /> : <Loader />}
        </div>
        <div>
          {data ? <OrderParamsInfo
            statusDisableAll={statusDisableAll}
            formProps={formProps}
            setActiveCancelVariant={setActiveCancelVariant}
            setCancelVariants={setCancelVariants}
            cancelVariants={cancelVariants}
            setRequiredFields={setRequiredFields}
            requiredFields={requiredFields}
            adminCanEdit={adminCanEdit}
            setFieldsReqEmail={setFieldsReqEmail}
            productsData={productsData}
            orderStatus={orderStatus}
            setOrderStatus={setOrderStatus}
            setProductsData={setProductsData}
            setTotalPriceData={setTotalPriceData}
          /> : <Loader />}
        </div>
        {((typePaymentWatch === '3') || (data?.fields?.type_payment === 3)) && <OrderCreditInfo statusDisableAll={statusDisableAll} formProps={formProps} />}

      </ItemsContainer>

      {activeCancelVariant && (
        <ModalCancelVariant
          active={activeCancelVariant}
          setActive={setActiveCancelVariant}
          cancelVariants={cancelVariants}
          setCancelVariants={setCancelVariants}
          title="Редактирование причин отказа"
        />
      )}

      {openModalChangeWarehouse && (
          <ModalChangeWarehouse
              active={openModalChangeWarehouse}
              data={data}
              title="Изменить магазин"
          />
      )}
    </MainContainer>
  );
};

export default OrderEditView;