import Constants from "./constants";

export const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Constants.ON_CHANGE_ORDER_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.data
        }
      };

    case Constants.CLEAR_ORDER_FILTERS:
      return {
        ...state,
        filters: {}
      };

    default:
      return state;
  }
};

export default reducer;
