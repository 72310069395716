import Constants from "./constants";

export const setOnChange = (key, value) => ({
    type: Constants.ON_CHANGE,
    [key]: value,
});

export const setOnChangeObj = (key, value, name) => ({
    type: Constants.ON_CHANGE_OBJ,
    key,
    value,
    name,
    id,
});

export const setOnChangeProduct = (key, value, index) => ({
    type: Constants.ON_CHANGE_PRODUCT,
    value,
    index,
    key
});

export const setOnChangeProductService = (key, value, index, indexService) => ({
    type: Constants.ON_CHANGE_PRODUCT_SERVICE,
    value,
    index,
    key,
    indexService
});

export const setOnChangeProductOffer = (key, value, index, indexOffer) => ({
    type: Constants.ON_CHANGE_PRODUCT_OFFER,
    value,
    index,
    key,
    indexOffer
});

export const setNewISBPMDiscount = (status) => ({
    type: Constants.NEW_BPM_DISCOUNT,
    payload: {status},
});

export const setSelectedOrders = (orders) => ({
    type: Constants.ADD_SELECTED_ORDER,
    payload: orders,
})

export const clearOrderEditData = () => ({
    type: Constants.CLEAR_FORM,
    payload: {}
})
