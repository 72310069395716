import React, { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { VALIDATION } from '../../const/text';
import { INPUT_MAX_LENGTH } from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {
  create, edit, getData, parseDataGet, parseDataSend, remove
} from './api';
import s from "./PromocodeSeriesEditView.module.scss";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { Helmet } from "react-helmet";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import ColFull from "../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import NavFormTabs from "../../components/ui/NavFormTabs/NavFormTabs";
import Conditions from "./components/Conditions/Conditions";
import SimpleDiscount from "./components/SimpleDiscount/SimpleDiscount";
import PromocodeTab from "./components/PromocodeTab/PromocodeTab";

const PromocodeSeriesEditView = () => {
  const { id: serID } = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
    watch,
  } = formH;

  const { load, setLoad, data, submitHandler } = useEdit({
    targetID: serID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/promocode-series/not-found',
      remove: `/app/promocode-series${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/promocode-series${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Серия промокода создана',
      edit: 'Серия промокода обновлена',
      remove: 'Серия промокода удалена',
    },
  });

  const [activeTab, setActiveTab] = useState(0);

  const [conditions, setConditions] = useState([]);
  const [discountConditions, setDiscountConditions] = useState([]);

  const couponTypeWatch = watch('coupon_type');

  useEffect(() => {

    if(data?.fields?.conditions) {
      setConditions(data?.fields?.conditions)
    }
    if(data?.fields?.discount_conditions) {
      setDiscountConditions(data?.fields?.discount_conditions)
    }
  }, [data]);

  const [domainChoose, setDomainChoose] = useState('1');

  const [date, setDate] = useState({
    date_starts_at: '',
    date_ends_at: '',
  });
  const [time, setTime] = useState({
    time_starts_at: '',
    time_ends_at: '',
  });

  const findActiveCat = useMemo(() => {
    return conditions?.filter((condItem) => Array.isArray(condItem?.value));
  }, [conditions])

  useEffect(() => {
    if(data?.fields?.starts_at || data?.fields?.ends_at) {
      setDate({
        date_starts_at: data?.fields?.starts_at?.split(' ')[0],
        date_ends_at: data?.fields?.ends_at?.split(' ')[0],
      });

      setTime({
        time_starts_at: data?.fields?.starts_at?.split(' ')[1],
        time_ends_at: data?.fields?.ends_at?.split(' ')[1],
      });
    }
  }, [data]);

  useEffect(() => {
    if(data?.fields?.domains) {
      setDomainChoose(data?.fields?.domains[0]?.id);
    }
  }, [data?.fields?.domains]);

  /* eslint-disable */
  return (
      <>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Promocode edit`} />
        <Form attrs={{ onSubmit: handleSubmit(submitHandler), style: { paddingTop: 0} }} >

          <button type="submit" className="d-hide" />

          <TopPanelChild
              title={serID ? data && data?.fields?.name : "Новий промокод"}
              breadcrumbs={{
                items: [
                  {
                    title: "Головна",
                    url: "/app",
                  },
                  {
                    title: "Промокоди",
                    url: `/app/promocode-series${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                  },
                  {
                    title: serID ? data && data?.fields?.name: "Новий промокод"
                  },
                ],
              }}
              back={`/app/promocode-series${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
              id={serID}
              children={<div>
                {/* is_active */}
                <CheckboxSwitch
                    name="is_active"
                    id="b-is-active"
                    label="Активність"
                    formH={formH}
                    defaultChecked={data?.fields?.is_active}
                />
              </div>}
              btnEdit
          />

          {!load ? (
              <div className={s.top}>
                <div className={s.top__left}>
                  {/* name */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull>
                      <InputWrapper
                          label="Назва"
                          id="ps-name"
                          errorComponent={errors?.name &&
                          <p className="form-input-hint">{errors?.name?.message}</p>}
                      >
                        <input
                            className={cn(s_Input.input, {
                              [s_Input.input_error]: formH.errors?.name,
                            })}
                            type="text"
                            placeholder="Назва"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register({
                              required: VALIDATION.req.required
                            })}
                            name="name"
                            id="ps-name"
                            defaultValue={data?.fields?.name}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* desc_ua */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull>
                      <InputWrapper label="Опис" id="ps-desc-ua">
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Опис УКР"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="desc_ua"
                            id="ps-desc-ua"
                            defaultValue={data?.fields?.desc_ua}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* desc_ru */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull>
                      <InputWrapper label="Опис ХРУ" id="ps-desc-ru">
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Опис ХРУ"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="desc_ru"
                            id="ps-desc-ru"
                            defaultValue={data?.fields?.desc_ru}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* slug */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull>
                      <InputWrapper label="Slug" id="ps-slug">
                        <input
                            className={s_Input.input}
                            type="text"
                            placeholder="Slug"
                            ref={formH.register}
                            name="slug_new"
                            id="ps-slug"
                            defaultValue={data?.fields?.slug}
                        />
                      </InputWrapper>
                      <input
                          name="slug"
                          type="hidden"
                          defaultValue={data?.fields?.slug}
                          ref={formH.register}
                      />
                    </ColFull>
                  </Group>

                  {/* has_student_tickets */}
                  <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                    <ColFull>
                      <CheckboxSwitch
                          name="has_student_tickets"
                          id="ps-has-student-tickets"
                          label="Студентський квиток"
                          fontWeight={400}
                          formH={formH}
                          defaultChecked={data?.fields?.has_student_tickets}
                      />
                    </ColFull>
                  </Group>

                  {/* is_special_offer_compatible */}
                  <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                    <ColFull>
                      <CheckboxSwitch
                          name="is_special_offer_compatible"
                          id="ps-is-special-offer-compatible"
                          label="Сумісний із спец. пропозиціями"
                          fontWeight={400}
                          formH={formH}
                          defaultChecked={data?.fields?.is_special_offer_compatible}
                      />
                    </ColFull>
                  </Group>

                  {/* is_bonuses_compatible */}
                  <Group attrs={{ style: { margin: '0 0 16px 0' } }}>
                    <ColFull>
                      <CheckboxSwitch
                          name="is_bonuses_compatible"
                          id="ps-is-bonuses-compatible"
                          label="Сумісний із застосуванням бонусів"
                          fontWeight={400}
                          formH={formH}
                          defaultChecked={data?.fields?.is_bonuses_compatible}
                      />
                    </ColFull>
                  </Group>

                  {/* coupon_type */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull>
                      <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                          <select
                              name="coupon_type"
                              className={cn(s_Select.select, {
                                [s_Select.select_error]: formH.errors?.coupon_type,
                              })}
                              id="ps-coupon-type"
                              ref={formH.register({
                                required: VALIDATION.select.required,
                              })}
                          >
                            <option value="0">Без промокоду</option>
                            <option value="1">З промокодами</option>
                          </select>
                          <label className={s_Select.label} htmlFor="ps-coupon-type">Тип промокоду</label>
                        </div>
                        {formH.errors?.coupon_type
                        && <p className="form-input-hint">{formH.errors.coupon_type?.message}</p>}
                      </div>
                    </ColFull>
                  </Group>

                  {/* usage_per_user */}
                  {couponTypeWatch === '1' && (
                      <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                        <ColFull>
                          <InputWrapper
                              label="Кількість використань для одного клієнта"
                              id="ps-usage-per-user"
                          >
                            <input
                                className={s_Input.input}
                                type="number"
                                placeholder="Кількість використань для одного клієнта"
                                maxLength={INPUT_MAX_LENGTH}
                                ref={formH.register}
                                name="usage_per_user"
                                id="ps-usage-per-user"
                                defaultValue={data?.fields?.usage_per_user}
                            />
                          </InputWrapper>
                        </ColFull>
                      </Group>
                  )}

                  {/* uses_per_coupon */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColFull>
                      <InputWrapper
                          label="Кількість використання промокоду"
                          id="ps-uses-per-coupon"
                      >
                        <input
                            className={s_Input.input}
                            type="number"
                            placeholder="Кількість використання промокоду"
                            maxLength={INPUT_MAX_LENGTH}
                            ref={formH.register}
                            name="uses_per_coupon"
                            id="ps-uses-per-coupon"
                            defaultValue={data?.fields?.uses_per_coupon}
                        />
                      </InputWrapper>
                    </ColFull>
                  </Group>

                  {/* date_starts_at */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="date_starts_at"
                              id="ps-date-starts-at"
                              placeholder="Термін дії від"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.date_starts_at}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                date_starts_at: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="ps-date-starts-at">Термін дії від</label>
                        </div>
                        {errors?.date_starts_at
                        && <p className="form-input-hint">{errors?.date_starts_at?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_starts_at"
                              id="ps-time-starts-at"
                              placeholder="Час"
                              ref={register}
                              value={time.time_starts_at}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_starts_at: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="ps-time-starts-at">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                  {/* date_ends_at */}
                  <Group attrs={{ style: { margin: '0 0 6px 0' } }}>
                    <ColLeft col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="date"
                              name="date_ends_at"
                              id="ps-time-ends-at"
                              placeholder="Термін дії до"
                              ref={register({
                                required: VALIDATION.req.required,
                              })}
                              value={date.date_ends_at}
                              onChange={(e) => setDate((prev) => ({
                                ...prev,
                                date_ends_at: e.target.value
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="ps-time-ends-at">Термін дії до</label>
                        </div>
                        {errors?.date_ends_at
                        && <p className="form-input-hint">{errors?.date_ends_at?.message}</p>}
                      </div>
                    </ColLeft>
                    <ColRight col={6}>
                      <div className={s_Input.wrapper}>
                        <div className={s_Input.input__wrapper}>
                          <input
                              className={s_Input.input}
                              type="time"
                              name="time_ends_at"
                              id="ps-time-ends-to"
                              placeholder="Час"
                              ref={register}
                              value={time.time_ends_at}
                              onChange={(e) => setTime((prev) => ({
                                ...prev,
                                time_ends_at: `${e.target.value}:00`
                              }))}
                          />
                          <label className={s_Input.label} htmlFor="ps-time-ends-to">Час</label>
                        </div>
                      </div>
                    </ColRight>
                  </Group>

                  {/* domains */}
                  <Group attrs={{ style: { margin: 0 } }}>
                    <ColFull>
                      <div className={s_Select.select__inner}>
                        <div className={s_Select.select__wrapper}>
                          {data
                              ? (
                                  <select
                                      name="domains"
                                      className={cn(s_Select.select, {
                                        [s_Select.select_error]: formH.errors?.domain_id,
                                      })}
                                      id="ps-domains"
                                      ref={formH.register({
                                        required: VALIDATION.select.required,
                                      })}
                                      value={domainChoose}
                                      onChange={(e) => setDomainChoose(e.target.value)}
                                  >
                                    <option value="">виберіть домен</option>
                                    {data && !!data.domains && data.domains.map((domain) => (
                                        <option
                                            key={domain.id}
                                            value={domain.id}
                                        >
                                          {domain.title}
                                        </option>
                                    ))}
                                  </select>
                              )
                              : (
                                  <div className="p-relative">
                                    <div className="loading" />
                                  </div>
                              )}
                          <label className={s_Select.label} htmlFor="ps-domains">Домен</label>
                        </div>
                        {formH.errors?.domains
                        && <p className="form-input-hint">{formH.errors.domains?.message}</p>}
                      </div>
                    </ColFull>
                  </Group>
                </div>
                <div className={s.top__rigth}>
                  <NavFormTabs
                      tabs={[
                        {
                          title: 'Умови',
                          id: 0,
                          isActive: activeTab === 0
                        },
                        {
                          title: 'Дії',
                          id: 1,
                          isActive: activeTab === 1
                        },
                        {
                          title: 'Промокоди',
                          id: 2,
                          isActive: activeTab === 2
                        }
                      ]}
                      setActiveTab={setActiveTab}
                  />
                  <div className={s.right_block}>
                    {data && (
                        <>
                          <Conditions
                              activeTab={activeTab}
                              data={data}
                              conditions={conditions}
                              setConditions={setConditions}
                              formH={formH}
                          />
                          <SimpleDiscount
                              activeTab={activeTab}
                              data={data}
                              discountConditions={discountConditions}
                              setDiscountConditions={setDiscountConditions}
                              activeCat={findActiveCat}
                              formH={formH}
                          />
                          <PromocodeTab
                            activeTab={activeTab}
                            data={data}
                            serID={serID}
                            formH={formH}
                          />
                        </>
                    )}
                  </div>
                </div>
              </div>
          ) : <Loader />}
        </Form>
      </>
  )
};

export default PromocodeSeriesEditView;
