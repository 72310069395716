import React from 'react';
import s from "./Button.module.scss";
import cn from "classnames";

const Button = ({
                    type = "button",
                    className,
                    id = "",
                    full,
                    small,
                    green,
                    red,
                    white,
                    purple,
                    border,
                    borderClear,
                    onClick,
                    disabled,
                    borderRadius,
                    children,
                    link,
                    style
}) => {
    return (
        <button
            type={type}
            className={cn(`${s.btn} ${className ? className : ''}`, {
                [s.btn_full]: full,
                [s.btn_green]: green,
                [s.btn_red]: red,
                [s.btn_white]: white,
                [s.btn_purple]: purple,
                [s.btn_border]: border,
                [s.btn_border_clear]: borderClear,
                [s.btn_small]: small,
                [s.btn_border_radius]: borderRadius,
                [s.btn_link]: link
            })}
            disabled={disabled}
            onClick={onClick}
            style={style}
            id={id}
        >
            {children}
        </button>
    );
};

export default Button;