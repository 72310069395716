import React, { useEffect, useState } from 'react';
import { DOMAIN_API } from "../../../../const/api";
import fetchAx from "../../../../lib/fetchAx";
import { useDispatch, useSelector } from "react-redux";
import {
    createSuborderService,
    editOrder,
    getOrderProductServices,
    removeOrderCouponPks, removeOrderCouponVisa
} from "../../../../lib/api/order";
import Button from "../../../../components/ui/Button";
import s_Modal from './modal.module.scss';
import s_Table from "../orderTable.module.scss";
import s from "../../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import Constants from "../../../../store/orderEdit/constants";
import errorMessage from "../../../../lib/errorMessage";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/ui/Loader";
import { refreshToken } from "../../../../lib/crud/refreshToken";
import { parseDataGet } from "../../api";
import { removePromoFunc } from "../../helper/removePromo";

const ModalAdditionalServices = ({
                                     active,
                                     setActive,
                                     title,
                                     formProps,
                                     servicesChecked,
                                     paramsServices,
                                     productIndex,
                                     setTotalPriceData,
                                     productsData,
                                     setProductsData,
                                     statusDisableAll,
}) => {
    const dispatch = useDispatch();
    const orderEdit = useSelector(state => state.orderEdit);
    const navigate = useNavigate();

    const onChangeProductService = (product_id, data) => dispatch({
        type: Constants.ON_CHANGE_PRODUCT_SERVICE_MODAL,
        payload: { product_id, data }
    });
    const removeProductService = (product_id, service_id) => dispatch({
        type: Constants.REMOVE_PRODUCT_SERVICE_MODAL,
        payload: { product_id, service_id }
    });

    const [serviceData, setServiceData] = useState([]);
    const [disable, setDisable] = useState(false);
    const [removeData, setRemoveData] = useState([]);
    const [suborderData, setSuborderData] = useState([]);
    const [load, setLoad] = useState(false);

    const removeServices = async () => {
        if (!!removeData?.length) {
            for (let i = 0; i < removeData?.length; i++) {
                if (removeData[i].servId) {
                    fetchAx({
                        url: `${DOMAIN_API}/api/admin/order/${removeData[i].orderId}/products/${removeData[i].prodId}/additional-services/${removeData[i].servId}`,
                        method: 'DELETE',
                    }).then((res) => {
                        setTotalPriceData({
                            total: res.data.total,
                            refunded: res.data.refunded,
                            price_final: res.data.price_final,
                        });
                        setProductsData((prev) => {
                            const productsData = [...prev];

                            if (productsData[removeData[i].prodInd]) {
                                productsData[removeData[i].prodInd].product.additional_services?.splice(removeData[i].servInd, 1);
                                productsData[removeData[i].prodInd].product = {
                                    ...productsData[removeData[i].prodInd]?.product,
                                    price: res.data.products[removeData[i].prodInd].price,
                                    price_final: res.data.products[removeData[i].prodInd].price_final,
                                    total: res.data.products[removeData[i].prodInd].total,
                                    total_full: res.data.products[removeData[i].prodInd].total_full,
                                };
                            }

                            return productsData;
                        });
                    }).catch(async (err) => {
                        if (err?.response?.status === 401) {
                            await refreshToken(async () => {
                                await fetchAx({
                                    url: `${DOMAIN_API}/api/admin/order/${removeData[i].orderId}/products/${removeData[i].prodId}/additional-services/${removeData[i].servId}`,
                                    method: 'DELETE',
                                }).then((res) => {
                                    setTotalPriceData({
                                        total: res.data.total,
                                        refunded: res.data.refunded,
                                        price_final: res.data.price_final,
                                    });
                                    setProductsData((prev) => {
                                        const productsData = [...prev];

                                        if (productsData[removeData[i].prodInd]) {
                                            productsData[removeData[i].prodInd].product.additional_services?.splice(removeData[i].servInd, 1);
                                            productsData[removeData[i].prodInd].product = {
                                                ...productsData[removeData[i].prodInd]?.product,
                                                price: res.data.products[removeData[i].prodInd].price,
                                                price_final: res.data.products[removeData[i].prodInd].price_final,
                                                total: res.data.products[removeData[i].prodInd].total,
                                                total_full: res.data.products[removeData[i].prodInd].total_full,
                                            };
                                        }

                                        return productsData;
                                    });
                                })
                            })
                        } else {
                            await errorMessage(err, `/api/admin/order/${removeData[i].orderId}/products/${removeData[i].prodId}/additional-services/${removeData[i].servId} DELETE`)
                        }
                    });
                }
            }
        }
    }

    const handleServiceAdd = (index, data) => {
        setProductsData((prev) => {
            const productsData = [...prev];

            const parseData = {
                additional_service_id: data.id,
                quantity: 1,
                price: data.price,
                price_final: data.price_final,
                title: data.title,
                related_group: data.related_group,
            };

            if (productsData[index]) {
                productsData[index].product.additional_services.push(parseData);
            }

            return productsData;
        });
    };

    const handleServiceRemove = (product_id, service_id) => {
        setProductsData(prev => {
            const productsData = [...prev];
            const findProduct = productsData?.findIndex(prod => prod?.id?.toString() === product_id?.toString());
            const findService = productsData[findProduct]?.product?.additional_services?.findIndex(service => service?.additional_service_id?.toString() === service_id?.toString());

            if ((findProduct >= 0) && (findService >= 0) && !!productsData[findProduct]?.product?.additional_services?.length) {
                const tempService = [...productsData[findProduct]?.product?.additional_services];

                tempService.splice(findService, 1);

                productsData[findProduct]['product'] = {
                    ...productsData[findProduct]['product'],
                    additional_services: [...tempService],
                };
            }

            return productsData;
        });
    };

    const handleSuborder = () => {
        setLoad(true);

        const findProductService = suborderData?.map(service => ({
            additional_service_id: service.additional_service_id
        }));

        const parseData = {
            "order_product_id": productsData[productIndex]?.id,
            "additional_services": findProductService
        };

        createSuborderService(formProps?.orderID, parseData)
            .then(res => {
                navigate(`/app/order/update/${res?.data?.order_id}`);
                window.location.reload();
            })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await createSuborderService(formProps?.orderID, parseData)
                            .then(res => {
                                navigate(`/app/order/update/${res?.data?.order_id}`);
                                window.location.reload();
                            })
                    })
                } else {
                    await errorMessage(err, `/api/admin/order/${formProps?.orderID}/suborder POST`)
                }
            })
            .finally(() => setLoad(false));
    };

    useEffect(() => {
        getOrderProductServices(formProps?.orderID, paramsServices?.product_id).then((res) => {
            const result = res.data.map((item) => item);
            setServiceData(result);
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await getOrderProductServices(formProps?.orderID, paramsServices?.product_id).then((res) => {
                        const result = res.data.map((item) => item);
                        setServiceData(result);
                    })
                })
            } else {
                await errorMessage(err, '')
            }
        });
    }, []);

    const handleSend = async () => {
        setDisable(true);
        const parseData = orderEdit?.products?.filter(prod => prod);

        if (formProps?.data?.fields?.has_pks_discount) {
            await removeOrderCouponPks(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }
                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        if (formProps?.data?.fields?.has_visa_discount) {
            await removeOrderCouponVisa(formProps?.orderID)
                .then((res) => {
                    const parseData = parseDataGet(res?.data, formProps?.orderID);

                    if (formProps?.setData) {
                        formProps?.setData(prev => {
                            const temp = {...prev};

                            temp.fields = parseData;

                            return temp;
                        })
                    }
                    if (!!res?.data?.products?.length) {
                        res?.data?.products?.map((c, id) => {
                            setProductsData((prev) => {
                                const productsData = [...prev];
                                productsData[id] = {
                                    ...prev[id],
                                    product: {
                                        ...c.product,
                                        manager_id: c.manager_id,
                                        price: c.price,
                                        price_final: c.price_final,
                                        total: c.total,
                                        total_full: c.total_full,
                                        additional_services: c.additional_services,
                                        special_offers: c.special_offers,
                                        discounts: c.discounts
                                    },
                                    discount_pks: c.discount_pks,
                                    manager: c.manager,
                                    manager_id: c.manager_id,
                                    refunded: c.refunded,
                                    has_bundles: c.has_bundles,
                                    has_gifts: c.has_gifts,
                                    has_discount_offers: c.has_discount_offers,
                                    has_additional_services: c.has_additional_services,
                                    id: c.id,
                                    user_id: c.user_id,
                                    user_type: c.user_type,
                                    product_id: c.product_id,
                                    quantity: c.quantity,
                                    warehouse: c.warehouse,
                                    purchase_quantity: c.purchase_quantity,
                                    discount_amount: Math.round(c.discount_amount),
                                    discount_absolute: c.discount_absolute,
                                    discount_bpm: c.discount_bpm,
                                    total: c.total,
                                    discount_percent: c.discount_percent,
                                    selected: c.selected,
                                    return_quantity: c.return_quantity,
                                    has_referenced_items: c.has_referenced_items
                                };
                                return productsData;
                            })
                        })
                    }

                })
                .catch(async (err) => await errorMessage(err, ''))
        }

        if (formProps?.data?.fields?.id) {
            await removePromoFunc(formProps?.data?.fields?.id, formProps, dispatch)
        }

        await editOrder(formProps?.orderID, {...orderEdit, products: parseData}).then((res) => {
            setActive(false);
            window.location.reload();
        })
            .catch(async (err) => {
                if(err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await editOrder(formProps?.orderID, {...orderEdit, products: parseData}).then((res) => {
                            setActive(false);
                            window.location.reload();
                        })
                    })
                } else {
                    await errorMessage(err, `/api/admin/order/${formProps?.orderID} PUT`)
                }
            })
            .finally(() => setDisable(false));
    };

    useEffect(() => {
        if(!!productsData[productIndex]?.product?.additional_services?.length) {
            const parseService = productsData[productIndex]?.product?.additional_services?.map((service, index) => {
                const temp = {...service};

                temp['prodInd'] = productIndex;
                temp['servInd'] = index;
                temp['orderId'] = +formProps?.orderID;
                temp['prodId'] = +formProps?.data?.fields?.products[productIndex]?.id;
                temp['servId'] = +service?.id;
                temp['additionalId'] = +service?.id;
                return temp;
            })
            setRemoveData(parseService);
        }
    }, []);

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={s_Modal.modal__header}>
                    <div className={s_Modal.modal__title}>{title}</div>
                </div>
                {serviceData.length !== 0 ? (
                    <div className={s_Modal.modal__box}>
                        <div className={s_Table.table__inner}>
                            <div className={s_Table.table__wrapper}>
                                <table className={s_Table.table}>
                                    <thead className={s_Table.thead}>
                                        <tr>
                                            <th />
                                            <th>
                                                <div>Название товара</div>
                                            </th>
                                            <th>
                                                <div>Цена</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className={s_Table.tbody}>
                                    {!!productsData?.length && serviceData.map((item, index) => {
                                        let additionalServicesWatch = formProps.formH.watch(`products[${productIndex}].additional_services[${index}]`);
                                        const findService = servicesChecked?.find(service => service?.additional_service_id === item?.id);
                                        const findSuborderService = suborderData?.find(sub => sub?.additional_service_id?.toString() === item?.id?.toString())?.additional_service_id?.toString();
                                        if(servicesChecked) {
                                            additionalServicesWatch = findService?.additional_service_id === item.id;
                                        }

                                        if(findSuborderService) {
                                            additionalServicesWatch = true;
                                        }

                                        const serviceIndex = productsData[productIndex]?.product?.additional_services?.indexOf(findService);
                                        const serviceDisable = productsData[productIndex]?.product?.additional_services
                                            .filter(service => {
                                                if(service?.additional_service_id === item?.id) {
                                                    service['related_group'] = item?.related_group;
                                                }
                                                return service?.related_group === item?.related_group;
                                            })[0]?.related_group;

                                        const suborderServiceDisable = suborderData.filter(service => {
                                                if(service?.additional_service_id === item?.id) {
                                                    service['related_group'] = item?.related_group;
                                                }
                                                return service?.related_group === item?.related_group;
                                            })[0]?.related_group;

                                        const checkDisable = () => {
                                            if(suborderServiceDisable === item?.related_group) {
                                                return false;
                                            }

                                            if(serviceDisable === item?.related_group) {
                                                return false;
                                            }

                                            if(!!suborderData?.length) {
                                                return true;
                                            }

                                            if(productsData[productIndex]?.product?.additional_services?.length === 0) {
                                                return false;
                                            }

                                            return true;
                                        }

                                        return (
                                            <tr key={index}>
                                                <td>
                                                    <div className={s.checkbox__wrapper}>
                                                        <input
                                                            className={s.checkbox}
                                                            type="checkbox"
                                                            id={`additional_services_${index}`}
                                                            name={`products[${productIndex}].additional_services[${index}]`}
                                                            defaultChecked={additionalServicesWatch}
                                                            disabled={checkDisable()}
                                                            ref={formProps.formH.register}
                                                            onClick={(e) => {
                                                                if(e.currentTarget.checked) {
                                                                    if(formProps?.data?.fields?.status_payment?.toString() !== "2") {
                                                                        onChangeProductService(
                                                                            productsData[productIndex]?.id,
                                                                            {
                                                                                additional_service_id: item.id,
                                                                                quantity: 1,
                                                                                price: item.price,
                                                                                price_final: item.price_final,
                                                                                title: item.title,
                                                                                related_group: item.related_group
                                                                            }
                                                                        );
                                                                        setRemoveData(prev => {
                                                                            return ([
                                                                                ...prev,
                                                                                {
                                                                                    prodInd: productIndex,
                                                                                    servInd: serviceIndex ? serviceIndex : index,
                                                                                    orderId: +formProps?.orderID,
                                                                                    prodId: +productsData[productIndex]?.id,
                                                                                    servId: serviceIndex ? productsData[productIndex]?.product?.additional_services[serviceIndex]?.id : item?.id,
                                                                                    additionalId: +item.id,
                                                                                }
                                                                            ])
                                                                        });
                                                                        handleServiceAdd(productIndex, item);
                                                                    } else {
                                                                        setSuborderData(prev => ([...prev, {
                                                                            additional_service_id: item.id,
                                                                            related_group: item.related_group
                                                                        }]));
                                                                    }
                                                                } else {
                                                                    if(formProps?.data?.fields?.status_payment?.toString() !== "2") {
                                                                        removeProductService(productsData[productIndex]?.id, +item?.id);
                                                                        setRemoveData((prev) => {
                                                                            if(!!prev?.length) {
                                                                                const filterRemoveService = prev?.filter((serv) => serv?.additionalId !== +item?.id);
                                                                                return filterRemoveService;
                                                                            }
                                                                            return prev;
                                                                        });
                                                                        handleServiceRemove(productsData[productIndex]?.id, +item?.id);
                                                                    } else {
                                                                        setSuborderData(prev => {
                                                                            const result = prev?.filter(sub => sub?.additional_service_id?.toString() !== item?.id?.toString());
                                                                            if(result) {
                                                                                return result
                                                                            } else {
                                                                                return prev;
                                                                            }
                                                                        });
                                                                    }
                                                                }
                                                            }}
                                                        />
                                                        <label htmlFor={`additional_services_${index}`}
                                                               className={`${s.label} ${s_Table.label}`}/>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {item.title}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>{item.price}</div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    )
                    : (
                        <div className="p-relative">
                            <div className="loading" />
                        </div>
                    )}

                <div className={s_Modal.modal__footer}>
                    {formProps?.data?.fields?.status_payment?.toString() === "2" && (
                        <div style={{ whiteSpace: "nowrap", marginRight: "16px" }}>
                            <Button purple onClick={handleSuborder}>Отделить в подзаказ</Button>
                        </div>
                    )}
                    <div className={s_Modal.modal__group}>
                        {formProps?.data?.fields?.status_payment?.toString() !== "2" && (
                            <div>
                                <Button purple onClick={async () => {
                                    await removeServices();
                                    await handleSend();
                                }} disabled={statusDisableAll ? statusDisableAll : disable}>Добавить и сохранить</Button>
                            </div>
                        )}
                        <div>
                            <Button border onClick={() => setActive(false)}>Закрыть</Button>
                        </div>
                    </div>
                </div>
            </div>
            {load && <Loader />}
        </div>
    );
};

export default ModalAdditionalServices;