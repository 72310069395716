import Constants from "./constants";

export const setFilters = (data) => dispatch => dispatch({
  type: Constants.ON_CHANGE_ORDER_FILTERS,
  data,
});

export const clearFilters = (data) => dispatch => dispatch({
  type: Constants.CLEAR_ORDER_FILTERS,
  data,
});
