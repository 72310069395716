import React, { useEffect, useState } from "react";
import s_Modal from "./modal.module.scss";
import Button from "../../../../components/ui/Button";
import s_Select from "../orderStyle.module.scss";
import RSelect from "../../../../components/ui/RSelect";
import { useDispatch } from "react-redux";
import { setOnChange } from "../../../../store/orderEdit/action";
import { editOrder } from "../../../../lib/api/order";
import errorMessage from "../../../../lib/errorMessage";
import Loader from "../../../../components/ui/Loader";

const ModalChangeWarehouse = ({ active, title, data }) => {
    const dispatch = useDispatch();

    const [warehouseSelected, setWarehouseSelected] = useState(null);
    const [load, setLoad] = useState(false);

    const applyHandler = async () => {
        setLoad(true)
        await editOrder(data?.fields?.id, {
            warehouse_id: warehouseSelected.id
        })
            .then(res => window.location.reload())
            .catch((err) => errorMessage(err, ''))
            .finally(() => setLoad(false))
    }

    useEffect(() => {
        if(data?.fields?.warehouse) {
            setWarehouseSelected(data?.fields?.warehouse);
        }
    }, [data?.fields?.warehouse]);

    if (!data?.wareHouses?.length) return null

    return (
        <div className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}>
            <div className={s_Modal.modal__content}>
                <div className={s_Modal.modal__header}>
                    <div>
                        <div className={s_Modal.modal__title}>{title}</div>
                    </div>
                </div>
                <div>
                    <div className={s_Modal.wrapper__input}>
                        <div className={s_Select.select__inner}>
                            <div>
                                <RSelect
                                    props={{
                                        options: data?.wareHouses,
                                        getOptionLabel: (e)=> e.title_ru || e.title_ua,
                                        getOptionValue: (e) => e.id,
                                        classNamePrefix: 'react-select',
                                        placeholder: 'Склад отгрузки*',
                                        value: warehouseSelected,
                                        onChange: (e) => {
                                            if (e) {
                                                dispatch(setOnChange('warehouse_id', e?.id ? e?.id : ''))
                                                setWarehouseSelected(e);
                                            } else {
                                                dispatch(setOnChange('warehouse_id', null));
                                                setWarehouseSelected(null);
                                            }
                                        },
                                    }}
                                />
                                <label className={`${s_Select.label} ${s_Select.label_react_select}`} htmlFor="or-warehouse_id">Склад отгрузки*</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s_Modal.modal__footer}>
                    <div className={s_Modal.modal__group} style={{ justifyContent: 'center' }}>
                        <Button purple onClick={applyHandler} disabled={!warehouseSelected}>Изменить</Button>
                    </div>
                </div>
            </div>
            {load && <Loader />}
        </div>
    );
};

export default ModalChangeWarehouse;