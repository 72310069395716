import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { uid } from 'react-uid';
import { useDispatch } from "react-redux";
import cn from 'classnames';

import s from './FiltersRow.module.scss';
import s_Select from "../../../ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../../../../containers/OrderEditView/fakeData/orderTable.module.scss";
import Button from "../../../ui/Button";
import { getDeliveriesSettlements } from "../../../../lib/api/delivery";
import InputFetchResult from "../../../ui/InputFetchResultNew";

const TableListViewFiltersRow = ({
  urlParams = {}, fields = [], onSubmit, loading = false, setFilters
}) => {
  const formH = useForm();

  const { register, handleSubmit, setValue } = formH

  const dispatch = useDispatch();

  const [nullCardNumber, setNullCardNumber] = useState(false);
  const [nullCardNumberDisable, setNullCardNumberDisable] = useState(false);
  const [settlement, setSettlement] = useState(null);

  function onSubmitHandle(data) {
    debugger
    if (onSubmit) {
      if(typeof data['null_card_number'] !== 'undefined') {

        if(!!data['card_number']?.length) {
          data['null_card_number'] = '';
        }

        if(data['null_card_number'] === 'true') {
          data['null_card_number'] = 1;
        } else {
          data['null_card_number'] = '';
        }
      }

      if(typeof data['has_product_quantity_main_warehouses'] !== 'undefined') {
        if(!!data['has_product_quantity_main_warehouses']?.length) {
          data['has_product_quantity_main_warehouses'] = 1;
        } else {
          data['has_product_quantity_main_warehouses'] = '';
        }
      }

      delete data['manager_id_me'];
      delete data['updated_by_me'];

      if (setFilters) {
        dispatch(setFilters(data))
      }

      onSubmit(data);
    }
  }

  // on press enter
  function onEnterPress(e) {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();

      handleSubmit(onSubmitHandle)();
    }
  }

  // on change fields (mostly not text inputs)
  function changeHandler() {
    handleSubmit(onSubmitHandle)();
  }

  function changeHandlerCheckbox(e) {
    const id = localStorage.getItem('userId');
    const parseName = e.target.name.replaceAll('_me', '');

    if(e.target.checked) {
      setValue(e.target.name, id);
      setValue(parseName, id);

    } else {
      setValue(e.target.name, "");
      const parseName = e.target.name.replaceAll('_me', '');
      setValue(parseName, "");
    }

    handleSubmit(onSubmitHandle)();
  }

  useEffect(() => {
    if(urlParams?.null_card_number) {
      setNullCardNumber(!!urlParams?.null_card_number);
    }

    if(urlParams?.settlement_id) {
      getDeliveriesSettlements({ id: urlParams?.settlement_id }).then((res) => {
        if (res?.data?.data?.length > 0) {
          setSettlement(res?.data?.data[0])
        }
      })
    } else {
      setSettlement(null)
    }
  }, [urlParams]);

  return (
    <tr className={cn(s.wrap,
        { [s.wrapLoading]: loading })}
    >
      {Array.isArray(fields) && fields.map((f, index) => {
        let el;
        let secondEl;
        const valKey = f.optProps ? f.optProps[0] : 'id';
        const titleKey = f.optProps ? f.optProps[1] : 'title';
        if(f?.secondLvl) {
          switch (f?.secondLvl?.type) {
            case ('date'):
              secondEl = (
                  <label htmlFor={f?.secondLvl?.name}>
                    {f?.secondLvl?.label}
                    <input
                        type="date"
                        name={f?.secondLvl?.name}
                        id={f?.secondLvl?.name}
                        className="form-input"
                        onKeyDown={onEnterPress}
                        ref={register}
                        defaultValue={urlParams && urlParams[f?.secondLvl?.name]}
                        {...f?.secondLvl?.attr}
                    />
                  </label>
              );

              break;

            case ('text'):
              secondEl = (
                  <label
                      htmlFor={f?.secondLvl?.name}>
                    {f?.secondLvl?.label}
                    <input
                        type='text'
                        name={f?.secondLvl?.name}
                        className="form-input"
                        onKeyDown={onEnterPress}
                        ref={register}
                        defaultValue={urlParams && urlParams[f?.secondLvl?.name]}
                        {...f?.secondLvl?.attr}
                    />
                  </label>
              );

              break;

            case ('checkbox'):
              secondEl = (
                  <div className={s_Select.display_f}>
                    <div className={s_Select.checkbox__wrapper}>
                      <input
                          className={s_Select.checkbox}
                          type='checkbox'
                          name={f?.secondLvl?.name}
                          id={f?.secondLvl?.id}
                          ref={register}
                          onChange={changeHandlerCheckbox}
                          {...f?.secondLvl?.attr}
                      />
                      <label htmlFor={f?.secondLvl?.id} className={s_Select.label} />
                    </div>
                    <div className={s.no_wrap}>
                      {f?.secondLvl?.label && f?.secondLvl?.label}
                    </div>
                  </div>
              );

              break;

            case ('select'):
              secondEl = (
                  <select
                      name={f?.secondLvl?.name}
                      className="form-select"
                      ref={register}
                      defaultValue={urlParams && urlParams[f?.secondLvl?.name]}
                      onChange={changeHandler}
                      {...f?.secondLvl?.attr}
                  >
                    <option value="">---</option>
                    {f?.secondLvl?.options && f?.secondLvl?.options.map((e) => {
                      if (e) {
                        return (
                            <option
                                value={e[valKey]}
                                key={uid(e)}
                            >
                              {e[titleKey]}
                            </option>
                        )
                      }
                    })}
                  </select>
              );

              break;

            default:
              secondEl = null;
          }
        }
        switch (f.type) {
          case ('custom'):
            el = (
                <div>
                  <div>
                    <label
                        htmlFor={f.name}>
                      {f.label}
                      <input
                          type='text'
                          name={f.name}
                          className="form-input"
                          onKeyDown={onEnterPress}
                          onChange={(e) => {
                            setNullCardNumber(false);
                            if(!!e?.target?.value?.length) {
                              setNullCardNumberDisable(true);
                            } else {
                              setNullCardNumberDisable(false);
                            }
                          }}
                          ref={register}
                          defaultValue={urlParams && urlParams[f.name]}
                          {...f.attr}
                      />
                    </label>
                  </div>

                  <div className={s.flex}>
                    <input
                        type='hidden'
                        name='null_card_number'
                        ref={register}
                        value={nullCardNumber}
                    />
                    <div className={s_Select.checkbox__wrapper}>
                      <input
                          className={s_Select.checkbox}
                          type='checkbox'
                          name='null_card_number_second'
                          id='check_id'
                          checked={nullCardNumber}
                          disabled={nullCardNumberDisable}
                          onClick={(e) => {
                            setNullCardNumber(e.target.checked);
                            setValue('null_card_number', e.target.checked);
                            changeHandler();
                          }}
                          {...f.attr}
                      />
                      <label
                          htmlFor='check_id'
                          className={cn(s_Select.label, {
                            [s_Select.label_disable]: nullCardNumberDisable
                          })}
                      />
                    </div>
                    {nullCardNumber ? 'Без карты' : 'С картой' }
                  </div>
                </div>
            );

            break;

          case ('date'):
            el = (
                <label htmlFor={f.name}>
                  {f.label}
                  <input
                      type="date"
                      name={f.name}
                      id={f.name}
                      className="form-input"
                      onKeyDown={onEnterPress}
                      ref={register}
                      defaultValue={urlParams && urlParams[f.name]}
                      {...f.attr}
                  />
                </label>
            );

            break;

          case ('text'):
            el = (
                <label htmlFor={f.name} style={{ ...f?.style }}>
                  {f.label}
                  <input
                      type='text'
                      name={f.name}
                      className="form-input"
                      onKeyDown={onEnterPress}
                      ref={register}
                      defaultValue={urlParams && urlParams[f.name]}
                      {...f.attr}
                  />
                </label>
            );

            break;

          case ('checkbox'):
            el = (
                <div className={s_Select.checkbox__wrapper}>
                  <input
                      className={s_Select.checkbox}
                      type='checkbox'
                      name={f.name}
                      id={f.id}
                      ref={register}
                      onChange={changeHandler}
                      defaultChecked={urlParams && urlParams[f.name]}
                      {...f.attr}
                  />
                  <label htmlFor={f.id} className={s_Select.label} />
                </div>
            );

            break;

          case ('select'):
            el = (
                <select
                    name={f.name}
                    className="form-select"
                    ref={register}
                    defaultValue={urlParams && urlParams[f.name]}
                    onChange={changeHandler}
                    {...f.attr}
                >
                  <option value="">---</option>
                  {f.options && f.options.map((e) => {
                    if (e) {
                      return (
                          <option
                              value={e[valKey]}
                              key={uid(e)}
                          >
                            {e[titleKey]}
                          </option>
                      )
                    }
                  })}
                </select>
            );

            break;

          case ('settlement'):
            el = (
                <InputFetchResult
                    name="settlement_id"
                    form={formH}
                    searchParam="title"
                    func={getDeliveriesSettlements}
                    defVal={settlement}
                    id="head-filter-settlement-id"
                    placeholder="Город"
                    callback={(el) =>{
                      setSettlement(el)
                      setValue('settlement_id', el.id)
                      changeHandler()
                    }}
                    size="sm"
                />
            );

            break;

          default:
            el = null;
        }

        return <th key={uid(f)} className={s.baseline}>
          <div>
            {index === 0 && <Button className={s.btn_filter} purple onClick={changeHandler}>Фильтровать</Button>}
            {el}
            {secondEl && (
                <>
                  <div style={{fontSize: 10, textAlign: 'center'}}>-</div>
                  {secondEl}
                </>
            )}
          </div>
        </th>;
      })}
    </tr>
  );
};

export default TableListViewFiltersRow;
