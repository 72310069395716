import { getUsers, removeUser } from "../../lib/api/users";
import { getDomains } from "../../lib/api/domains";
import {getDeliveriesSettlements} from "../../lib/api/delivery";

export const getFiltersData = () => Promise.allSettled([
    getDomains(), getDeliveriesSettlements()
])
    .then((res) => ({
        domains: res[0]?.value?.data,
        cityList: res[1]?.value?.data?.data,
    }));

export const getData = getUsers;
export const remove = removeUser;