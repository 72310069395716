import React, {useEffect, useMemo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import cn from 'classnames';
import {VALIDATION} from '../../const/text';
import {INPUT_MAX_LENGTH} from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import {create, edit, getData, parseDataGet, parseDataSend, remove,} from './api';
import useController from './useController';
import getTZtoDate from "../../lib/getTZtoDate";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import TopPanelNew from "../../components/common/TopPanelNew";
import s from "./SpecOfferEdit.module.scss";
import s_OrderStyle from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputDateNew from "../../components/ui/InputDateNew";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import ColFull from "../../components/ui/FormEditView/ColFull";
import CheckboxList from "../../components/ui/CheckboxList";
import TypeOne from "./component/TypeOne";
import TypeSecond from "./component/TypeSecond";
import TypeThree from "./component/TypeThree";
import TypeSix from "./component/TypeSix";
import RSelect from "../../components/ui/RSelect";
import {getDeliveries} from "../../lib/api/delivery";
import TypeThreeNew from "./component/TypeThreeNew";
import useList from "../../lib/crud/useList";
import {getFiltersData} from "../BanksList/api";

const SpecOfferViewEdit = () => {
  const {id: soID} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    reset,
    watch,
    setValue
  } = formH;

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [domainChoose, setDomainChoose] = useState(null);
  const [specOfferOptionsChoose, setSpecOfferOptionsChoose] = useState([]);

  // TYPE 1
  const [bonusTypeID, setBonusTypeID] = useState(null);
  const [discountType, setDiscountType] = useState(null);
  const [selectValue, setSelectValue] = useState('');

  // TYPE 3
  const [discountType3, setDiscountType3] = useState('');
  const [selectedType3, setSelectedType3] = useState(null);

  //DELIVERY_TYPES
  const [deliveriesType, setDeliveriesType] = useState([]);

  const watchMainProductDiscount = watch('is_main_product_discount');
  const watchPromoProductDiscount = watch('is_promo_product_discount');

  const discountPercentType3 = watch('special_offer_type3_discount.discount_percent');
  const discountValueType3 = watch('special_offer_type3_discount.discount_value');

  const {load, data, submitHandler} = useEdit({
    targetID: soID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/spec-offer/not-found',
      remove: location?.state?.from ? location?.state?.from : `/app/spec-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: location?.state?.from ? location?.state?.from : `/app/spec-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Специальное предложение создано',
      edit: 'Специальное предложение обновлено',
      remove: 'Специальное предложение удалено',
    },
  });

  const {state, action, render} = useController({
    formH,
    defaultValues: data && data?.fields,
    markers: data && data?.markers,
    domains: data && data?.domains,
    categories: data && data?.categories,
    additional_services: data && data?.additional_services,
    unaccounted_products: data && data?.unaccounted_products,
    bonus_type: data && data?.bonus_type,
  });

  const [time, setTime] = useState({
    time_from: '',
    time_to: '',
  });

  const specialOfferList = useMemo(() => {
    if (!!data?.special_offers?.length) {
      const tempList = [...data?.special_offers];

      if (domainChoose) {
        return tempList?.filter(item => {
          return item?.domain_id?.toString() === domainChoose?.toString()
        })
      }
    } else {
      return [];
    }
  }, [data?.special_offers, state?.type?.id, domainChoose]);

  const isDiscountApplyRequired = watch('is_discount_apply_required');

  useEffect(() => {
    if (!!data?.fields?.options?.length && !!data?.special_offers_options?.length) {
      const result = []
      data?.fields?.options.forEach(item => {
        const findOpt = data?.special_offers_options?.find(opt => opt?.value === item)
        if (findOpt) {
          result.push(findOpt)
        }
      })
      setSpecOfferOptionsChoose(result)
    }
  }, [data?.fields, data?.special_offers_options]);

  useEffect(() => {
    if (data?.fields) {
      const timeFrom = getTZtoDate(data?.fields?.date_from, true).split(' ')[1];
      const timeTo = getTZtoDate(data?.fields?.date_to, true).split(' ')[1];
      setTime({
        time_from: `${timeFrom}:00`,
        time_to: `${timeTo}:00`,
      });
    } else {
      setTime({
        time_from: `00:00:00`,
        time_to: `00:00:00`,
      });
    }

    if (data?.fields?.domain_id) {
      setDomainChoose(data?.fields?.domain_id);
    }
  }, [data?.fields]);

  useEffect(() => {
    // TYPE 1
    if (data?.fields?.special_offer_type1_bonus?.bonus_type) {
      setBonusTypeID(data?.fields?.special_offer_type1_bonus?.bonus_type)
    }

    if (data?.fields?.special_offer_type1_bonus?.bonus_type3?.discount_percent) {
      setDiscountType('1')
    }

    if (data?.fields?.special_offer_type1_bonus?.bonus_type3?.discount_value) {
      setDiscountType('2')
    }

    // TYPE 3
    if (data?.fields?.special_offer_type3_discount?.discount_percent) {
      setDiscountType3('1')
    }

    if (data?.fields?.special_offer_type3_discount?.discount_value) {
      setDiscountType3('2')
    }
  }, [data?.fields]);

  useEffect(() => {
    if(data?.fields?.is_main_product_discount){
      setValue('is_main_product_discount', data?.fields?.is_main_product_discount);
    }

    if(data?.fields?.is_promo_product_discount){
      setValue('is_promo_product_discount', data?.fields?.is_promo_product_discount);
    }
  }, [data?.fields]);

  useEffect(() => {
    if (!!data?.fields?.compatible_special_offers?.length) {
      const parseCompatible = data?.fields?.compatible_special_offers?.reduce((a, c) => [...a, {
        id: c,
        title_ua: data?.special_offers
            .find((e) => e.id === c)?.title_ua,
      }], []);
      setSelectedType3(parseCompatible);
    }
  }, [data?.fields?.compatible_special_offers, data?.special_offers]);

  useEffect(() => {
    if (discountType) {
      setSelectValue(discountType)
    }
  }, [discountType]);
  useEffect(() => {
    const pT = state?.paymentTypes?.reduce((a, c) => [...a, {
      id: c.payment_type,
      title: data?.payment_type
          .find((e) => e.id === c.payment_type)?.title_ua,
    }], []);
    setPaymentTypes(pT);
  }, [state?.paymentTypes, data?.paymentTypes]);

  useEffect(() => {
    const pT = state?.deliveryTypes?.reduce((a, c) => [...a, {
      id: c.delivery_type,
      title: data?.delivery_type
          .find((e) => e.id === c.delivery_type)?.title_ua,
    }], []);
    setDeliveryTypes(pT);
  }, [state?.deliveryTypes, data?.deliveryTypes]);

  useEffect(() => {
    if (state.type.id || bonusTypeID) {
      setActiveTab(0);
    }
  }, [state.type.id, bonusTypeID])

  /* deliveries_types */
  useEffect(async () => {
      const deliveries = await getDeliveries({lang: 'ua', domain_id: domainChoose, per_page: 100});
      setDeliveriesType(deliveries?.data?.data)
  }, [domainChoose])

  /* selected_deliveries */
  useEffect( () => {
    const deliveries = data?.fields?.delivery_ids?.map((id) => ({id}));
    setDeliveryTypes(deliveries)
  }, [data?.fields?.delivery_ids])

  const switcherHandler = (value, name) => {
    // console.log(watchMainProductDiscount, watchPromoProductDiscount)

    if(name === 'is_main_product_discount' && watchPromoProductDiscount){
      setValue('is_promo_product_discount', false);
    }

    if(name === 'is_promo_product_discount' && watchMainProductDiscount){
      setValue('is_main_product_discount', false);
    }
  }

  /* eslint-disable */
  return (
      <>
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Spec offer edit`}/>
        <Form attrs={{
          onSubmit: handleSubmit(submitHandler),
        }}
        >
          <button type="submit" className="d-hide"/>
          <TopPanelNew
              title={soID ? data && data?.fields?.title_ua : "Нова спеціальна пропозиція"}
              breadcrumbs={{
                items: [
                  {
                    title: "Dashboard",
                    url: "/app",
                  },
                  {
                    title: "Спеціальні пропозиції",
                    url: location?.state?.from ? location?.state?.from : `/app/spec-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                  },
                  {
                    title: soID ? data && data?.fields?.title_ua : "Нова спеціальна пропозиція",
                  },
                ],
              }}
              back={location?.state?.from ? location?.state?.from : `/app/spec-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
              updateDate={data?.fields?.updated_at && getTZtoDate(data?.fields?.updated_at, true)}
              btnRemove={{
                api: remove,
                alert: `Видалити “${data?.fields?.title_ua}”?`,
                href: location?.state?.from ? location?.state?.from : `/app/spec-offers${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                id: soID,
                navigate
              }}
              btnEdit
              btnApply={!!soID}
              marginNone
              showIsActive={(
                  <div style={{display: 'flex', gap: 16}}>
                    {((bonusTypeID?.toString() === '3') && (state?.type?.id?.toString() === '1')) && (
                        <CheckboxSwitch
                            name="is_discount_apply_required"
                            id="im-is-discount-apply_required"
                            label="Тільки для корзини"
                            formH={formH}
                            defaultChecked={data?.fields?.is_discount_apply_required}
                        />
                    )}

                    <CheckboxSwitch
                        name="is_active"
                        id="im-is-active"
                        label="Активность"
                        formH={formH}
                        defaultChecked={data?.fields?.is_active}
                    />
                  </div>
              )}
          />
          <div style={{display: "flex", marginTop: "34px"}}>
            <div style={{width: "420px", marginRight: "32px"}}>
              {/* title_ua */}
              <div className={s.main__input}>
                <InputWrapper
                    label="Назва УКР*"
                    id="so-name-ua"
                    errorComponent={formH.errors?.title_ua &&
                        <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                >
                  <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH.errors?.title_ua,
                      })}
                      type="text"
                      placeholder="Назва УКР*"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={formH.register({
                        required: VALIDATION.req.required
                      })}
                      name="title_ua"
                      id="so-name-ua"
                      defaultValue={data?.fields?.title_ua}
                  />
                </InputWrapper>
              </div>

              {/* title_ru */}
              <div className={s.main__input}>
                <InputWrapper
                    label="Назва РУС*"
                    id="so-name-ru"
                    errorComponent={formH.errors?.title_ru &&
                        <p className="form-input-hint">{formH?.errors?.title_ru?.message}</p>}
                >
                  <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH.errors?.title_ru,
                      })}
                      type="text"
                      placeholder="Назва РУС*"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={formH.register({
                        required: VALIDATION.req.required
                      })}
                      name="title_ru"
                      id="so-name-ru"
                      defaultValue={data?.fields?.title_ru}
                  />
                </InputWrapper>
              </div>

              {/* date_from */}
              <div className={s.main__date}>
                <InputDateNew
                    name="date_from"
                    className="form-input"
                    id="so-date-from"
                    defVal={data?.fields?.date_from.split(' ')[0]}
                    placeholder="Термін дії від"
                    formH={formH}
                    withZero={false}
                />

                <div className={s_Input.wrapper}>
                  <div className={s_Input.input__wrapper}>
                    <input
                        className={s_Input.input}
                        type="time"
                        name="time_from"
                        id="so-time_from"
                        placeholder="Час"
                        ref={register}
                        value={time.time_from}
                        onChange={(e) => setTime((prev) => ({
                          ...prev,
                          time_from: `${e.target.value}:00`
                        }))}
                    />
                    <label className={s_Input.label} htmlFor="so-time_from">Час</label>
                  </div>
                </div>
              </div>

              {/* date_to */}
              <div className={s.main__date}>
                <InputDateNew
                    name="date_to"
                    className="form-input"
                    id="so-date-to"
                    defVal={data?.fields?.date_to.split(' ')[0]}
                    placeholder="Термін дії до"
                    formH={formH}
                    withZero={false}
                />

                <div className={s_Input.wrapper}>
                  <div className={s_Input.input__wrapper}>
                    <input
                        className={s_Input.input}
                        type="time"
                        name="time_to"
                        id="so-time_to"
                        defaultValue={data?.fields?.time_to?.split(' ')[0]}
                        placeholder="Час"
                        ref={register}
                        value={time.time_to}
                        onChange={(e) => setTime((prev) => ({
                          ...prev,
                          time_to: `${e.target.value}:00`
                        }))}
                    />
                    <label className={s_Input.label} htmlFor="so-time_to">Час</label>
                  </div>
                </div>
              </div>

              {/* types */}
              <div>
                <div className={s_OrderStyle.select__inner}>
                  <div className={s_OrderStyle.select__wrapper}>
                    <select
                        name="type"
                        className={cn(s_OrderStyle.select, {
                          [s_OrderStyle.select_error]: errors?.type,
                        })}
                        id="so-type"
                        ref={register({
                          required: VALIDATION.select.required,
                        })}
                        onChange={action.setType}
                        defaultValue={state.type.id}
                    >
                      <option value={null}>Вибрати тип</option>
                      <option value={1}>Акционные товары</option>
                      <option value={2}>Купи А - получи Б в подарок</option>
                      <option value={3}>Купи А - получи Б со скидкой</option>
                      {/*<option value={4}>Купи А+Б - получи скидку на комплект</option>*/}
                      <option value={6}>Купи А - получи Б и В в подарок</option>
                      {/*<option value={8}>Акция с бонусами</option>*/}
                    </select>
                    <label className={s_OrderStyle.label} htmlFor="so-type">Тип пропозиції*</label>
                  </div>
                  {errors?.type && <p className="form-input-hint">{errors.type?.message}</p>}
                </div>
              </div>


              {/* special_offer_type1_bonus.bonus_type */}
              {(state?.type?.id?.toString() === '1') && (
                  <>
                    <div>
                      <div className={s_OrderStyle.select__inner}>
                        <div className={s_OrderStyle.select__wrapper}>
                          <select
                              name="special_offer_type1_bonus.bonus_type"
                              className={s_OrderStyle.select}
                              id="so-bonus-type"
                              ref={register}
                              onChange={(e) => setBonusTypeID(e.target.value)}
                              defaultValue={bonusTypeID}
                          >
                            <option value={null}>Вибрати тип бонуса</option>
                            {data && data?.bonus_type?.map(bonusType => (
                                <option
                                    key={`so-bonus-types-${bonusType.id}`}
                                    value={bonusType.id}
                                >
                                  {bonusType.title}
                                </option>
                            ))}
                          </select>
                          <label className={s_OrderStyle.label} htmlFor="so-bonus-type">Тип
                            бонуса</label>
                        </div>
                      </div>
                    </div>
                    {bonusTypeID?.toString() === '3' && (
                        <div className={s.discount__wrapper}>
                          <div className={s_OrderStyle.select__inner}>
                            <div className={s_OrderStyle.select__wrapper}>
                              <select
                                  className={s_OrderStyle.select}
                                  id="so-discount-type"
                                  onChange={(e) => setSelectValue(e.target.value)}
                                  value={selectValue}
                              >
                                <option value="">Виберите знижку</option>
                                <option value="1">Відсоткова знижка</option>
                                <option value="2">Абсолютна знижка</option>
                              </select>
                              <label className={s_OrderStyle.label} htmlFor="so-discount-type">Розмір
                                знижки</label>
                            </div>
                          </div>
                          <div>
                            {(selectValue.toString() === '1') && (
                                <div className={s_Input.wrapper}>
                                  <div className={s_Input.input__wrapper}>
                                    <input
                                        className={cn(s_Input.input, {
                                          'is-error': errors.special_offer_type1_bonus_type3?.discount_percent,
                                        })}
                                        type="number"
                                        ref={register({
                                          required: VALIDATION.req.required,
                                        })}
                                        name="special_offer_type1_bonus_type3.discount_percent"
                                        id="so-discount-percent"
                                        defaultValue={data?.fields?.special_offer_type1_bonus?.bonus_type3?.discount_percent}
                                        placeholder="Відсоток знижки"
                                    />
                                    <label className={s_Input.label} htmlFor="so-discount-percent">Відсоток
                                      знижки</label>
                                  </div>
                                  {errors.special_offer_type1_bonus_type3?.discount_percent
                                      &&
                                      <p className="form-input-hint">
                                        {errors.special_offer_type1_bonus_type3.discount_percent.message}
                                      </p>
                                  }
                                </div>
                            )}
                            {(selectValue.toString() === '2') && (
                                <div className={s_Input.wrapper}>
                                  <div className={s_Input.input__wrapper}>
                                    <input
                                        className={cn(s_Input.input, {
                                          'is-error': errors.special_offer_type1_bonus_type3?.discount_value,
                                        })}
                                        type="number"
                                        ref={register({
                                          required: VALIDATION.req.required,
                                        })}
                                        name="special_offer_type1_bonus_type3.discount_value"
                                        id="so-discount-value"
                                        defaultValue={data?.fields?.special_offer_type1_bonus?.bonus_type3?.discount_value}
                                        placeholder="Абсолютна знижка"
                                    />
                                    <label className={s_Input.label} htmlFor="so-discount-value">Абсолютна
                                      знижка</label>
                                  </div>
                                  {errors.special_offer_type1_bonus_type3?.discount_value
                                      &&
                                      <p className="form-input-hint">
                                        {errors.special_offer_type1_bonus_type3.discount_value.message}
                                      </p>
                                  }
                                </div>
                            )}
                          </div>
                        </div>
                    )}
                  </>
              )}

              {/* is_promo_product_discount */}
              {state?.type?.id?.toString() === '3' && (
                  <Group>
                    <ColFull>
                      <div style={{
                        margin: "5px 0px",
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}>
                        <div style={{paddingBottom: '20px'}}>
                          <CheckboxSwitch
                              name="is_main_product_discount"
                              id="is_main_product_discount"
                              label="Скидка только на товар A"
                              formH={formH}
                              defaultChecked={watchMainProductDiscount}
                              onClickFunc={(e) => switcherHandler(e, 'is_main_product_discount')}
                              thirdType={true}
                          />
                        </div>
                        <CheckboxSwitch
                            name="is_promo_product_discount"
                            id="is_promo_product_discount"
                            label="Скидка только на товар Б"
                            formH={formH}
                            defaultChecked={watchPromoProductDiscount}
                            onClickFunc={(e) => switcherHandler(e, 'is_promo_product_discount')}
                            thirdType={true}
                        />
                      </div>
                    </ColFull>
                  </Group>
              )}

              {(state?.type?.id?.toString() === '3') && (
                  <div className={s.discount__wrapper}>
                    <div className={s_OrderStyle.select__inner}>
                      <div className={s_OrderStyle.select__wrapper}>
                        <select
                            className={s_OrderStyle.select}
                            id="so-discount-type"
                            onChange={(e) => setDiscountType3(e.target.value)}
                            value={discountType3}
                        >
                          <option value="">Виберите знижку</option>
                          <option value="1">Відсоткова знижка</option>
                          <option value="2">Абсолютна знижка</option>
                        </select>
                        <label className={s_OrderStyle.label} htmlFor="so-discount-type">Розмір
                          знижки</label>
                      </div>
                    </div>
                    <div>
                      {(discountType3.toString() === '1') && (
                          <div className={s_Input.wrapper}>
                            <div className={s_Input.input__wrapper}>
                              <input
                                  className={cn(s_Input.input, {
                                    'is-error': errors.special_offer_type3_discount?.discount_percent,
                                  })}
                                  type="number"
                                  ref={register({
                                    required: VALIDATION.req.required,
                                  })}
                                  name="special_offer_type3_discount.discount_percent"
                                  id="so-discount-percent"
                                  defaultValue={data?.fields?.special_offer_type3_discount?.discount_percent}
                                  placeholder="Відсоток знижки"
                              />
                              <label className={s_Input.label} htmlFor="so-discount-percent">Відсоток
                                знижки</label>
                            </div>
                            {errors.special_offer_type3_discount?.discount_percent
                                &&
                                <p className="form-input-hint">
                                  {errors.special_offer_type3_discount.discount_percent.message}
                                </p>
                            }
                          </div>
                      )}
                      {(discountType3.toString() === '2') && (
                          <div className={s_Input.wrapper}>
                            <div className={s_Input.input__wrapper}>
                              <input
                                  className={cn(s_Input.input, {
                                    'is-error': errors.special_offer_type3_discount?.discount_value,
                                  })}
                                  type="number"
                                  ref={register({
                                    required: VALIDATION.req.required,
                                  })}
                                  name="special_offer_type3_discount.discount_value"
                                  id="so-discount-value"
                                  defaultValue={data?.fields?.special_offer_type3_discount?.discount_value}
                                  placeholder="Абсолютна знижка"
                              />
                              <label className={s_Input.label} htmlFor="so-discount-value">Абсолютна
                                знижка</label>
                            </div>
                            {errors.special_offer_type3_discount?.discount_value
                                &&
                                <p className="form-input-hint">
                                  {errors.special_offer_type3_discount.discount_value.message}
                                </p>
                            }
                          </div>
                      )}
                    </div>
                  </div>
              )}

              {/* options */}
              <Group attrs={{className: s.rselect__wrapper}}>
                <ColFull>
                  <RSelect
                      props={{
                        value: specOfferOptionsChoose,
                        onChange: (opt) => setSpecOfferOptionsChoose(opt),
                        placeholder: 'Виберіть тип блоку',
                        options: !!data?.special_offers_options?.length ? data?.special_offers_options : [],
                        isMulti: true,
                        getOptionLabel: (opt) => opt.title,
                        getOptionValue: (opt) => opt.value
                      }}
                  />
                  {!!specOfferOptionsChoose?.length && specOfferOptionsChoose?.map((item, index) =>
                      <input
                          key={`spec-offer-options-item-hidden-${item?.value}`}
                          type="hidden"
                          ref={register}
                          name={`options[${index}]`}
                          value={item.value}
                      />
                  )}
                </ColFull>
              </Group>

              {/*sort*/}
              {state?.type?.id?.toString() === '3' &&
                <div className={s.main__input}>
                  <InputWrapper
                    label="Позиція"
                    id="so-sort"
                    errorComponent={formH.errors?.title_ua &&
                      <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH.errors?.title_ua,
                      })}
                      type="number"
                      placeholder="Позиція"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={formH.register({
                        required: VALIDATION.req.required
                      })}
                      name="position"
                      id="so-sort-input"
                      defaultValue={data?.fields?.position}
                    />
                  </InputWrapper>
                </div>
              }

              {/* compatible_special_offers */}
              {((state?.type?.id?.toString() === '3') || ((bonusTypeID?.toString() === '3') && (state?.type?.id?.toString() === '1'))) && (
                <Group attrs={{className: s.rselect__wrapper}}>
                  <ColFull>
                    <RSelect
                      props={{
                        value: selectedType3,
                        onChange: (opt) => {
                          setSelectedType3(opt)
                        },
                        placeholder: 'Виберіть сумісні бандли',
                        options: specialOfferList,
                        isMulti: true,
                        getOptionLabel: (opt) => opt.title_ua,
                        getOptionValue: (opt) => opt.id
                      }}
                    />

                    {!!selectedType3?.length && selectedType3?.map((item, index) =>
                          <input
                              key={`compatible_special_offers_item_hidden_${index}`}
                              type="hidden"
                              ref={register}
                              name={`compatible_special_offers[${index}]`}
                              value={item.id}
                          />
                      )}
                    </ColFull>
                  </Group>
              )}

              {/* is_bundle_allowed */}
              {((state?.type?.id?.toString() === '2') || (state?.type?.id?.toString() === '6')) && (
                  <Group>
                    <ColFull>
                      <CheckboxSwitch
                          name="is_bundle_allowed"
                          id="so-is-bundle-allowed"
                          label="Разрешить использовать в бандлах"
                          formH={formH}
                          defaultChecked={data?.fields?.is_bundle_allowed}
                      />
                    </ColFull>
                  </Group>
              )}

              {/* domain_id */}
              <div>
                <div className={s_OrderStyle.select__inner}>
                  <div className={s_OrderStyle.select__wrapper}>
                    <select
                      name="domain_id"
                      className={cn(s_OrderStyle.select, {
                        [s_OrderStyle.select_error]: errors?.domain_id,
                      })}
                      id="so-domain-id"
                      ref={register({
                        required: VALIDATION.select.required,
                      })}
                      onChange={(e) => setDomainChoose(e.target.value)}
                    >
                      <option value={null}>Вибрати домен</option>
                      {data && data?.domains?.map(domain => (
                        <option
                          key={`so-domain-${domain.id}`}
                          value={domain.id}
                          selected={domainChoose?.toString() === domain.id.toString()}
                        >
                          {domain.title}
                        </option>
                      ))}
                    </select>
                    <label className={s_OrderStyle.label} htmlFor="so-domain-id">Домен*</label>
                  </div>
                  {errors?.domain_id && <p className="form-input-hint">{errors.domain_id?.message}</p>}
                </div>
              </div>
              <div>
                <CheckboxList
                  addName=".delivery_type"
                  name="delivery_ids"
                  list={deliveriesType && deliveriesType}
                  formH={formH}
                  defVal={deliveryTypes}
                  title="Типы доставок"
                  id="so-deliveries-type"
                  setChooseValue={setDeliveryTypes}
                  activeAll
                />
              </div>

              {/* payment_types */}
              <div>
                <CheckboxList
                    addName=".payment_type"
                    name="special_offer_payments"
                    list={data && data?.payment_type}
                    formH={formH}
                    defVal={paymentTypes}
                    title="Способи оплати"
                    id="so-type"
                    setChooseValue={setPaymentTypes}
                    activeAll
                />
              </div>
            </div>

            {domainChoose && (
                <>
                  {state?.type?.id?.toString() === '1' && (
                      <TypeOne
                          tabList={bonusTypeID?.toString() === '1'
                              ? [{id: 0, title: 'Товар А'}, {id: 1, title: 'Не підрахункові товари'}]
                              : bonusTypeID?.toString() === '2'
                                  ? [{id: 0, title: 'Товар А'}, {id: 2, title: 'Додаткови сервисі'}]
                                  : bonusTypeID?.toString() === '3' && [{id: 0, title: 'Товар А'}]
                          }
                          bonusTypeID={bonusTypeID}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          domainWatch={domainChoose}
                          data={data}
                          formH={formH}
                          showDiscountType1={isDiscountApplyRequired}
                      />
                  )}

                  {state?.type?.id?.toString() === '2' && (
                      <TypeSecond
                          tabList={[{id: 0, title: 'Товар А'}, {id: 1, title: 'Товар Б'},]}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          domainWatch={domainChoose}
                          data={data}
                          formH={formH}
                      />
                  )}

                  {state?.type?.id?.toString() === '3' && (
                      <TypeThreeNew
                          tabList={[{id: 0, title: 'Товар А'}, {id: 1, title: 'Товар Б'}]}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          domainWatch={domainChoose}
                          data={data}
                          formH={formH}
                          discountPercent={discountType3 === '1' ? discountPercentType3 : null}
                          discountValue={discountType3 === '2' ? discountValueType3 : null}
                      />

                      // <TypeThree
                      //     tabList={[{id: 0, title: 'Товар А'}, {id: 1, title: 'Товар Б'}]}
                      //     activeTab={activeTab}
                      //     setActiveTab={setActiveTab}
                      //     domainWatch={domainChoose}
                      //     data={data}
                      //     formH={formH}
                      //     discountPercent={discountType3 === '1' ? discountPercentType3 : null}
                      //     discountValue={discountType3 === '2' ? discountValueType3 : null}
                      // />
                  )}

                  {state?.type?.id?.toString() === '6' && (
                      <TypeSix
                          tabList={[{id: 0, title: 'Товар А'}, {id: 1, title: 'Товар Б'}, {
                            id: 2,
                            title: 'Товар В'
                          }]}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          domainWatch={domainChoose}
                          data={data}
                          formH={formH}
                      />
                  )}
                </>
            )}

            {/*<div>*/}
            {/*  {state.type.id !== null && (*/}
            {/*      <>*/}
            {/*        <div className="divider text-center" data-content={state?.type?.title} />*/}
            {/*        {render.SpecialOfferType(state.type.id)}*/}
            {/*      </>*/}
            {/*  )}*/}
            {/*</div>*/}
            {load && <Loader/>}
          </div>
        </Form>
      </>
  );
};

export default SpecOfferViewEdit;
