import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';

import { getInCartStatusName, in_cart_status } from "../../lib/const/order";
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewTable from '../../components/common/TableListView/Table';
import TableListViewRow from '../../components/common/TableListView/Row';
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import TableListViewFiltersRow from '../../components/common/TableListView/FiltersRow';
import { getData } from './api';
import TopNavTabs from '../../components/ui/TopNavTabs/TopNavTabs';
import getTZtoDate from "../../lib/getTZtoDate";
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";

const InCartList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();
  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div className="box-wrap">
        <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - In cart list`} />
      <TopNavTabs tabs={[
        {
          title: 'Заказы',
          url: '/app/orders',
          permissionName: 'admin.order.index',
        },
        {
          title: `В корзине ${+rest?.meta?.total ? `(${+rest?.meta?.total})` : '(0)'}`,
          url: '/app/in-carts',
          permissionName: 'admin.in-cart.index',
          isActive: true,
        },
        {
          title: 'Заявки MonoCheckout',
          url: '/app/orders-mono-checkout',
          permissionName: 'admin.order.index'
        }
      ]}
      />
      <TableListViewTable>
        <thead>
          <tr>
            <th>ID</th>
            <th>Дата</th>
            <th>Имя</th>
            <th>Фамилия</th>
            <th>Номер телефона</th>
            <th>Емайл</th>
            <th>Статус заказа</th>
            <th>Домен</th>
            <th> </th>
          </tr>

          <TableListViewFiltersRow
            urlParams={urlParams}
            fields={[
              { type: 'text', name: 'id' },
              {},
              { type: 'text', name: 'name' },
              { type: 'text', name: 'surname' },
              { type: 'text', name: 'phone' },
              {},
              { type: 'select', name: 'status', options: in_cart_status },
              {},
              {},
              // { type: 'select', name: 'domain_id', options: DOMAINS },
            ]}
            onSubmit={pushUrlParams}
          />
        </thead>
        <tbody>
          {load
            ? (
              <TableListViewRowLoader cols={10} />
            )
            : (rest && (
              rest?.data?.length
                ? rest?.data?.map((e, i) => (
                      <TableListViewRow
                          id={e.id}
                          key={`in_cart-item-${e.id}`}
                          editLink={`/app/in-cart/update/${e?.id}`}
                          deleteButton={false}
                          permissionRemove='admin.in-cart.destroy'
                          permissionEdit='admin.in-cart.edit'
                      >
                          <td>
                              {checkPermission('admin.in-cart.edit')
                                  ? <Link to={`/app/in-cart/update/${e.id}`}>{e.id}</Link>
                                  : <span>{e.id}</span>
                              }
                          </td>
                          <td>{getTZtoDate(e.created_at, true)}</td>
                          <td>{e.user_info.name}</td>
                          <td>{e.user_info.surname}</td>
                          <td>{e.user_info.phone}</td>
                          <td>{e.user_info.email}</td>
                          <td>{getInCartStatusName(e.status)?.name}</td>
                          <td>{e.domain.title}</td>
                      </TableListViewRow>
                  ))
                : (
                  <TableListViewRow disabled>
                    <td colSpan={10} />
                  </TableListViewRow>
                )
            ))}
        </tbody>
      </TableListViewTable>
        {!load && (
            <TableListViewFooter
                left={(
                    <>
                        {+rest?.total && (
                            <>
                                <TableListViewPagination
                                    length={+rest?.last_page}
                                    current={+rest?.current_page}
                                    total={+rest?.total}
                                    perPage={+rest?.per_page}
                                    loc={location}
                                />
                            </>
                        )}
                    </>
                )}
                right={(
                    <TableListViewPerPage
                        urlParams={urlParams}
                        onSelect={(val, params) => pushUrlParams(params)}
                    />
                )}
                rightCn="col-auto"
            />
        )}
    </div>
  );
};

export default InCartList;
