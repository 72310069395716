import { getBinotelRecordLinkById } from "../api/binotel";
import { toast } from "react-toastify";

export const openBinotelRecordLink = async (id) => {
	await getBinotelRecordLinkById(id)
		.then((res) => {
			if (res.status === 200) {
				window.open(res.data.record_link, '_blank');
			}
		})
		.catch((err) => {
			if (err.response.status === 404) {
				toast.error('Не знайдено', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			} else {
				toast.error('Помилка', {
					position: 'top-center',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
			}
		})
}
