import {
    SPEC_OFFER_GET_ALL,
    SPEC_OFFER_CREATE,
    SPEC_OFFER_GET_EDIT,
    SPEC_OFFER_EDIT,
    SPEC_OFFER_DELETE,
    SPEC_OFFER_GET, SPEC_OFFER_CALCULATE, DOCUMENTS_TO_POST, SPEC_OFFER_MOVE_TO, SPEC_OFFER_OPTIONS_GET
} from '../../const/api';

import { LIST_PER_PAGE } from '../../const/view';
import fetchAx from '../fetchAx';

export const getSpecOffer = (params) => fetchAx({
  url: SPEC_OFFER_GET,
  method: 'GET',
  params: {
    per_page: LIST_PER_PAGE.default,
    ...params,
  },
});

export const getSpecOffersALl = () => fetchAx({
  url: SPEC_OFFER_GET_ALL,
  method: 'GET',
});

export const getSpecOffersOptions = () => fetchAx({
    url: SPEC_OFFER_OPTIONS_GET,
    method: 'GET',
});

export const getSpecOffersType = () => fetchAx({
  url: SPEC_OFFER_GET,
  method: 'GET',
  params: {
    type: 2,
    is_active: 1,
    per_page: LIST_PER_PAGE.xxxl
  }
});

export const createSpecOffer = (data) => fetchAx({
  url: SPEC_OFFER_CREATE,
  method: 'POST',
  data,
});

export const getSpecOfferEdit = (id) => fetchAx({
  url: SPEC_OFFER_GET_EDIT.join(id),
  method: 'GET',
});

export const editSpecOffer = (id, data) => fetchAx({
  url: SPEC_OFFER_EDIT.join(id),
  method: 'PUT',
  data,
});

export const removeSpecOffer = (id) => fetchAx({
  url: SPEC_OFFER_DELETE.join(id),
  method: 'DELETE',
});

export const specOfferCalculate = (data) => fetchAx({
  url: SPEC_OFFER_CALCULATE,
  method: 'POST',
  data,
  params: {
    per_page: 1000,
  },
});

export const specMoveTo = (offer, id, move, data) => fetchAx({
    url: SPEC_OFFER_MOVE_TO(offer, id, move),
    method: 'PUT',
    params: {
        ...data,
        per_page: 1000,
    },
});
