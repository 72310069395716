import React, {useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import getSplicedData from "../../lib/crud/getSplicedData";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import TableListViewTable from "../../components/common/TableListViewNew/Table";
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from "../../components/common/TableListViewNew/Row";
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListViewNew/Row/RowLoader";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import { getData, getFiltersData, remove } from "./api";
import checkPermission from "../../lib/checkPermission";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import StatusCircle from "../../components/ui/StatusCircle";
import {ModalEditTryAndBuy} from "../TryAndBuyList/Modal";
import {ModalEdit} from "./Modal";

const CreditTypesList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const [activeModal, setActiveModal] = useState(false);
    const [activeId, setActiveId] = useState(false);
    console.log(activeModal, activeId)
    const {
        rest,
        filtersData,
        load,
        urlParams,
        setRest,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    });
    return (
        <div>
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Credit type list`}/>
          {!load && (
              <TopPagination
                  pagination={(
                      <>
                        {+rest?.total && (
                            <>
                              <TableListViewPagination
                                  length={+rest?.last_page}
                                  current={+rest?.current_page}
                                  loc={location}
                                  navigate={navigate}
                              />
                              <TableListViewPerPage
                                  urlParams={urlParams}
                                  onSelect={(val, params) => pushUrlParams(params)}
                                  total={+rest?.total}
                              />
                            </>
                        )}
                      </>
                  )}
              />
          )}
            <TableListViewTable>
              <TableHead
                arrayChildren={[
                  {title: 'ID', attr: {style: {width: '30px'}}}, 'Назва типу кредиту', {title: 'Статус активності', attr: {style: {width: '30px'}}},
                ]}
                filterChildren={<TableListViewFiltersRow
                  urlParams={urlParams}
                  fields={[
                    {},
                    {type: 'text', name: 'title', attr: {style: {width: "100%"}, placeholder: 'Пошук'}, attrLabel: { style: { width: '50%' } }},
                    {
                      type: 'select', name: 'is_active',
                      options: [
                        {
                          id: '0',
                          title: 'Не активна'
                        },
                        {
                          id: '1',
                          title: 'Активна'
                        }
                      ]
                    },
                    {},
                  ]}
                  onSubmit={pushUrlParams}
                />}
              />
              <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={3}/>
                    )
                    : (rest && (
                        !!rest?.data?.length
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`credit_type-${e.id}`}
                                    remove={{
                                        api: remove,
                                        success: () => setRest(getSplicedData(i, rest)),
                                        alert: 'Тип кредиту видалено',
                                    }}
                                    setActiveModal={setActiveModal}
                                    setActiveId={setActiveId}
                                    permissionRemove='admin.credit-type.destroy'
                                    permissionEdit='admin.credit-type.edit'
                                    title={`Видалити тип кредиту “${e?.title}”?`}
                                    additionalStyles={{
                                        container: { maxWidth: '465px', minWidth: '465px' }
                                    }}
                                    tagA={false}
                                    editLink
                                >
                                    <td>
                                        {checkPermission('admin.credit-type.edit')
                                            ? (
                                                <span onClick={() => {
                                                    setActiveModal(true)
                                                    setActiveId(e.id)
                                                }} className="custom_color_link">
                                                    {e.id}
                                                </span>
                                            )
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>
                                      <span>
                                        {e?.title || '---'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>
                                        {e.is_active ? <StatusCircle green text="Активна" /> : <StatusCircle red text="Не активна" />}
                                      </span>
                                    </td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                  <td colSpan={3}>Немає типів кредитів</td>
                                </TableListViewRow>
                            )
                    ))}
              </tbody>
            </TableListViewTable>
            {activeModal && (
                <ModalEdit setActive={setActiveModal} active={activeModal} activeId={activeId} />
            )}
        </div>
    );
};

export default CreditTypesList;
