import {Link, useLocation, useNavigate} from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from "../../lib/crud/useList";
import { getData, remove, getFiltersData } from "./api";
import TableListViewTable from "../../components/common/TableListView/Table";
import React from "react";
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import TableListViewRowLoader from "../../components/common/TableListView/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListView/Row";
import getTZtoDate from "../../lib/getTZtoDate";
import { geDomainName } from "../../lib/const/domains";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";


const UsersClientList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        load,
        setRest,
        filtersData,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
    })
    return(
        <div className="box-wrap">
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - User client list`} />
            <TableListViewTable>
                <thead>
                <tr>
                    <th>ID {+rest?.meta?.total ? `(${+rest?.meta?.total})` : (0)}</th>
                    <th>ФИО</th>
                    <th>Имейл</th>
                    <th>Телефон</th>
                    <th>Город</th>
                    <th>Статус</th>
                    <th>Карта лояльности</th>
                    <th>Дата регистрации</th>
                    <th>Заказы</th>
                    <th>Домен регистрации </th>
                    <th> </th>
                </tr>
                <TableListViewFiltersRow
                    urlParams={urlParams}
                    fields={[
                        {},
                        { type: 'text', name: 'name' },
                        { type: 'text', name: 'email' },
                        { type: 'text', name: 'phone' },
                        { type: 'settlement' },
                        { type: 'select', name: 'status',
                            options: [
                                {
                                    id: 'disabled',
                                    title: 'Не активен',
                                },
                                {
                                    id: 'active',
                                    title: 'Активен',
                                },
                                {
                                    id: 'draft',
                                    title: 'Draft',
                                },
                                {
                                    id: 'verification-pending',
                                    title: 'Ожидает верификации',
                                },
                            ],
                        },
                        { type: 'custom', name: 'card_number'},
                        { type: 'date', name: 'date_from', secondLvl: { type: 'date', name: 'date_to' } },
                        { type: 'text', name: 'order' },
                        { type: 'select', name: `domain_id`, options: filtersData?.domains && filtersData.domains },
                        { },
                    ]}
                    onSubmit={pushUrlParams}
                />

                </thead>
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={7} />
                    )
                    : (rest && (
                        rest?.data?.length !== 0
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`user-item-${e.id}`}
                                    editLink={`/app/user/client/update/${e.id}`}
                                    deleteButton={false}
                                    permissionRemove='admin.user.destroy'
                                    permissionEdit='admin.user.edit'
                                >
                                    <td>
                                        {checkPermission('admin.user.edit')
                                            ? <Link to={`/app/user/client/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>{e.name}</td>
                                    <td>{e.email}</td>
                                    <td>{e.phone}</td>
                                    <td>{e?.settlement?.name || '---'}</td>
                                    <td>{e.status}</td>
                                    <td>{e?.card_number}</td>
                                    <td>{e?.created_at ? getTZtoDate(e?.created_at, false) : ''}</td>
                                    <td>
                                        {e?.orders?.length > 0 ?
                                            e?.orders?.map(({id}) => {
                                            return(
                                                <div
                                                key={`order-item-${id}`}
                                                >
                                                    {id},
                                                </div>
                                            )
                                        })
                                            : '--'
                                        }
                                    </td>
                                    <td>{e?.domain?.title}</td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={7} />
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>
            {!load && (
                <TableListViewFooter
                    left={(
                        <>
                            {+rest?.meta?.total && (
                                <TableListViewPagination
                                    length={+rest?.meta?.last_page}
                                    current={+rest?.meta?.current_page}
                                    total={+rest?.meta?.total}
                                    perPage={+rest?.meta?.per_page}
                                    loc={location}
                                />
                            )}
                        </>
                    )}
                    right={(
                        <TableListViewPerPage
                            urlParams={urlParams}
                            onSelect={(val, params) => pushUrlParams(params)}
                        />
                    )}
                    rightCn="col-auto"
                />
            )}
        </div>
    )
}

export default UsersClientList