import {Link, useLocation, useNavigate} from 'react-router-dom';
import React from 'react';
import getSplicedData from '../../lib/crud/getSplicedData';
import useIsMounted from '../../lib/useIsMounted';
import useList from '../../lib/crud/useList';
import TableListViewPagination from "../../components/common/TableListViewNew/Pagination";
import TableListViewRow from '../../components/common/TableListViewNew/Row';
import TableListViewPerPage from "../../components/common/TableListViewNew/SelectPerPage";
import TableListViewRowLoader from '../../components/common/TableListView/Row/RowLoader';
import {getData, remove} from './api';
import {DOMAINS, geDomainName} from "../../lib/const/domains";
import TableListViewFiltersRow from "../../components/common/TableListViewNew/FiltersRow";
import getTZtoDate from "../../lib/getTZtoDate";
import checkPermission from "../../lib/checkPermission";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import TableHead from "../../components/common/TableListViewNew/TableHead";
import TableListViewTable from '../../components/common/TableListViewNew/Table';
import TopPagination from "../../components/common/TableListViewNew/TopPagination";
import TopNavTabs from "../../components/ui/TopNavTabsNew/TopNavTabs";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import s from "../PageSettingEditView/PageSettingEditView.module.scss";

const PageSettingsList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMounted = useIsMounted();

  const {
    rest,
    load,
    urlParams,
    setRest,
    pushUrlParams,
  } = useList({
    api: getData,
    location,
    navigate,
    isMounted,
  });

  return (
    <div>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Page setting list`}/>
      <TopPanelChild
        location={location.pathname}
        title={'Налаштування сторінок'}
        breadcrumbs={{
          items: [
            {
              title: 'Головна',
              url: '/app',
              permissionName: 'admin.page.index',
            },
            {
              title: 'SEO',
              url: '/app/page-settings',
              permissionName: 'admin.page.index',
            },
            {
              title: 'Налаштування сторінок',
              url: `/app/page-settings`,
              permissionName: 'admin.page.index',
            },
          ],
        }}
        btnCreate={{
            title: 'Створити',
            href: '/app/page-setting/create/',
            permissionName: 'admin.page.index'
        }}
        children={<div className={s.top__item}>
        </div>}
        btnEdit
      />
      {!load && (
        <TopPagination
          pagination={(
            <>
              {+rest?.total ? (
                <>
                  <TableListViewPagination
                    length={+rest?.last_page}
                    current={+rest?.current_page}
                    loc={location}
                    navigate={navigate}
                  />
                  <TableListViewPerPage
                    urlParams={urlParams}
                    onSelect={(val, params) => pushUrlParams(params)}
                    total={+rest?.total}
                  />
                </>
              ) : null}
            </>
          )}
          stickySecond
        />
      )}
      <TableListViewTable>
        <TableHead
          arrayChildren={[
            'ID', 'Домен', 'Страница', 'Название страницы',
            'Дата создания', 'Дата обновления', 'Статус активности'
          ]}
          filterChildren={<TableListViewFiltersRow
            urlParams={urlParams}
            fields={[
              {},
              {type: 'select', name: 'domain_id', options: DOMAINS},
              {type: 'text', name: 'url'},
              {type: 'text', name: 'name'},
              {type: 'date', name: 'date_from'},
              {type: 'date', name: 'date_to'},
              {
                type: 'select',
                name: 'is_active',
                options: [
                  {
                    id: 0,
                    title: 'отключена',
                  },
                  {
                    id: 1,
                    title: 'активна',
                  },
                ],
              },
              {},
            ]}
            onSubmit={pushUrlParams}
          />}/>
        <tbody>
        {load
          ? (
            <TableListViewRowLoader cols={8}/>
          )
          : (rest && (
            rest?.data?.length !== 0
              ? rest?.data?.map((e, i) => (
                <TableListViewRow
                  id={e.id}
                  key={`page-setting-item-${e.id}`}
                  remove={{
                    api: remove,
                    success: () => setRest(getSplicedData(i, rest)),
                    alert: 'Настройка страницы удалена',
                  }}
                  editLink={`/app/page-setting/update/${e.id}`}
                  permissionRemove='admin.seo.meta.destroy'
                  permissionEdit='admin.seo.meta.edit'
                >
                  <td>
                    {checkPermission('admin.seo.meta.edit')
                      ? <Link to={`/app/page-setting/update/${e.id}`}>{e.id}</Link>
                      : <span>{e.id}</span>
                    }
                  </td>
                  <td>{geDomainName(e.domain_id)?.name}</td>
                  <td>{e.url}</td>
                  <td>{e.name}</td>
                  <td>{e?.created_at ? getTZtoDate(e.created_at, true) : '---'}</td>
                  <td>{e?.updated_at ? getTZtoDate(e.updated_at, true) : '---'}</td>
                  <td>{+e.is_active ? 'активна' : 'отключена'}</td>
                </TableListViewRow>
              ))
              : (
                <TableListViewRow disabled>
                  <td colSpan={8}/>
                </TableListViewRow>
              )
          ))}
        </tbody>
      </TableListViewTable>
    </div>
  );
};

export default PageSettingsList;