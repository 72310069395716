import React, { useRef, useState } from 'react';
import cn from 'classnames';
import get from 'lodash/get';
import loadMedia from '../../../lib/helpers/loadMedia';
import MediaFile from '../MediaFile';
import { savePositionMedia } from "../../../lib/api/media";
import errorMessage from "../../../lib/errorMessage";
import downloadIcon from "../../../assets/icons/icon_download.svg";

import s from './ImageLoad.module.scss';

const ImageLoad = ({
    id,
    nameParent,
    formH,
    accept,
    acceptStr,
    minOneRequired,
    maxWidth = 4096,
    maxHeight = 2160,
    width = 128,
    height = 170,
    setActiveMedia,
    prodID,
    setDataModal,
    setActiveRemove,
    imageData,
    setImageData,
    entityType = 'product',
    collectionName = 'images',
    altData = {},
    langLabel = 'UA',
    windowName = 'mobile',
    showDeleteBtn = true,
    showCreateBtn = true,
    showEditBtn = true,
    styleMedia,
    classNameMedia,
    setLoad,
    textLoadImg = 'Завантажити зображення'
}) => {
    const nameOneReq = `${id}_oneReq`;

    async function fileHandler(e) {
        const fList = e.target.files;

        if (!fList.length) return;
        const files = Array.from(fList);

        // Reading in sequence
        for (const imageFile of files) {
            let reader = new FileReader();
            reader.onload = function(e) {
                let img = document.createElement('img');
                img.onload = function() {
                    if(maxWidth < this.width && maxHeight < this.height) {
                        alert(`Допустимый размер картинки: ${maxWidth}x${maxHeight}`);
                    }
                };
                img.src = e.target.result;
            }
            reader.readAsDataURL(fList[0]);
            try {
                // const compressedFile = await imageCompression(imageFile, options);

                if (setLoad) {
                    setLoad(true);
                }

                await loadMedia(
                    fList[0],
                    (data) => {
                        const parseImageData = imageData?.map(item => item.id);
                        let result = [data?.id];
                        if(parseImageData) {
                            result = [...parseImageData, data?.id];
                        }

                        savePositionMedia({position: result})
                            .then(res => {})
                            .catch(err => errorMessage(err, '/api/admin/media/position POST'))
                        return setImageData(prev => ([
                            data,
                            ...prev,
                        ]));
                    },
                    acceptStr,
                    1,
                    collectionName,
                    prodID,
                    entityType,
                    altData,
                );

                if (setLoad) {
                    setLoad(false);
                }
            } catch (error) {
                console.log(error);
                if (setLoad) {
                    setLoad(false);
                }
            }
        }
    }

    return (
        <div className={s.wrap}>
            <div className={s.list}>
                {(minOneRequired && !imageData?.length) && <input type="hidden" name={nameOneReq} ref={formH.register({ required: true })} />}

                {!!imageData?.length ? imageData.map((e, i) => {
                    const name = `${nameParent}[${i}]`;
                    return (
                        <div
                            key={e.id}
                            className={cn(s.box, {
                                [s.ml_0]: !showCreateBtn,
                            })}
                            style={{ width, height, padding: 0 }}
                        >
                            <Item
                                val={e?.conversions?.original}
                                index={i}
                                name={name}
                                formH={formH}
                                setActiveMedia={setActiveMedia}
                                setActiveRemove={setActiveRemove}
                                setDataModal={setDataModal}
                                setImageData={setImageData}
                                defVal={e}
                                prodID={prodID}
                                acceptStr={acceptStr}
                                entityType={entityType}
                                collectionName={collectionName}
                                showDeleteBtn={showDeleteBtn}
                                styleMedia={styleMedia}
                                classNameMedia={classNameMedia}
                                showEditBtn={showEditBtn}
                            />
                        </div>
                    );
                }) : showCreateBtn && (
                    <div className={cn(classNameMedia, {
                        [s.boxError]: minOneRequired && get(formH.errors, nameOneReq)
                    })} style={{ width, height }}
                    >
                        <div className={s.drop}>
                            <label htmlFor={id}>
                                <input
                                    id={id}
                                    className="d-hide"
                                    type="file"
                                    onChange={fileHandler}
                                    accept={accept}
                                    multiple
                                />
                            </label>
                            {langLabel && <p className={s.drop__title}>{langLabel}</p>}
                            <div className={s.drop__block}>
                                <img src={downloadIcon} alt="download" />
                                {textLoadImg && <p className={s.drop__text}>{textLoadImg}</p>}
                            </div>
                            {windowName && <p className={s.drop__desc}>{windowName}</p>}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const Item = ({
    val,
    index,
    name,
    formH,
    setActiveMedia,
    setDataModal,
    setActiveRemove,
    defVal,
    setImageData,
    prodID,
    acceptStr,
    collectionName,
    entityType,
    showDeleteBtn,
    styleMedia,
    classNameMedia,
    showEditBtn
}) => {
    const [showComp, setShowComp] = useState('');

    const Block = (showDeleteBtn) => {
        return (
            <div className={s.hover__btn_group}>
                {showEditBtn && (
                    <button
                        className='btn btn-action s-circle'
                        type='button'
                        onClick={() => {
                            setDataModal(defVal);
                            if (setActiveMedia) {
                                setActiveMedia(true)
                            }
                        }}
                        style={{ margin: 8 }}
                    >
                        <i className="icon icon-edit" />
                    </button>
                )}

                {showDeleteBtn && (
                    <button
                        className={`btn btn-action s-circle ${s.btn_danger}`}
                        type="button"
                        onClick={() => {
                            setDataModal({
                                ...defVal,
                                'indexItem': index
                            });
                            setActiveRemove(true);
                        }}
                        style={{ margin: 8 }}
                    >
                        <i className="icon icon-delete" />
                    </button>
                )}
            </div>
        )
    }
    const handleLeave = () => setShowComp('');
    const handleHover = () => setShowComp(Block(showDeleteBtn));

    return (
        <div style={{ position: 'relative' }} onMouseOver={handleHover} onMouseLeave={handleLeave}>
            <MediaFile
                name={name}
                defVal={val}
                val={val}
                ref={formH.register()}
                formH={formH}
                removeBtn={false}
                setImageData={setImageData}
                prodID={prodID}
                indexItem={index}
                acceptStr={acceptStr}
                entity_type={entityType}
                collection_name={collectionName}
                style={styleMedia}
                className={classNameMedia}
            />
            {showComp}
        </div>
    );
};

export default ImageLoad;
