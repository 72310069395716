import React, {useEffect, useRef, useState} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsMounted from "../../lib/useIsMounted";
import useList from '../../lib/crud/useList';
import { getData, getFiltersData } from "./api";
import TableListViewTable from "../../components/common/TableListView/Table";
import TableListViewRowLoader from "../../components/common/TableListView/Row/RowLoader";
import TableListViewRow from "../../components/common/TableListView/Row";
import TableListViewFooter from "../../components/common/TableListView/Footer";
import TableListViewPagination from "../../components/common/TableListView/Pagination";
import TableListViewPerPage from "../../components/common/TableListView/SelectPerPage";
import TopNavTabs from "../../components/ui/TopNavTabs/TopNavTabs";
import { geDomainName } from "../../lib/const/domains";
import TableListViewFiltersRow from "../../components/common/TableListView/FiltersRow";
import {
    getOrderStatusPaymentName,
    order_status_payment,
} from "../../lib/const/order";
import getTZtoDate from "../../lib/getTZtoDate";
import getNumbersWithSpaces from "../../lib/getNumbersWithSpaces";
import checkPermission from "../../lib/checkPermission";
import { Helmet } from "react-helmet";
import {useDispatch, useSelector} from "react-redux";
import { setFilters } from "../../store/orderList/action";
import { DEVELOPMENT_ENVIRONMENT } from "../../const/api";
import { findDeliveryTypeNameById, findOrderStatusById } from "../../lib/helpers/order";
import {setSelectedOrders} from "../../store/orderEdit/action";
import s from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Table from "../OrderEditView/fakeData/orderTable.module.scss";

const OrderMonoCheckoutList = () => {
    const orderStore = useSelector(state => state);
    const dispatch = useDispatch();
    const ref = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const isMounted = useIsMounted();
    const {
        rest,
        filtersData,
        load,
        urlParams,
        pushUrlParams,
    } = useList({
        api: getData,
        apiFilters: getFiltersData,
        location,
        navigate,
        isMounted,
        filters: orderStore.orderList
    });

    const [selected, setSelected] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = async () => {
        if (ref?.current?.checked) {
            setSelectAll(true);
            setSelected([]);
        } else {
            setSelectAll(false);
        }
    };


    const handleSelect = (id, check) => {
        let resultIndex;

        selected?.find((item, index) => {
            if (item.id === id) {
                resultIndex = index;
            }
        });

        setSelectAll(false);

        if (typeof resultIndex === 'number') {
            setSelected((prev) => {
                const temp = [...prev];
                if (temp[resultIndex]) {
                    temp[resultIndex].is_selected = check;
                }
                return temp;
            });
        } else {
            setSelected((prev) => ([
                    ...prev,
                    {id, is_selected: check}
                ]
            ));
        }
    };

    useEffect(() => {
        dispatch(setSelectedOrders(selected.filter(el => el.is_selected)))
        // console.log(orderStore.orderEdit.selectedOrders)
    }, [selected])

    return (
        <div className="box-wrap">
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Order list`} />
            <TopNavTabs tabs={[
                {
                    title: 'Заказы',
                    url: '/app/orders',
                    permissionName: 'admin.order.index'
                },
                {
                    title: 'В корзине',
                    url: '/app/in-carts',
                    permissionName: 'admin.in-cart.index'
                },
                {
                    title: `Заявки MonoCheckout ${+rest?.meta?.total ? `(${+rest?.meta?.total})` : '(0)'}`,
                    url: '/app/orders-mono-checkout',
                    permissionName: 'admin.order.index',
                    isActive: true
                }
            ]}
            />
            <TableListViewTable>
                <thead>
                <tr>
                    <th></th>
                    <th>Номер / JDE</th>
                    <th>Дата</th>
                    <th>Клиент</th>
                    <th>Комментарий</th>
                    <th>Статус заказа</th>
                    <th>Статус оплаты</th>
                    <th>Способ оплаты</th>
                    <th>Оператор</th>
                </tr>

                <TableListViewFiltersRow
                    urlParams={urlParams}
                    fields={[
                        // { type: 'checkbox', name: 'select_all', id: 'table-select_all', attr:{ onClick: () => handleSelectAll(), defaultChecked: selectAll,ref}},
                        {},
                        { type: 'text', name: 'id', style: { minWidth: '65px', display: 'block' } },
                        { type: 'date', name: 'date_from', secondLvl: { type: 'date', name: 'date_to'} },
                        { type: 'text', name: 'name' },
                        { type: 'text', name: 'comment' },
                        {},
                        { type: 'select', name: 'status_payment', options: order_status_payment },
                        { type: 'select', name: 'type_payment', options: filtersData?.payments && filtersData.payments, optProps: ['id', 'title_ru']  },
                        { type: 'select', name: 'updated_by', options: filtersData?.managers && filtersData.managers, optProps: ['id', 'name'],
                            secondLvl: { type: 'checkbox', name: 'updated_by_me', id: 'updated-by-me', label: 'Мои заказы', attr: {
                                    defaultChecked: urlParams?.updated_by?.toString() === localStorage.getItem('userId'),
                                    value: localStorage.getItem('userId'),
                                } } }
                    ]}
                    onSubmit={pushUrlParams}
                    setFilters={setFilters}
                />

                </thead>
                <tbody>
                {load
                    ? (
                        <TableListViewRowLoader cols={7} />
                    )
                    : (rest && (
                        rest?.data?.length !== 0
                            ? rest?.data?.map((e, i) => (
                                <TableListViewRow
                                    id={e.id}
                                    key={`order-item-${e.id}`}
                                    permissionRemove='admin.order.destroy'
                                    permissionEdit='admin.order.edit'
                                    controlsOff
                                >
                                    <td  className={s.firstColumn}>
                                        <div className={s.checkbox__wrapper}>
                                            <input
                                                className={s.checkbox}
                                                type="checkbox"
                                                id={`table_product_${i}`}
                                                name={`product_${i}`}
                                                checked={selectAll ? false : selected?.find((i) => i.id === e.id)?.is_selected}
                                                onClick={(event) => handleSelect(e.id, event.target.checked)}
                                            />
                                            <label htmlFor={`table_product_${i}`}
                                                   className={`${s.label} ${s_Table.label}`}/>
                                        </div>
                                    </td>
                                    <td>
                                        {checkPermission('admin.order.edit')
                                            ? <Link to={`/app/order/update/${e.id}`}>{e.id}</Link>
                                            : <span>{e.id}</span>
                                        }
                                    </td>
                                    <td>{getTZtoDate(e.created_at, true)}</td>
                                    <td>{e.user_info?.surname} {e.user_info?.name} {e.user_info?.patronymic}</td>
                                    <td>
                                        {!!e?.comments?.length && e?.comments.map(comment => {
                                            const text = comment.text.split('\\n');
                                            return text.map((item, indexText) => <div key={`item-${i}-comment-${indexText}`}>{item}</div>);
                                        })}
                                    </td>
                                    <td>{!!filtersData?.statuses?.length
                                        ? findOrderStatusById(filtersData?.statuses, e.status)?.name
                                        : e.status ? e.status : '---'}</td>
                                    <td>{getOrderStatusPaymentName(e.status_payment)?.name}</td>
                                    <td>{e?.type_payment && filtersData?.payments && filtersData?.payments?.find(item => item.id === e?.type_payment)?.title_ru}</td>
                                    <td>{e.updatedBy ? e.updatedBy?.name : '---'}</td>
                                </TableListViewRow>
                            ))
                            : (
                                <TableListViewRow disabled>
                                    <td colSpan={16}/>
                                </TableListViewRow>
                            )
                    ))}
                </tbody>
            </TableListViewTable>

            {!load && (
                <TableListViewFooter
                    left={(
                        <>
                            {rest?.meta?.total > 0 && (
                                <>
                                    <TableListViewPagination
                                        length={+rest?.meta?.last_page}
                                        current={+rest?.meta?.current_page}
                                        total={+rest?.meta?.total}
                                        perPage={+rest?.meta?.per_page}
                                        loc={location}
                                    />
                                </>
                            )}
                        </>
                    )}
                    right={(
                        <TableListViewPerPage
                            urlParams={urlParams}
                            onSelect={(val, params) => pushUrlParams(params)}
                        />
                    )}
                    rightCn="col-auto"
                />
            )}
        </div>
    )
}

export default OrderMonoCheckoutList
