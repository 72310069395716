import React, {useEffect, useMemo, useState} from "react";
import {order_reports_columns} from "../../lib/const/order";
import {getStatuses} from "../../lib/api/statuses";
import {getDomains} from "../../lib/api/domains";
import {getWhAll} from "../../lib/api/warehouses";
import {getSpecOffersALl} from "../../lib/api/specOffers";

import Button from '../../components/ui/Button';
import s from './ReportsOrders.module.scss';
import s_FormSelect from "../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import BtnExport from "../../components/common/TopPanel/BtnExport";
import Loader from "../../components/ui/Loader";
import errorMessage from "../../lib/errorMessage";
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import {refreshToken} from "../../lib/crud/refreshToken";
import RSelect from "../../components/ui/RSelect";
import {getOrderExcelModes} from "../../lib/api/order";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";

const ReportsOrders = () => {
    const [params, setParams] = useState({
        domain_send: false,
        status_send: false,
        warehouse_send: false,
    });

    const [load, setLoad] = useState(false);
    const [statuses, setStatuses] = useState([]);
    const [domains, setDomains] = useState([]);
    const [warehouses, setWarehouses] = useState([]);
    const [specOfferSelected, setSpecOfferSelected] = useState(null);
    const [specOfferList, setSpecOfferList] = useState([]);
    const [orderExcelModes, setOrderExcelModes] = useState([]);

    const SelectHandler = (el) => {
        setSpecOfferSelected(el);
    }

    const orderReportsColumnsMemo = useMemo(() => {
        if (params?.mode === 'order-only') {
            return order_reports_columns.filter(item => !['11', '12', '13', '14', '15', '18', '19', '20', '22', '29', '42', '43'].includes(item.value))
        }
        return order_reports_columns
    }, [params])

    useEffect(() => {
        if(!!specOfferSelected?.length) {
            handleParams('special_offers', specOfferSelected.map(el => el.id))
        }
    }, [specOfferSelected]);

    const handleParams = (key, value) => {
        if (!!value?.toString()?.length) {
            setParams(prev => ({
                ...prev,
                [key]: value
            }))
        } else {
            setParams(prev => {
                const temp = {...prev};
                delete temp[key];
                return temp;
            })
        }
    };

    const removeParam = (key, send) => setParams(prev => {
        const temp = {...prev};
        delete temp[key];
        temp[send] = false;
        return temp;
    });

    useEffect(() => {
        setLoad(true);
        getStatuses({'per_page': 100})
            .then((res) => setStatuses(res.data.data))
            .catch(async (err) => await errorMessage(err, '/api/admin/status GET'))
            .finally(() => setLoad(false));

        getDomains()
            .then((res) => setDomains(res.data))
            .catch(async (err) => await errorMessage(err, '/api/admin/domain/all GET'))
            .finally(() => setLoad(false));

        getWhAll()
            .then((res) => setWarehouses(res.data.data))
            .catch(async (err) => {
                if (err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getStatuses({'per_page': 100})
                            .then((res) => setStatuses(res.data.data))
                            .catch(async (err) => await errorMessage(err, '/api/admin/status GET'))
                            .finally(() => setLoad(false));

                        await getDomains()
                            .then((res) => setDomains(res.data))
                            .catch(async (err) => await errorMessage(err, '/api/admin/domain/all GET'))
                            .finally(() => setLoad(false));

                        await getWhAll().then((res) => setWarehouses(res.data.data))
                    })
                } else {
                    await errorMessage(err, '/api/admin/warehouse GET')
                }
            })
            .finally(() => setLoad(false));
        /*Spec-offers*/
        getSpecOffersALl()
            .then((res) => setSpecOfferList(res.data))
            .catch(async (err) => {
                if (err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getStatuses({'per_page': 100})
                            .then((res) => setStatuses(res.data.data))
                            .catch(async (err) => await errorMessage(err, '/api/admin/status GET'))
                            .finally(() => setLoad(false));

                        await getSpecOffersALl().then((res) => setSpecOfferList(res.data))
                    })
                } else {
                    await errorMessage(err, '/api/admin/warehouse GET')
                }
            })
            .finally(() => setLoad(false));

        getOrderExcelModes()
            .then((res) => setOrderExcelModes(res.data))
            .catch(async (err) => {
                if (err?.response?.status === 401) {
                    await refreshToken(async () => {
                        await getOrderExcelModes()
                            .then((res) => setOrderExcelModes(res.data))
                            .catch(async (err) => await errorMessage(err, '/api/admin/order/excel-modes GET'))
                            .finally(() => setLoad(false));
                    })
                } else {
                    await errorMessage(err, '/api/admin/order/excel-modes GET')
                }
            })
            .finally(() => setLoad(false));

    }, []);

    useEffect(() => {
        if (!!specOfferList?.length) {
            setSpecOfferSelected(() => {
                const result = [];

                specOfferList?.forEach((item) => {
                    if (specOfferSelected?.find(offer => offer === item.id)) {
                        result.push(item)
                    }
                });

                return result;
            });
        }
    }, [specOfferList]);


    return (
        <div className="box-wrap">
            <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Order reports`}/>
            <div className={s.header}>
                <div className={s.title}>ОТЧЕТ ЕДИНЫЙ (ЗАКАЗЫ)</div>
                <div className={s.header__block}>
                    <div>
                        <BtnExport
                            title='Скачать'
                            href='/api/admin/order/excel/'
                            filename='Многоцелевой_отчет.xlsx'
                            params={params}
                            permissionName='admin.order.export'
                            removeParams={true}
                        >
                            Скачать
                        </BtnExport>
                    </div>
                </div>
            </div>
            <div className={s.top}>
                <div className={s.top__title}>Параметры отчета</div>
                <div className={s.top__bottom}>
                    <div className={s.top__block}>
                        <div className={s.top__item}>
                            <div className={s.input__block}>
                                <div>Дата с:</div>

                                <div className={s_Input.wrapper}>
                                    <div className={s_Input.input__wrapper}>
                                        <input
                                            className={s_Input.input}
                                            type="date"
                                            name="delivery.estimated_delivery_date"
                                            id="to-estimated-delivery-date"
                                            defaultValue=''
                                            placeholder="Выберите дату"
                                            onChange={(e) => handleParams('date_from', e.target.value)}
                                        />
                                        <label className={s_Input.label} htmlFor="to_delivery_date">Дата с:</label>
                                    </div>
                                </div>
                            </div>
                            <div className={s.input__block}>
                                <div>Статус заказа:</div>
                                <div>
                                    <div className={s.warehouse__block}>
                                        <ul>
                                            {!load ? !!statuses?.length ? statuses?.map(status => {
                                                return (
                                                    <li key={status.id}>
                                                        <div className={s_FormSelect.checkbox__wrapper}>
                                                            <input
                                                                className={s_FormSelect.checkbox}
                                                                type="checkbox"
                                                                id={`status-${status.id}`}
                                                                name={`status[${status.id}]`}
                                                                checked={params['status']?.find(item => item === status.id)}
                                                                onChange={() => {
                                                                    setParams(prev => {
                                                                        if (!!prev?.status?.length) {
                                                                            const included = prev?.status?.find(item => item === status.id);
                                                                            if (included) {
                                                                                const removeValue = prev?.status?.filter(item => item !== status.id);
                                                                                if (removeValue?.length === 0) {
                                                                                    const temp = {...prev};
                                                                                    delete temp['status'];
                                                                                    temp['status_send'] = false;
                                                                                    return temp;
                                                                                } else {
                                                                                    return {
                                                                                        ...prev,
                                                                                        'status': removeValue,
                                                                                        'status_send': true,
                                                                                    }
                                                                                }
                                                                            }
                                                                            return {
                                                                                ...prev,
                                                                                'status': [...prev?.status, status.id],
                                                                                'status_send': true,
                                                                            }
                                                                        }
                                                                        return {
                                                                            ...prev,
                                                                            'status': [status.id],
                                                                            'status_send': true,
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                            <label
                                                                htmlFor={`status-${status.id}`}
                                                                className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}
                                                            >
                                                                {status.name}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            }) : <li>Нет статусов</li> : <Loader/>}
                                        </ul>
                                    </div>
                                    <div className={s.btn__block}>
                                        <Button border onClick={() => {
                                            setParams(prev => {
                                                const allStatus = statuses?.map(status => status.id);
                                                return {
                                                    ...prev,
                                                    'status': allStatus,
                                                    'status_send': false,
                                                }
                                            })
                                        }}>Выделить все</Button>
                                        <Button border onClick={() => removeParam('status', 'status_send')}>Снять
                                            все</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.top__item}>
                            <div className={s.input__block}>
                                <div>Дата по:</div>
                                <div className={s_Input.wrapper}>
                                    <div className={s_Input.input__wrapper}>
                                        <input
                                            className={s_Input.input}
                                            type="date"
                                            name="delivery.estimated_delivery_date_to"
                                            id="to-estimated-delivery-date-to"
                                            defaultValue=''
                                            placeholder="Выберите дату"
                                            onChange={(e) => handleParams('date_to', e.target.value)}
                                        />
                                        <label className={s_Input.label} htmlFor="to_delivery_date-to">Дата по:</label>
                                    </div>
                                </div>
                            </div>
                            <div className={s.input__block}>
                                <div>Магазин создатель:</div>
                                <div>
                                    <div className={s.warehouse__block}>
                                        <ul>
                                            {!load ? !!warehouses?.length ? warehouses?.map(warehouse => {
                                                return (
                                                    <li key={warehouse.id}>
                                                        <div className={s_FormSelect.checkbox__wrapper}>
                                                            <input
                                                                className={s_FormSelect.checkbox}
                                                                type="checkbox"
                                                                id={`warehouse-${warehouse.id}`}
                                                                name={`warehouse[${warehouse.id}]`}
                                                                checked={params['warehouse_id']?.find(item => item === warehouse.id)}
                                                                onChange={() => {
                                                                    setParams(prev => {
                                                                        if (!!prev?.warehouse_id?.length) {
                                                                            const included = prev?.warehouse_id?.find(item => item === warehouse.id);
                                                                            if (included) {
                                                                                const removeValue = prev?.warehouse_id?.filter(item => item !== warehouse.id);
                                                                                if (removeValue?.length === 0) {
                                                                                    const temp = {...prev};
                                                                                    delete temp['warehouse_id'];
                                                                                    temp['warehouse_send'] = false;
                                                                                    return temp;
                                                                                } else {
                                                                                    return {
                                                                                        ...prev,
                                                                                        'warehouse_id': removeValue,
                                                                                        'warehouse_send': true,
                                                                                    }
                                                                                }
                                                                            }
                                                                            return {
                                                                                ...prev,
                                                                                'warehouse_id': [...prev?.warehouse_id, warehouse.id],
                                                                                'warehouse_send': true,
                                                                            }
                                                                        }
                                                                        return {
                                                                            ...prev,
                                                                            'warehouse_id': [warehouse.id],
                                                                            'warehouse_send': true,
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                            <label
                                                                htmlFor={`warehouse-${warehouse.id}`}
                                                                className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}
                                                            >
                                                                {warehouse?.title_ru}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            }) : <li>Нет магазинов</li> : <Loader/>}
                                        </ul>
                                    </div>
                                    <div className={s.btn__block}>
                                        <Button border onClick={() => {
                                            setParams(prev => {
                                                const allWarehouse = warehouses?.map(warehouse => warehouse.id);
                                                return {
                                                    ...prev,
                                                    'warehouse_id': allWarehouse,
                                                    'warehouse_send': false,
                                                }
                                            })
                                        }}>Выделить все</Button>
                                        <Button border onClick={() => removeParam('warehouse_id', 'warehouse_send')}>Снять
                                            все</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.top__item}>
                            <div>
                                <CheckboxSwitch
                                    name="is_active"
                                    id="reports-orders-excel-modes"
                                    label="Заказы"
                                    defaultChecked={params?.mode === 'order-only'}
                                    onClickFunc={(value) => {
                                        setParams(prev => {
                                            return {
                                                ...prev,
                                                'mode': value ? 'order-only' : 'default',
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <div className={s.input__block}>
                                <div>Домены:</div>
                                <div>
                                    <div className={s.warehouse__block}>
                                        <ul>
                                            {!load ? !!domains?.length ? domains?.map(domain => {
                                                return (
                                                    <li key={domain.id}>
                                                        <div className={s_FormSelect.checkbox__wrapper}>
                                                            <input
                                                                className={s_FormSelect.checkbox}
                                                                type="checkbox"
                                                                id={`domain-${domain.id}`}
                                                                name={`domain[${domain.id}]`}
                                                                checked={params['domain']?.find(item => item === domain.id)}
                                                                onChange={() => {
                                                                    setParams(prev => {
                                                                        if (!!prev?.domain?.length) {
                                                                            const included = prev?.domain?.find(item => item === domain.id);
                                                                            if (included) {
                                                                                const removeValue = prev?.domain?.filter(item => item !== domain.id);
                                                                                if (removeValue?.length === 0) {
                                                                                    const temp = {...prev};
                                                                                    delete temp['domain'];
                                                                                    temp['domain_send'] = false;
                                                                                    return temp;
                                                                                } else {
                                                                                    return {
                                                                                        ...prev,
                                                                                        'domain': removeValue,
                                                                                        'domain_send': true,
                                                                                    }
                                                                                }
                                                                            }
                                                                            return {
                                                                                ...prev,
                                                                                'domain': [...prev?.domain, domain.id],
                                                                                'domain_send': true,
                                                                            }
                                                                        }
                                                                        return {
                                                                            ...prev,
                                                                            'domain': [domain.id],
                                                                            'domain_send': true,
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                            <label
                                                                htmlFor={`domain-${domain.id}`}
                                                                className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}
                                                            >
                                                                {domain.title}
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            }) : <li>Домены не доступны</li> : <Loader/>}
                                        </ul>
                                    </div>
                                    <div className={s.btn__block}>
                                        <Button border onClick={() => {
                                            setParams(prev => {
                                                const allDomain = domains?.map(domain => domain.id);
                                                return {
                                                    ...prev,
                                                    'domain': allDomain,
                                                    'domain_send': false,
                                                }
                                            })
                                        }}>Выделить все</Button>
                                        <Button border onClick={() => removeParam('domain', 'domain_send')}>Снять
                                            все</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.input__block}>
                        <div>
                            <div>
                                <InputWrapper label="Номера заказов / JDE" id="to-id">
                                    <input
                                        className={s_Input.input}
                                        type="text"
                                        placeholder="Номера заказов / JDE"
                                        name="id"
                                        defaultValue=""
                                        id="to-id"
                                        onChange={(e) => handleParams('id', e.target.value)}
                                    />
                                </InputWrapper>
                            </div>
                            <div>Для генерации отчета по списку заказов - нужно перечислить заказы через пробел или
                                зяпятую. Все остальные фильтры не учитываются!
                            </div>
                        </div>
                        <div>
                            <div className={s.input__select}>
                                <RSelect
                                    props={{
                                        value: specOfferSelected,
                                        onChange: (opt) => {
                                            SelectHandler(opt)
                                        },
                                        placeholder: 'Названия спец предложений:',
                                        options: specOfferList,
                                        isMulti: true,
                                        getOptionLabel: (opt) => opt.title_ua,
                                        getOptionValue: (opt) => opt.id,

                                    }}
                                />

                                {!!specOfferSelected?.length && specOfferSelected?.map((item, index) =>
                                    <input
                                        key={`special_offers-hidden-${index}`}
                                        type="hidden"
                                        // ref={register}
                                        name={`special_offers[${index}]`}
                                        value={item.id}
                                    />
                                )}
                            </div>
                            <div>Для генерации отчета по списку спец предложений - нужно перечислить спец предложения в
                                заказах через зяпятую. Все остальные фильтры не учитываются!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.top}>
                <div className={s.top__title}>Колонки</div>
                <div className={s.top__bottom}>
                    <div className={s.warehouse__block}>
                        <ul className={s.no_scroll}>
                            {orderReportsColumnsMemo.map(column => (
                                <li key={column.id}>
                                    <div className={s_FormSelect.checkbox__wrapper}>
                                        <input
                                            className={s_FormSelect.checkbox}
                                            type="checkbox"
                                            id={`columns-${column.id}`}
                                            name={`columns[${column.id}]`}
                                            onChange={(e) => {
                                                setParams(prev => {
                                                    if (!!prev?.columns?.length) {
                                                        const included = prev?.columns?.find(item => item === column.id);
                                                        if (included) {
                                                            const removeValue = prev?.columns?.filter(item => item !== column.id);
                                                            if (removeValue?.length === 0) {
                                                                const temp = {...prev};
                                                                delete temp['columns'];
                                                                return temp;
                                                            } else {
                                                                return {
                                                                    ...prev,
                                                                    'columns': removeValue,
                                                                }
                                                            }
                                                        }
                                                        return {
                                                            ...prev,
                                                            'columns': [...prev?.columns, column.id],
                                                        }
                                                    }
                                                    return {
                                                        ...prev,
                                                        'columns': [column.id],
                                                    }
                                                })
                                            }}
                                        />
                                        <label
                                            htmlFor={`columns-${column.id}`}
                                            className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}
                                        >
                                            {column.title}
                                        </label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportsOrders;