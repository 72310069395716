import React, { useEffect, useState } from "react";
import s_Modal from "./modal.module.scss";
import sSelect from "../orderStyle.module.scss";
import icon_nova_poshta from "../../../../assets/icons/icon_nova_poshta.svg";
import icon_ipost from "../../../../assets/icons/logo_ipost.png";
import Button from "../../../../components/ui/Button";
import Group from "../../../../components/ui/FormEditView/Group";
import ColFull from "../../../../components/ui/FormEditView/ColFull";
import s_FormSelect from "../../../../components/ui/FormEditView/FormCheckbox/FormCheckbox.module.scss";
import InputWrapper from "../../../../components/ui/InputWrapper";
import s from "../../../../components/ui/InputWrapper/InputWrapper.module.scss";
import ods from "../../../OrderEditView/fakeData/orderStyle.module.scss";
import { createTtn, getShippingActions } from "../../../../lib/api/order";
import { toast } from "react-toastify";
import getTZtoDate from "../../../../lib/getTZtoDate";
import errorMessage from "../../../../lib/errorMessage";
import InputMask from "react-input-mask";
import { INPUT_MAX_LENGTH } from "../../../../const/view";
import { refreshToken } from "../../../../lib/crud/refreshToken";

const ModalTtn = ({ active, setActive, title, formProps, orderID, setTtnNumber, priceForNP }) => {

    const [load, setLoad] = useState(false);
    const [shippingActions, setShippingActions] = useState({});
    const [typeAction, setTypeAction] = useState('');
    const [createTtnNovaPoshta, setCreateTtnNovaPoshta] = useState({
        action: typeAction,
        parameters: {
            weight: '',
            places: '',
            width: '30',
            height: '25',
            length: '30',
            shipping_date: getTZtoDate(new Date()).split('.').reverse().join('-'),
            order_total: priceForNP || '',
            backward_delivery: false,
            payment_control: formProps?.data?.fields?.type_payment === 1,
        }
    });

    const [createTtnJustin, setCreateTtnJustin] = useState({
        action: typeAction,
        parameters: {
            weight: '',
            places: '',
            size: '',
            order_total: formProps?.data?.fields?.total || '',
        }
    });

    const [createTtnDelfast, setCreateTtnDelfast] = useState({
        action: typeAction,
        parameters: {
            places: '',
            order_total: formProps?.data?.fields?.total || '',
        }
    });

    const [createTtnIPost, setCreateTtnIPost] = useState({
        action: typeAction,
        parameters: {
            weight: '',
            width: '',
            height: '',
            length: '',
            places: '',
            shipping_date: getTZtoDate(new Date()).split('.').reverse().join('-'),
            shipping_time_interval: '10:00-13:00',
            parcel_description: '',
            order_total: formProps?.data?.fields?.total || '',
        }
    });

    const handleSendData = async () => {
        switch (shippingActions?.provider) {
            case "NOVAPOSHTA":{
                await createTtn(orderID, createTtnNovaPoshta).then((res) => {
                    toast('ТТН Создан', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTtnNumber(res?.data?.data?.ttn);
                }).catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await createTtn(orderID, createTtnNovaPoshta).then((res) => {
                                toast('ТТН Создан', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setTtnNumber(res?.data?.data?.ttn);
                            })
                        })
                    } else {
                        await errorMessage(err, "NOVAPOSHTA createTtn")
                    }
                })
                    .finally(() => setActive(false));
                break;
            }
            case "DELFAST": {
                await createTtn(orderID, createTtnDelfast).then((res) => {
                    setTtnNumber(res?.data?.data?.ttn);
                    toast('ТТН Создан', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }).catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await createTtn(orderID, createTtnNovaPoshta).then((res) => {
                                toast('ТТН Создан', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setTtnNumber(res?.data?.data?.ttn);
                            })
                        })
                    } else {
                        await errorMessage(err, "DELFAST createTtn")
                    }
                })
                    .finally(() => setActive(false));
                break;
            }
            case "JUSTIN": {
                await createTtn(orderID, createTtnJustin).then((res) => {
                    toast('ТТН Создан', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTtnNumber(res?.data?.data?.ttn);
                }).catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await createTtn(orderID, createTtnJustin).then((res) => {
                                toast('ТТН Создан', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setTtnNumber(res?.data?.data?.ttn);
                            })
                        })
                    } else {
                        await errorMessage(err, "JUSTIN createTtn")
                    }
                })
                    .finally(() => setActive(false));
                break;
            }

            case "IPOST":{
                const parseData = {
                    ...createTtnIPost,
                    action: typeAction
                }
                await createTtn(orderID, parseData).then((res) => {
                    toast('ТТН Создан', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    setTtnNumber(res?.data?.data?.ttn);
                }).catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await createTtn(orderID, parseData).then((res) => {
                                toast('ТТН Создан', {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                setTtnNumber(res?.data?.data?.ttn);
                            })
                        })
                    } else {
                        await errorMessage(err, "IPost createTtn")
                    }
                })
                    .finally(() => setActive(false));
                break;
            }
        }
    }
    const handleAction = async () => {
        const data = {
            action: typeAction,
            parameters: null,
        };

        await createTtn(orderID, data).then((res) => {
            toast(`ТТН ${typeAction}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if(res?.data?.data?.link) {
                window.open(res?.data?.data?.link, '_blank');
            }
            if(typeAction === 'REMOVE_TTN') {
                setTtnNumber('');
            }
        }).catch(async (err) => {
            if(err?.response?.status === 401) {
                await refreshToken(async () => {
                    await createTtn(orderID, data).then((res) => {
                        toast(`ТТН ${typeAction}`, {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if(res?.data?.data?.link) {
                            window.open(res?.data?.data?.link, '_blank');
                        }
                        if(typeAction === 'REMOVE_TTN') {
                            setTtnNumber('');
                        }
                    })
                })
            } else {
                await errorMessage(err, 'REMOVE TTN')
            }
        })
            .finally(() => setActive(false));
    }

    const handleTimeInterval = (e) => setCreateTtnIPost(prev => ({
        ...prev,
        parameters: {
            ...prev.parameters,
            shipping_time_interval: e.target.value
        }
    }))

    const renderFormCreate = () => {
        switch (shippingActions?.provider) {
            case "NOVAPOSHTA": {
                return (
                    (
                        <div>
                            {/* weight */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Вес,кг" id="ttn-weight">
                                        <input
                                            className={s.input}
                                            type="number"
                                            min={0}
                                            placeholder="Вес,кг"
                                            name="weight"
                                            id="ttn-weight"
                                            value={createTtnNovaPoshta.parameters?.weight || ''}
                                            onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    weight: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {shippingActions?.type === "DELIVERY_TYPE_TERMINAL" && (
                                <Group attrs={{className: 'mt-1 mb-1'}}>
                                    <ColFull attrs={{ className: 'd-flex' }}>
                                        {/* width */}
                                        <InputWrapper label="Ширина,см" id="ttn-width" style={{width: '105px'}}>
                                            <input
                                                className={s.input}
                                                type="number"
                                                min={0}
                                                placeholder="Ширина,см"
                                                name="width"
                                                id="ttn-width"
                                                value={createTtnNovaPoshta.parameters?.width}
                                                onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                        ...prev.parameters,
                                                        width: e.target.value
                                                    }}))}
                                            />
                                        </InputWrapper>

                                        {/* height */}
                                        <InputWrapper label="Высота,см" id="ttn-height" style={{width: '105px'}}>
                                            <input
                                                className={s.input}
                                                type="number"
                                                min={0}
                                                placeholder="Высота,см"
                                                name="height"
                                                id="ttn-height"
                                                value={createTtnNovaPoshta.parameters?.height}
                                                onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                        ...prev.parameters,
                                                        height: e.target.value
                                                    }}))}
                                            />
                                        </InputWrapper>

                                        {/* length */}
                                        <InputWrapper label="Длина,см" id="ttn-length" style={{width: '105px'}}>
                                            <input
                                                className={s.input}
                                                type="number"
                                                min={0}
                                                placeholder="Длина,см"
                                                name="length"
                                                id="ttn-length"
                                                value={createTtnNovaPoshta.parameters?.length}
                                                onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                        ...prev.parameters,
                                                        length: e.target.value
                                                    }}))}
                                            />
                                        </InputWrapper>
                                    </ColFull>
                                </Group>
                            )}

                            {/* places */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Места" id="ttn-places">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Места"
                                            name="places"
                                            id="ttn-places"
                                            value={createTtnNovaPoshta.parameters?.places || ''}
                                            onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    places: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* shipping_date */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Дата отправки" id="ttn-shipping_date">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Дата отправки"
                                            name="shipping_date"
                                            id="ttn-shipping_date"
                                            value={createTtnNovaPoshta.parameters?.shipping_date}
                                            onChange={(e) => setCreateTtnNovaPoshta(prev => ({
                                                ...prev,
                                                parameters: {
                                                    ...prev.parameters,
                                                    shipping_date: e.target.value
                                                }
                                            }))}
                                            readOnly
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* order_total */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Итого" id="ttn-order_total">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Итого"
                                            name="order_total"
                                            id="ttn-order_total"
                                            value={createTtnNovaPoshta.parameters?.order_total}
                                            onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    order_total: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* payment_control */}
                            <Group attrs={{className: 'mt-2 mb-2'}}>
                                <ColFull>
                                    <div className={s_FormSelect.checkbox__wrapper}>
                                        <input
                                            className={s_FormSelect.checkbox}
                                            type="checkbox"
                                            id="ttn-payment_control"
                                            name="payment_control"
                                            checked={createTtnNovaPoshta.parameters?.payment_control || false}
                                            onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    payment_control: e.target.checked
                                                }}))}
                                        />
                                        <label htmlFor="ttn-payment_control" className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}>Оплата за дорученням</label>
                                    </div>
                                </ColFull>
                            </Group>

                            {/* backward_delivery */}
                            <Group attrs={{className: 'mt-2 mb-2'}}>
                                <ColFull>
                                    <div className={s_FormSelect.checkbox__wrapper}>
                                        <input
                                            className={s_FormSelect.checkbox}
                                            type="checkbox"
                                            id="ttn-backward_delivery"
                                            name="backward_delivery"
                                            checked={createTtnNovaPoshta.parameters?.backward_delivery || false}
                                            onChange={(e) => setCreateTtnNovaPoshta(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    backward_delivery: e.target.checked
                                                }}))}
                                        />
                                        <label htmlFor="ttn-backward_delivery" className={`${s_FormSelect.label} ${s_FormSelect.label_left}`}>Обратная доставка</label>
                                    </div>
                                </ColFull>
                            </Group>
                        </div>
                    )
                )
            }

            case "DELFAST": {
                return (
                    <div>
                        {/* places */}
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <InputWrapper label="Объем" id="ttn-places">
                                    <input
                                        className={s.input}
                                        type="text"
                                        placeholder="Объем"
                                        name="places"
                                        id="ttn-places"
                                        value={createTtnDelfast?.parameters?.places || ''}
                                        onChange={(e) => setCreateTtnDelfast(prev => ({...prev, parameters: {
                                                ...prev.parameters,
                                                places: e.target.value
                                            }}))}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>

                        {/* order_total */}
                        <Group attrs={{className: 'mt-1 mb-1'}}>
                            <ColFull>
                                <InputWrapper label="Итого" id="ttn-order_total">
                                    <input
                                        className={s.input}
                                        type="text"
                                        placeholder="Итого"
                                        name="order_total"
                                        id="ttn-order_total"
                                        value={createTtnDelfast.parameters?.order_total || ''}
                                        onChange={(e) => setCreateTtnDelfast(prev => ({...prev, parameters: {
                                            ...prev.parameters,
                                            order_total: e.target.value
                                        }}))}
                                    />
                                </InputWrapper>
                            </ColFull>
                        </Group>
                    </div>
                )
            }

            case "JUSTIN": {
                return (
                    (
                        <div>
                            {/* weight */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Вес,кг" id="ttn-weight">
                                        <input
                                            className={s.input}
                                            type="number"
                                            min={0}
                                            placeholder="Вес,кг"
                                            name="weight"
                                            id="ttn-weight"
                                            value={createTtnJustin.parameters?.weight || ''}
                                            onChange={(e) => setCreateTtnJustin(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    weight: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* places */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Места" id="ttn-places">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Места"
                                            name="places"
                                            id="ttn-places"
                                            value={createTtnJustin.parameters?.places || ''}
                                            onChange={(e) => setCreateTtnJustin(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    places: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* size */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Объем" id="ttn-size">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Объем"
                                            name="size"
                                            id="ttn-size"
                                            value={createTtnJustin?.parameters?.size || ''}
                                            onChange={(e) => setCreateTtnJustin(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    size: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* order_total */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Итого" id="ttn-order_total">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Итого"
                                            name="order_total"
                                            id="ttn-order_total"
                                            value={createTtnJustin.parameters?.order_total || ''}
                                            onChange={(e) => setCreateTtnJustin(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    order_total: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                        </div>
                    )
                )
            }

            case "IPOST": {
                return (
                    (
                        <div>
                            {/* delivery.delivery_comment */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <div className={ods.textarea__wrapper}>
                                    <textarea
                                        name="ttn-parcel_description"
                                        className={ods.textarea}
                                        id="ttn-parcel-description"
                                        placeholder="Описание посылки"
                                        rows="4"
                                        maxLength={INPUT_MAX_LENGTH}
                                        onChange={(e) => setCreateTtnIPost(prev => ({...prev, parameters: {
                                                ...prev.parameters,
                                                parcel_description: e.target.value
                                            }}))}
                                    />
                                        <label className={ods.label__textarea} htmlFor="ttn-parcel-description">Описание посылки</label>
                                    </div>
                                </ColFull>
                            </Group>

                            {/* weight */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Вес,кг" id="ttn-weight">
                                        <input
                                            className={s.input}
                                            type="number"
                                            min={0}
                                            placeholder="Вес,кг"
                                            name="weight"
                                            id="ttn-weight"
                                            value={createTtnIPost.parameters?.weight}
                                            onChange={(e) => setCreateTtnIPost(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    weight: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull attrs={{ className: 'd-flex' }}>
                                    {/* width */}
                                    <InputWrapper label="Ширина,см" id="ttn-width" style={{width: '105px'}}>
                                        <input
                                            className={s.input}
                                            type="number"
                                            min={0}
                                            placeholder="Ширина,см"
                                            name="width"
                                            id="ttn-width"
                                            value={createTtnIPost.parameters?.width}
                                            onChange={(e) => setCreateTtnIPost(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    width: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>

                                    {/* height */}
                                    <InputWrapper label="Высота,см" id="ttn-height" style={{width: '105px'}}>
                                        <input
                                            className={s.input}
                                            type="number"
                                            min={0}
                                            placeholder="Высота,см"
                                            name="height"
                                            id="ttn-height"
                                            value={createTtnIPost.parameters?.height}
                                            onChange={(e) => setCreateTtnIPost(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    height: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>

                                    {/* length */}
                                    <InputWrapper label="Длина,см" id="ttn-length" style={{width: '105px'}}>
                                        <input
                                            className={s.input}
                                            type="number"
                                            min={0}
                                            placeholder="Длина,см"
                                            name="length"
                                            id="ttn-length"
                                            value={createTtnIPost.parameters?.length}
                                            onChange={(e) => setCreateTtnIPost(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    length: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* places */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Места" id="ttn-places">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Места"
                                            name="places"
                                            id="ttn-places"
                                            value={createTtnIPost.parameters?.places}
                                            onChange={(e) => setCreateTtnIPost(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    places: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* shipping_date */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Дата отправки" id="ttn-shipping_date">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Дата отправки"
                                            name="shipping_date"
                                            id="ttn-shipping_date"
                                            value={createTtnIPost.parameters?.shipping_date}
                                            onChange={(e) => setCreateTtnIPost(prev => ({
                                                ...prev,
                                                parameters: {
                                                    ...prev.parameters,
                                                    shipping_date: e.target.value
                                                }
                                            }))}
                                            readOnly
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* shipping_time_interval */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Время отправки" id="ttn-shipping_date">
                                        <InputMask mask="99:99-99:99" defaultValue={createTtnIPost?.parameters?.shipping_time_interval} onChange={handleTimeInterval}>
                                            {(inputProps) => (
                                                <input
                                                    className={s.input}
                                                    type="text"
                                                    placeholder="Время отправки"
                                                    name="shipping_time_interval"
                                                    id="ttn-shipping_time_interval"
                                                    {...inputProps}
                                                />
                                            )}
                                        </InputMask>
                                    </InputWrapper>
                                </ColFull>
                            </Group>

                            {/* order_total */}
                            <Group attrs={{className: 'mt-1 mb-1'}}>
                                <ColFull>
                                    <InputWrapper label="Итого" id="ttn-order_total">
                                        <input
                                            className={s.input}
                                            type="text"
                                            placeholder="Итого"
                                            name="order_total"
                                            id="ttn-order_total"
                                            value={createTtnIPost.parameters?.order_total}
                                            onChange={(e) => setCreateTtnIPost(prev => ({...prev, parameters: {
                                                    ...prev.parameters,
                                                    order_total: e.target.value
                                                }}))}
                                        />
                                    </InputWrapper>
                                </ColFull>
                            </Group>
                        </div>
                    )
                )
            }

        }
    }

    const renderFrom = () => {
        switch (shippingActions?.provider) {
            case "NOVAPOSHTA":{
                const disabled = !createTtnNovaPoshta.parameters?.weight.length;
                return (
                    <>
                        <div className={s_Modal.modal__header}>
                            <div>
                                <div className={s_Modal.modal__title}>{title}</div>
                            </div>
                            <div>
                                <img src={icon_nova_poshta} alt="Nova poshta"/>
                            </div>
                        </div>
                        <div>
                            <div className={sSelect.select__inner}>
                                <div className={sSelect.select__wrapper}>
                                    <select
                                        name="ttn"
                                        className={sSelect.select}
                                        id="to_ttn"
                                        onChange={(e) => {
                                            setTypeAction(e.target.value);
                                            setCreateTtnNovaPoshta(prev => ({...prev, action: e.target.value}));
                                        }}
                                    >
                                        {shippingActions?.actions?.map((item, index) => {
                                            return <option value={item.action} key={index}>{item.name}</option>
                                        })}
                                    </select>
                                    <label className={sSelect.label} htmlFor="to_ttn">Действие</label>
                                </div>
                            </div>
                            {typeAction === 'GENERATE_TTN' && renderFormCreate()}
                        </div>
                        <div className={s_Modal.modal__footer}>
                            <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                                <div className={s_Modal.modal__buttons}>
                                    <div>
                                        <Button purple onClick={typeAction === 'GENERATE_TTN' ? handleSendData : handleAction} disabled={typeAction === 'GENERATE_TTN' && disabled}>Отправить</Button>
                                    </div>
                                </div>

                                <div>
                                    <Button border onClick={() => setActive(false)}>Закрыть</Button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            case "DELFAST": {
                const disabled = false;
                return (
                    <>
                        <div className={s_Modal.modal__header}>
                            <div>
                                <div className={s_Modal.modal__title}>{title}</div>
                            </div>
                            <div>
                                Delfast
                                {/*<img src={icon_nova_poshta} alt="Nova poshta"/>*/}
                            </div>
                        </div>
                        <div>
                            <div className={sSelect.select__inner}>
                                <div className={sSelect.select__wrapper}>
                                    <select
                                        name="ttn"
                                        className={sSelect.select}
                                        id="to_ttn"
                                        onChange={(e) => {
                                            setTypeAction(e.target.value);
                                            setCreateTtnDelfast(prev => ({...prev, action: e.target.value}));
                                        }}
                                    >
                                        {shippingActions?.actions?.map((item, index) => {
                                            return <option value={item.action} key={index}>{item.name}</option>
                                        })}
                                    </select>
                                    <label className={sSelect.label} htmlFor="to_ttn">Действие</label>
                                </div>
                            </div>
                            {typeAction === 'GENERATE_TTN' && renderFormCreate()}
                        </div>
                        <div className={s_Modal.modal__footer}>
                            <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                                <div className={s_Modal.modal__buttons}>
                                    <div>
                                        <Button
                                            purple
                                            onClick={typeAction === 'GENERATE_TTN' ? handleSendData : handleAction}
                                            disabled={typeAction === 'GENERATE_TTN' && disabled}
                                        >
                                            Отправить
                                        </Button>
                                    </div>
                                </div>

                                <div>
                                    <Button border onClick={() => setActive(false)}>Закрыть</Button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            case "JUSTIN": {
                const disabled = !createTtnJustin.parameters?.weight.length;
                return (
                    <>
                        <div className={s_Modal.modal__header}>
                            <div>
                                <div className={s_Modal.modal__title}>{title}</div>
                            </div>
                            <div>
                                Justin
                                {/*<img src={icon_nova_poshta} alt="Nova poshta"/>*/}
                            </div>
                        </div>
                        <div>
                            <div className={sSelect.select__inner}>
                                <div className={sSelect.select__wrapper}>
                                    <select
                                        name="ttn"
                                        className={sSelect.select}
                                        id="to_ttn"
                                        onChange={(e) => {
                                            setTypeAction(e.target.value);
                                            setCreateTtnJustin(prev => ({...prev, action: e.target.value}));
                                        }}
                                    >
                                        {shippingActions?.actions?.map((item, index) => {
                                            return <option value={item.action} key={index}>{item.name}</option>
                                        })}
                                    </select>
                                    <label className={sSelect.label} htmlFor="to_ttn">Действие</label>
                                </div>
                            </div>
                            {typeAction === 'GENERATE_TTN' && renderFormCreate()}
                        </div>
                        <div className={s_Modal.modal__footer}>
                            <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                                <div className={s_Modal.modal__buttons}>
                                    <div>
                                        <Button purple onClick={typeAction === 'GENERATE_TTN' ? handleSendData : handleAction} disabled={typeAction === 'GENERATE_TTN' && disabled}>Отправить</Button>
                                    </div>
                                </div>

                                <div>
                                    <Button border onClick={() => setActive(false)}>Закрыть</Button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            case "IPOST":{
                const disabled = !createTtnIPost.parameters?.weight?.length
                    || !createTtnIPost.parameters?.width?.length
                    || !createTtnIPost.parameters?.height?.length
                    || !createTtnIPost.parameters?.length?.length
                    || !createTtnIPost.parameters?.shipping_time_interval?.length
                    || !createTtnIPost.parameters?.places?.length;
                return (
                    <>
                        <div className={s_Modal.modal__header}>
                            <div>
                                <div className={s_Modal.modal__title}>{title}</div>
                            </div>
                            <div>
                                <img src={icon_ipost} alt="logo ipost" />
                            </div>
                        </div>
                        <div>
                            <div className={sSelect.select__inner}>
                                <div className={sSelect.select__wrapper}>
                                    <select
                                        name="ttn"
                                        className={sSelect.select}
                                        id="to_ttn"
                                        onChange={(e) => {
                                            setTypeAction(e.target.value);
                                            setCreateTtnIPost(prev => ({...prev, action: e.target.value}));
                                        }}
                                    >
                                        {shippingActions?.actions?.map((item, index) => {
                                            return <option value={item.action} key={index}>{item.name}</option>
                                        })}
                                    </select>
                                    <label className={sSelect.label} htmlFor="to_ttn">Действие</label>
                                </div>
                            </div>
                            {typeAction === 'GENERATE_TTN' && renderFormCreate()}
                        </div>
                        <div className={s_Modal.modal__footer}>
                            <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                                <div className={s_Modal.modal__buttons}>
                                    <div>
                                        <Button purple onClick={typeAction === 'GENERATE_TTN' ? handleSendData : handleAction} disabled={typeAction === 'GENERATE_TTN' && disabled}>Отправить</Button>
                                    </div>
                                </div>

                                <div>
                                    <Button border onClick={() => setActive(false)}>Закрыть</Button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }

            default: {
                return (
                    <>
                        <div className={s_Modal.modal__header}>
                            <div>
                                <div className={s_Modal.modal__title}>{title}</div>
                            </div>
                        </div>
                        {!load ? (
                            <div className={s_Modal.not_found}>К сожалению, по заданным параметрам нет соответствующих служб доставки. Измените параметры заказа или добавьте правило доставки.</div>
                        ) : (
                            <div className="p-relative">
                                <div className="loading" />
                            </div>
                        )}
                        <div className={s_Modal.modal__footer}>
                            <div className={s_Modal.modal__group} style={{justifyContent: 'space-between'}}>
                                <div className={s_Modal.modal__buttons} />
                                <Button border onClick={() => setActive(false)}>Закрыть</Button>
                            </div>
                        </div>
                    </>
                )
            }
        }
    }

    useEffect(() => {
        if(orderID) {
            setLoad(true);
            getShippingActions(orderID)
                .then(res => {
                    setTypeAction(res.data.actions[0]?.action);
                    setShippingActions(res.data)
                })
                .catch(async (err) => {
                    if(err?.response?.status === 401) {
                        await refreshToken(async () => {
                            await getShippingActions(orderID)
                                .then(res => {
                                    setTypeAction(res.data.actions[0]?.action);
                                    setShippingActions(res.data)
                                })
                        })
                    } else {
                        await errorMessage(err, '/api/admin/order/shipping-actions')
                    }
                })
                .finally(() => setLoad(false));
        }
    }, []);

    useEffect(() => {
        if(typeAction) {
            setCreateTtnNovaPoshta(prev => ({...prev, action: typeAction}));
            setCreateTtnJustin(prev => ({...prev, action: typeAction}));
            setCreateTtnDelfast(prev => ({...prev, action: typeAction}));
        }
    }, [typeAction])

    return (
        <div
            className={active ? `${s_Modal.modal} ${s_Modal.active}` : s_Modal.modal}
            onClick={() => setActive(false)}
        >
            <div
                className={s_Modal.modal__content}
                onClick={(e) => e.stopPropagation()}
            >
                {renderFrom()}
            </div>
        </div>
    );
};

export default ModalTtn;
