import { removePromo } from "../api";
import errorMessage from "../../../lib/errorMessage";
import { setOnChange } from "../../../store/orderEdit/action";

export const removePromoFunc = async (id, formProps, dispatch) => {
	await removePromo(id)
		.then(async (res) => {
			formProps?.setData(prev => {
				const temp = {...prev};

				temp.fields.coupon_code = res?.coupon_code;

				return temp;
			})

			dispatch(setOnChange('promoCode', res?.coupon_code))
		})
		.catch(async (err) => await errorMessage(err , ''))
		.finally(() => formProps.setLoad(false));
}