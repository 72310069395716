import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import cn from 'classnames';
import {INPUT_MAX_LENGTH} from '../../const/view';
import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import {create, edit, getData, parseDataGet, parseDataSend, remove,} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import ColFull from "../../components/ui/FormEditView/ColFull";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import InputWrapper from "../../components/ui/InputWrapper";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import s from "./PageSettingEditView.module.scss";
import Block from "../../components/ui/Block/Block";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import RSelect from "../../components/ui/RSelect";
import {TinyComponent} from "./Components/TinyComponent";
import ColRight from "../../components/ui/FormEditView/ColRight";

const PageSettingEditView = () => {
  const {id: pageSettingsID} = useParams();
  const formH = useForm();
  const [chooseDomains, setChooseDomains] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [titleUaValue, setTitleUaValue] = useState('');
  const [titleRuValue, setTitleRuValue] = useState('');

  const location = useLocation();
  const navigate = useNavigate();

  const {
    register,
    errors,
    handleSubmit,
    reset,

  } = formH;

  const {load, data, submitHandler} = useEdit({
    targetID: pageSettingsID,
    updateFields: reset,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/page-setting/not-found',
      remove: `/app/page-setting${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/page-settings${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Настройка страницы создан',
      edit: 'Настройка страницы обновлен',
      remove: 'Настройка страницы удален',
    },
  });

  useEffect(() => {
    if (!!data?.fields?.domains?.length) {
      const findNameDomains = [];

      if (!!data?.domains?.length) {
        data?.fields?.domains?.forEach(item => {
          const findDomain = data?.domains?.find(domain => domain?.id === item);

          if (findDomain) {
            findNameDomains.push(findDomain)
          }
        })
      }

      setChooseDomains(findNameDomains);
    }

    if (!!data?.fields?.body_ru) {
      setTitleRuValue(data?.fields?.body_ru);
    }

    if (!!data?.fields?.body_ua) {
      setTitleUaValue(data?.fields?.body_ua);
    }
  }, [data?.fields]);

  return (
    <div>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Page setting edit`}/>
      <Form attrs={{
        onSubmit: handleSubmit(submitHandler),
      }}
      >
        <TopPanelChild
          location={location.pathname}
          title={pageSettingsID ? data?.fields?.title_ua ? data.fields.title_ua : 'Сторінка' : 'Нове налаштування сторінки'}
          breadcrumbs={{
            items: [
              {
                title: 'Головна',
                url: '/app',
                permissionName: 'admin.page.index',
              },
              {
                title: 'SEO',
                url: '/SEO',
                permissionName: 'admin.page.index',
              },
              {
                title: 'Налаштування сторінок',
                url: `/app/page-settings${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
                permissionName: 'admin.page.index',
              },
              {
                title: pageSettingsID ? data?.fields?.title_ua ? data.fields.title_ua : 'Нове налаштування сторінки' : 'Створити сторінку',
                permissionName: 'admin.page.index',
              },
            ],
          }}
          back={`/app/page-settings${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
          id={pageSettingsID}
          children={<div className={s.top__item}>
            {/* is_active */}
            <CheckboxSwitch
              name="is_active"
              id="page-setting-is-active"
              label="Активність"
              formH={formH}
              defaultChecked={data?.fields?.is_active === 1}
            />
          </div>}
          btnEdit
        />
        <div className={s.content__wrapper}>
          <div className={s.top__nav} style={{ position: 'static' }}>
            <div className={activePage === 1 ? s.active_link : ''}
                 onClick={() => setActivePage(1)}>Загальні
            </div>
            <div className={activePage === 2 ? s.active_link : ''}
                 onClick={() => setActivePage(2)}>SEO текст УКР
            </div>
            <div className={activePage === 3 ? s.active_link : ''}
                 onClick={() => setActivePage(3)}>SEO текст РУС
            </div>
          </div>
          <div className={s.top__block} style={activePage === 1 ? {display: 'flex'} : {display: 'none'}}>
            <div className={s.top__block__left}>

              {/* page */}
              <Group attrs={{style: {margin: '0 0 6px 0'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Сторінка"
                    id="edit-page"
                  >
                    <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH?.errors?.title_ua,
                      })}
                      type="text"
                      placeholder="Сторінка"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="url"
                      id="seo-meta-url"
                      defaultValue={data?.fields?.url}
                    />
                  </InputWrapper>
                </ColFull>
              </Group>

              {/* page name */}
              <Group attrs={{style: {margin: '0 0 6px 0'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Назва сторінки"
                    id="page_name"
                    // errorComponent={formH?.errors?.title_ua &&
                    //     <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH?.errors?.title_ua,
                      })}
                      type="text"
                      placeholder="Назва сторінки"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="name"
                      id="seo-meta-name"
                      defaultValue={data?.fields?.page_name}
                    />
                  </InputWrapper>
                </ColFull>
              </Group>

              {/* follow Status */}
              <Group attrs={{style: {margin: '0 0 16px 0'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <CheckboxSwitch
                    name="is_nofollow"
                    id="seo-meta-is-nofollow"
                    label="Статус follow"
                    formH={formH}
                    defaultChecked={data?.fields?.is_nofollow === 1}
                    fontWeight={400}
                  />
                </ColFull>
              </Group>

              {/* index status */}
              <Group attrs={{style: {margin: '0 0 16px 0'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <CheckboxSwitch
                    name="is_noindex"
                    id="is_noindex_status"
                    label="Статус індексації"
                    formH={formH}
                    defaultChecked={data?.fields?.is_noindex === 1}
                    fontWeight={400}
                  />
                </ColFull>
              </Group>

              {/*domains*/}
              <Group attrs={{style: {margin: 0}}}>
                <ColFull attrs={{style: {padding: 0}}}>
                  {data
                    ? (
                      <>
                        <select
                          name="domain_id"
                          className={s.formSelect}
                          id="im-domain"
                          ref={register()}
                        >
                          <option value="">выберите домен</option>
                          {data && !!data.domains && data.domains.map((domain) => (
                            <option
                              key={domain.id}
                              value={domain.id}
                            >
                              {domain.title}
                            </option>
                          ))}
                        </select>
                      </>
                    )
                    : (
                      <div className="p-relative">
                        <div className="loading"/>
                      </div>
                    )}
                </ColFull>
              </Group>

              {/* canonical */}
              <Group attrs={{style: {margin: '0 0 6px 0'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Canonical"
                    id="canonical"
                    // errorComponent={formH?.errors?.title_ua &&
                    //     <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH?.errors?.title_ua,
                      })}
                      type="text"
                      placeholder="Canonical"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="url_canonical"
                      id="seo-meta-h1-ua"
                    />
                  </InputWrapper>
                </ColFull>
              </Group>

              {/* change URL */}
              <Group attrs={{style: {margin: '0 0 6px 0'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Заміна URL"
                    id="change_url"
                    // errorComponent={formH?.errors?.title_ua &&
                    //     <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH?.errors?.title_ua,
                      })}
                      type="text"
                      placeholder="Заміна URL"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="url_from"
                      id="seo-meta-h1-ru"
                    />
                  </InputWrapper>
                </ColFull>
              </Group>
            </div>
            <Block title="Українська" classNameWrapper={s.top__wrapper}>
              {/* header_ua */}
              <Group attrs={{style: {margin: '0 0 6px 0', maxWidth: "100%"}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Мета-тег H1 UA:"
                    id="h1_ua"
                    // errorComponent={formH?.errors?.title_ua &&
                    //     <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input)}
                      type="text"
                      placeholder="Мета-тег H1 UA:"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="h1_ua"
                      id="seo-meta-h1-ua"
                    />
                  </InputWrapper>
                </ColFull>
              </Group>

              {/* title_ua */}
              <Group attrs={{style: {margin: '0 0 6px 0', maxWidth: "100%"}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Мета-тег title UA:"
                    id="title_ua"
                    // errorComponent={formH?.errors?.title_ua &&
                    //     <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input, {
                        [s_Input.input_error]: formH?.errors?.title_ua,
                      })}
                      type="text"
                      placeholder="Мета-тег H1 UA:"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="title_ua"
                      id="seo-meta-title-ua"
                    />
                  </InputWrapper>
                </ColFull>
              </Group>

              {/* description_ua */}
              <Group attrs={{style: {margin: "0 0 10px 0", maxWidth: '100%'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <div className={s_Select.textarea__wrapper} style={{margin: 0}}>
                                        <textarea
                                          name="desc_ua"
                                          className={cn(s_Select.textarea, {
                                            [s_Select.select_error]: errors.edit_description_ua,
                                          })}
                                          id="seo-meta-desc-ua"
                                          placeholder="Мета-тег description UA"
                                          ref={register}
                                          maxLength={INPUT_MAX_LENGTH}
                                          style={{minHeight: '212px'}}
                                        />
                    <label className={s_Select.label__textarea} htmlFor="seo-meta-desc-ua">Мета-тег
                      description UA</label>
                  </div>
                </ColFull>
              </Group>

              {/* keyword_ua */}
              <Group attrs={{style: {margin: "0 0 10px 0", maxWidth: '100%'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <div className={s_Select.textarea__wrapper} style={{margin: 0}}>
                                        <textarea
                                          name="keywords_ua"
                                          className={cn(s_Select.textarea, {
                                            [s_Select.select_error]: errors.edit_keyword_ua,
                                          })}
                                          id="seo-meta-keywords-ua"
                                          placeholder="Мета-тег keyword UA"
                                          maxLength={INPUT_MAX_LENGTH}
                                          ref={register}
                                          style={{minHeight: '212px'}}
                                        />
                    <label className={s_Select.label__textarea} htmlFor="seo-meta-keywords-ua">Мета-тег
                      keyword
                      UA</label>
                  </div>
                  {errors.edit_keyword_ua &&
                    <p className="form-input-hint">{errors.edit_keyword_ua.message}</p>}
                </ColFull>
              </Group>
            </Block>
            <Block title="Російська" classNameWrapper={s.top__wrapper}>
              {/* header_ru */}
              <Group attrs={{style: {margin: '0 0 6px 0', maxWidth: "100%"}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Мета-тег H1 RU:"
                    id="h1_ru"
                    // errorComponent={formH?.errors?.title_ua &&
                    //     <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input)}
                      type="text"
                      placeholder="Мета-тег H1 RU:"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="h1_ru"
                      id="seo-meta-h1-ru"
                    />
                  </InputWrapper>
                </ColFull>
              </Group>

              {/* title_ru */}
              <Group attrs={{style: {margin: '0 0 6px 0', maxWidth: "100%"}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <InputWrapper
                    label="Мета-тег title RU:"
                    id="title_ru"
                    // errorComponent={formH?.errors?.title_ua &&
                    //     <p className="form-input-hint">{formH?.errors?.title_ua?.message}</p>}
                  >
                    <input
                      className={cn(s_Input.input)}
                      type="text"
                      placeholder="Мета-тег title RU:"
                      maxLength={INPUT_MAX_LENGTH}
                      ref={register}
                      name="title_ru"
                      id="seo-meta-title-ru"
                    />
                  </InputWrapper>
                </ColFull>
              </Group>

              {/* description_ru */}
              <Group attrs={{style: {margin: "0 0 10px 0", maxWidth: '100%'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <div className={s_Select.textarea__wrapper} style={{margin: 0}}>
                                        <textarea
                                          name="desc_ru"
                                          className={cn(s_Select.textarea, {
                                            [s_Select.select_error]: errors.edit_description_ru,
                                          })}
                                          id="seo-meta-desc-ru"
                                          placeholder="Мета-тег description RU"
                                          ref={register}
                                          maxLength={INPUT_MAX_LENGTH}
                                          style={{minHeight: '212px'}}
                                        />
                    <label className={s_Select.label__textarea} htmlFor="marker-description-ru">Мета-тег
                      description RU</label>
                  </div>
                </ColFull>
              </Group>

              {/* keyword_ru */}
              <Group attrs={{style: {margin: "0 0 10px 0", maxWidth: '100%'}}}>
                <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                  <div className={s_Select.textarea__wrapper} style={{margin: 0}}>
                                        <textarea
                                          name="keywords_ru"
                                          className={cn(s_Select.textarea, {
                                            [s_Select.select_error]: errors.edit_keyword_ru,
                                          })}
                                          id="seo-meta-keywords-ru"
                                          placeholder="Мета-тег keyword RU"
                                          ref={register}
                                          maxLength={INPUT_MAX_LENGTH}
                                          style={{minHeight: '212px'}}
                                        />
                    <label className={s_Select.label__textarea} htmlFor="seo-meta-keywords-ru">Мета-тег
                      keyword
                      ru</label>
                  </div>
                </ColFull>
              </Group>
            </Block>
          </div>

          <div style={activePage === 2 ? {display: 'flex'} : {display: 'none'}}>
            <TinyComponent titleValue={titleUaValue} setTitleValue={setTitleUaValue} register={register}
                           placeholder={'Опис УКР'} name={'body_ua'} id={'body_ua'}/>
          </div>

          <div style={activePage === 3 ? {display: 'flex'} : {display: 'none'}}>
            <TinyComponent titleValue={titleRuValue} setTitleValue={setTitleRuValue} register={register}
                           placeholder={'Опис Рус'}
                           name={'body_ru'} id={'body_ru'}/>
          </div>

        </div>
      </Form>

      {load && <Loader/>}
    </div>
  );
};

export default PageSettingEditView;
