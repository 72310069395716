import React from 'react';
import {uuid} from 'uuidv4';
import {Controller, useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import cn from 'classnames';
import {VALIDATION} from '../../const/text';
import {INPUT_MAX_LENGTH} from '../../const/view';

import useEdit from '../../lib/crud/useEdit';
import Loader from '../../components/ui/Loader';
import Form from '../../components/ui/FormEditView/Form';
import Group from '../../components/ui/FormEditView/Group';
import ColLeft from '../../components/ui/FormEditView/ColLeft';
import ColRight from '../../components/ui/FormEditView/ColRight';
import {create, edit, getData, parseDataGet, parseDataSend, remove,} from './api';
import {DEVELOPMENT_ENVIRONMENT} from "../../const/api";
import {Helmet} from "react-helmet";
import CheckboxSwitch from "../../components/ui/CheckboxSwitch";
import TopPanelChild from "../../components/common/TopPanelNew/TopPanelChild";
import ColFull from "../../components/ui/FormEditView/ColFull";
import InputWrapper from "../../components/ui/InputWrapper";
import s_Input from "../../components/ui/InputWrapper/InputWrapper.module.scss";
import s_Select from "../OrderEditView/fakeData/orderStyle.module.scss";
import InputFile from "../../components/ui/InputFile";

const MediaEditView = () => {
  const {id: mediaID} = useParams();
  const formH = useForm();
  const {
    register,
    errors,
    handleSubmit,
    watch,
    control,
  } = formH;

  const {load, data, submitHandler} = useEdit({
    targetID: mediaID,
    handleSubmit,
    api: {
      create,
      edit,
      remove,
      get: getData,
    },
    parse: {
      get: parseDataGet,
      send: parseDataSend,
    },
    redirects: {
      notFound: '/app/media/not-found',
      remove: `/app/medias${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
      save: `/app/medias${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
    },
    alerts: {
      create: 'Медиа создана',
      edit: 'Медиа обновлена',
      remove: 'Медиа удалена',
    },
  });

  /* eslint-disable */
  return (
    <>
      <Helmet title={`${DEVELOPMENT_ENVIRONMENT} - Media edit`}/>
      <TopPanelChild
        location={location.pathname}
        title={mediaID ? "Медіа" : 'Нова Медіа'}
        breadcrumbs={{
          items: [
            {
              title: 'Dashboard',
              url: '/app',
              permissionName: 'admin.media.index',
            },
            {
              title: 'Медіатека',
              url: `/app/medias${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`,
              permissionName: 'admin.media.index',
            },
            {
              title: 'Медиа',
              permissionName: 'admin.media.index',
            },
          ],
        }}
        back={`/app/medias${document?.referrer?.split('?')?.[1] ? `?${document.referrer.split('?')[1]}` : ''}`}
        id={mediaID}
        children={<>
          {/* is_active */}
          <CheckboxSwitch
            name="is_active"
            label="Активність"
            formH={formH}
            defaultChecked={data?.fields?.is_active}
          />
        </>}
        btnEdit
      />

      <Form attrs={{
        onSubmit: handleSubmit(submitHandler),
      }}
      >
        <button type="submit" className="d-hide"/>
        <div style={{display: 'flex', gap: '30px'}}>
          <div style={{width: '480px'}}>
            {/* custom_properties.alt_ua */}
            <Group attrs={{style: {margin: '0 0 6px 0'}}}>
              <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                <InputWrapper
                  label="ALt УКР:"
                  id="alt-ua"
                >
                  <input
                    name="custom_properties.alt_ua"
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.sort,
                    })}
                    placeholder="ALT УКР:"
                    type="text"
                    id="media-alt-ua"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={register({
                      required: VALIDATION.req.required,
                    })}
                    defaultValue={data?.fields?.custom_properties?.alt_ua}
                  />
                </InputWrapper>
              </ColFull>
            </Group>

            {/* custom_properties.alt_ru */}
            <Group attrs={{style: {margin: '0 0 6px 0'}}}>
              <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                <InputWrapper
                  label="ALT РУ:"
                  id="alt-ru"
                >
                  <input
                    name="custom_properties.alt_ru"
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.sort,
                    })}
                    placeholder="ALT РУ:"
                    type="text"
                    id="media-alt-ru"
                    defaultValue={data?.fields?.custom_properties?.alt_ru}
                    maxLength={INPUT_MAX_LENGTH}
                    ref={register({
                      required: VALIDATION.req.required,
                    })}
                  />
                </InputWrapper>
              </ColFull>
            </Group>

            {/* domain_id */}
            <Group attrs={{style: {margin: '0 0 6px 0'}}}>
              <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                <div className={s_Select.select__inner}>
                  <div className={s_Select.select__wrapper}>
                    {data
                      ? (
                        <select
                          name="domain_id"
                          className={cn(s_Select.select, {
                            [s_Select.select_error]: formH.errors?.type,
                          })}
                          id="media-domain-id"
                          ref={formH.register({
                            required: VALIDATION.select.required,
                          })}
                          defaultValue={control.defaultValuesRef.current.type}
                          style={{margin: 0}}
                        >
                          <option value="">Оберіть домен</option>
                          {data && !!data.domains && data.domains.map((domain) => (
                            <option
                              key={`${uuid()}`}
                              value={domain.id}
                              selected={data?.fields?.domain_id === domain.id}
                            >
                              {domain.title}
                            </option>
                          ))}
                        </select>
                      )
                      : (
                        <div className="p-relative">
                          <div className="loading"/>
                        </div>
                      )}
                    <label className={s_Select.label} htmlFor="marker-type">Домен</label>
                  </div>
                  {formH.errors?.domain_id
                    && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                </div>
              </ColFull>
            </Group>

            {/* collection_name */}
            <Group attrs={{style: {margin: '0 0 6px 0'}}}>
              <ColFull attrs={{style: {padding: 0}}}>
                <div className={s_Select.select__inner}>
                  <div className={s_Select.select__wrapper}>
                    {data
                      ? (
                        <select
                          name="collection_name"
                          className={cn(s_Select.select, {
                            [s_Select.select_error]: formH.errors?.type,
                          })}
                          id="media-collection-name"
                          ref={formH.register({
                            required: VALIDATION.select.required,
                          })}
                          defaultValue={control.defaultValuesRef.current.type}
                          style={{margin: 0}}
                        >
                          <option value="">Оберіть тип</option>
                          {data && !!data?.types && data?.types?.map((type) => (
                            <option
                              key={`${uuid()}`}
                              value={type.value}
                              selected={data?.fields?.collection_name === type.value}
                            >
                              {type.title}
                            </option>
                          ))}
                        </select>
                      )
                      : (
                        <div className="p-relative">
                          <div className="loading"/>
                        </div>
                      )}
                    <label className={s_Select.label} htmlFor="marker-type">Тип зображення</label>
                  </div>
                  {formH.errors?.domain_id
                    && <p className="form-input-hint">{formH.errors.domain_id?.message}</p>}
                </div>
              </ColFull>
            </Group>

            {/* position */}
            <Group attrs={{style: {margin: '0 0 6px 0'}}}>
              <ColFull attrs={{style: {paddingLeft: 0, paddingRight: 0}}}>
                <InputWrapper
                  label="Позиція"
                  id="position"
                >
                  <input
                    name="position"
                    className={cn(s_Input.input, {
                      [s_Input.input_error]: formH?.errors?.sort,
                    })}
                    placeholder="Позиція"
                    type="text"
                    id="media-position"
                    maxLength={INPUT_MAX_LENGTH}
                    ref={register({
                      required: VALIDATION.req.required,
                    })}
                    defaultValue={data?.fields?.position}
                  />
                </InputWrapper>
              </ColFull>
            </Group>
          </div>

          <div>
            {/* conversions.original */}
            <Group>
              <ColFull>
                <Controller
                  defVal={data?.fields?.conversions?.original}
                  as={InputFile}
                  control={control}
                  className={cn('form-input-file')}
                  name="conversions.original"
                  val={watch('conversions.original')}
                  formH={formH}
                  acceptStr="PNG, JPG, GIF"
                  accept="image/*"
                  sizeMB={1}
                  maxWidth={500}
                  maxHeight={500}
                  width={440}
                  height={324}
                  objectFit="contain"
                  labelDownload='Додати зображення'
                />
              </ColFull>
            </Group>
          </div>
        </div>
      </Form>

      {load && <Loader/>}
    </>
  );
};

export default MediaEditView;
