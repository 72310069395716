import { parseAll } from '../../lib/api/_parseApi';
import { getDomains } from '../../lib/api/domains';
import { removeMedia, editMedia, getMediaTypes, saveMedia, getMediaEdit } from "../../lib/api/media";
import {refreshToken} from "../../lib/crud/refreshToken";

export const getData = (mediaId) => Promise.allSettled([getDomains(), mediaId
&& getMediaEdit(mediaId), getMediaTypes()])
  .then(async (res) => {
      const findStatus401 = (res?.find(item => item?.reason?.response?.status === 401))

      if (findStatus401) {
          return await refreshToken(() => window.location.reload())
      }

      return ({
          domains: res[0]?.value?.data,
          fields: mediaId && res[1]?.value?.data,
          types: res[2]?.value?.data,
      })
  });

export const create = saveMedia;
export const edit = editMedia;
export const remove = removeMedia;

export const parseDataSend = (data, mediaId) => {
  let d = { ...data };
  const isUpdate = typeof mediaId !== 'undefined';

  if(!d?.domain_id) {
      delete d['domain_id'];
  }

  if(d?.position) {
    d['position'] = +d?.position;
  }


  d = parseAll(d, !isUpdate, [
    'is_active',
  ]);

  return d;
};

export const parseDataGet = (data) => {
  const d = { ...data };

  return d;
};
